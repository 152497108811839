import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'cbm-rating-modal',
  templateUrl: './cbm-rating-modal.component.html',
  styleUrls: ['./cbm-rating-modal.component.scss']
})
export class CbmRatingModalComponent implements OnInit {
  @Input() visible: boolean = false;
  constructor() {
  }
  ngOnInit() {
  }

}
