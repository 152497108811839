import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import { NgbdCarouselBasicModule } from "./shared/carousel/carousel-basic.module";

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { LandingComponent } from './pages/landing/landing.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { CbmSelectorComponent } from './components/cbm-selector/cbm-selector.component';
import { CbmSelectorItemComponent } from './components/cbm-selector-item/cbm-selector-item.component';
import {ViewRequestComponent} from './pages/servicerequest/view/view-request.component';
import {ServiceRequestService} from './shared/services/service-request.service';
import { TrackComponent } from './pages/servicerequest/track/track.component';
import { CreateComponent } from './pages/servicerequest/create/create-request.component';
import { SelectCstComponent} from './components/select-cst/select-cst.component';
import {CbmDetailComponent} from './modals/cbm-detail/cbm-detail.component';
import { CbmRatingModalComponent} from './modals/cbm-rating-modal/cbm-rating-modal.component';
import { PrivacypolicyComponent} from './modals/privacypolicy/privacypolicy.component';
import {ConfirmComponent} from './modals/confirm/confirm.component';
import {CbmRatingComponent} from './components/cbm-rating/cbm-rating.component';
import {SelectCheckboxComponent} from './components/select-checkbox/select-checkbox.component';
import {SelectCbmStarsComponent} from './components/select-cbm-stars/select-cbm-stars.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {SelectCbmPcabComponent} from './components/select-cbm-pcab/select-cbm-pcab.component';
import {CbmItemComponent} from './components/cbm-item/cbm-item.component';
import {InfoModalComponent} from './modals/info-modal/info-modal.component';
import { OtpSmsComponent } from './modals/otp-sms/otp-sms.component';
import { OtpEmailComponent } from './modals/otp-email/otp-email.component';
import { OtpRetryComponent } from './modals/otp-retry/otp-retry.component';
import { OtpExpiredComponent } from './modals/otp-expiredtimer/otp-expired.component';
import {ContractorDetailComponent} from './pages/contractor-detail/contractor-detail.component';
import { AboutComponent } from './pages/about/about.component';
import { TermsComponent } from './pages/terms/terms.component';
import { StarshadingComponent } from './starshading/starshading.component';
import { StarratemodalComponent } from "./starratemodal/starratemodal.component";

import { AppliedLoadGuideModalComponent } from "./modals/applied-load-guide-modal/applied-load-guide.component";
import { StardetailsComponent } from './stardetails/stardetails.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NavbarComponent,
    CbmSelectorComponent,
    CbmSelectorItemComponent,
    ViewRequestComponent,
    TrackComponent,
    CreateComponent,
    SelectCstComponent,
    CbmDetailComponent,
    CbmRatingModalComponent,
    PrivacypolicyComponent,
    ConfirmComponent,
    CbmRatingComponent,
    SelectCheckboxComponent,
    SelectCbmStarsComponent,
    PaginationComponent,
    SelectCbmPcabComponent,
    CbmItemComponent,
    InfoModalComponent,
    OtpSmsComponent,
    OtpEmailComponent,
    OtpRetryComponent,
    OtpExpiredComponent,
    ContractorDetailComponent,
    AboutComponent,
    TermsComponent,
    StarshadingComponent,
    StarratemodalComponent,

    AppliedLoadGuideModalComponent,
      StardetailsComponent
    
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {}),
    SidebarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbdCarouselBasicModule,
    MatFormFieldModule
  ],
  providers: [
      ServiceRequestService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
