import {AfterContentInit, Component, ElementRef, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  apply_as: string = 'rep';
  constructor(private router: Router) { }
  ngOnInit() {
  }
  onRequestService() {
    switch (this.apply_as) {
      case 'rep':
          this.router.navigate(['/create']);
          break;
      case 'contractor':
          window.open('https://online.meralco.com.ph/customers/s/cxe-apply', '_self', 'noopener');
          break;
      case 'cbm':
          window.open('https://contractor.peccbm.ph', '_self', 'noopener');
          break;
      default:
         this.router.navigate(['/create']);
    }
  }

}
