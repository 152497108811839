import { Injectable } from '@angular/core';
import {ApiHTTP} from './http-provider.service';
import {HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OtpProviderService {

  constructor(private api: ApiHTTP) { }
  createSMSOTP_SR(mobileNumber: string, name: string) {
    //mobileNumber = mobileNumber.replace('+', '%2B');
    //name = encodeURI(name);
   //var customerInfoObj = {"mobile": mobileNumber, "customer_name": name}
   var customerInfoObj = {"mobile": mobileNumber, "customer_name": name};
   //customerInfoObj.append('mobile', mobileNumber);
   //customerInfoObj.append('customer_name', name);
    //return this.api.sendPostRequest(`/otp/customer/service/create?mobile=${mobileNumber}&customer_name=${name}`,
    return this.api.sendPostRequestOTP(`/otp/customer/service/create`,
                              customerInfoObj,
                              new HttpHeaders(),
                              new HttpParams());
  }
  createEmailOTP_SR(email: string, name: string) {
   // name = encodeURI(name);
   // email = encodeURI(email);
   // var customerInfoObj =  {"email": email, "customer_name": name}
   var customerInfoObj = {"email": email, "customer_name": name};
   //customerInfoObj.append('email', email);
   //customerInfoObj.append('customer_name', name);
    return this.api.sendPostRequestOTP(`/otp/customer/service/create`,
       customerInfoObj,
        new HttpHeaders(),
        new HttpParams());
  }
  createSMSOTP_Feedback(mobileNumber: string, contractor_name: string) {
    //mobileNumber = mobileNumber.replace('+', '%2B');
   // contractor_name = encodeURI(contractor_name);
    var customerInfoObj = {"mobile": mobileNumber, "contractor_name": contractor_name};
    //customerInfoObj.append('mobile', mobileNumber);
    //customerInfoObj.append('contractor_name', contractor_name);
    //var customerInfoObj =  {"mobile": mobileNumber, "contractor_name": contractor_name}
    return this.api.sendPostRequestOTP(`/otp/customer/feedback/create`,
       customerInfoObj,
        new HttpHeaders(),
        new HttpParams());
  }
  createEmailOTP_Feedback(email: string, contractor_name: string) {
   // contractor_name = encodeURI(contractor_name);
    //var customerInfoObj =  {"email": email, "contractor_name": contractor_name}
    var customerInfoObj = {"email": email, "contractor_name": contractor_name};
    //customerInfoObj.append('email', email);
    //customerInfoObj.append('contractor_name', contractor_name);
    return this.api.sendPostRequestOTP(`/otp/customer/feedback/create`,
       customerInfoObj,
        new HttpHeaders(),
        new HttpParams());
  }
  createSMSOTP_View(mobileNumber: string, refNum: string) {
    //mobileNumber = mobileNumber.replace('+', '%2B');
    //refNum = encodeURI(refNum);
     //var customerInfoObj = { "mobile": mobileNumber, "refnum": refNum };
   
    var customerInfoObj: any = {"mobile": mobileNumber, "refnum": refNum};
    //var customerInfoObjFin = new HttpParams();
    //customerInfoObjFin.append('mobile', mobileNumber);
    //customerInfoObjFin.append('refnum', refNum);
    //customerInfoObjFin.append('resend', 0);
    return this.api.sendPostRequestOTP(`/otp/customer/service/create`,
       customerInfoObj,
        new HttpHeaders(),
        new HttpParams());
  }
  createSMSOTP_View_Resend(mobileNumber: string, refNum: string) {
    //mobileNumber = mobileNumber.replace('+', '%2B');
    //refNum = encodeURI(refNum);
    //var customerInfoObj =  {"mobile": mobileNumber, "refnum": refNum, "resend": 1}
    var customerInfoObj = {"mobile": mobileNumber, "refnum": refNum, "resend": 1};
    //customerInfoObj.append('mobile', mobileNumber);
    //customerInfoObj.append('refnum', refNum);
    //customerInfoObj.append('resend', '1');
    return this.api.sendPostRequestOTP(`/otp/customer/service/create`,
       customerInfoObj,
        new HttpHeaders(),
        new HttpParams());
  }
  createEmailOTP_View(email: string, refNum: string) {
    //email = encodeURI(email);
    //refNum = encodeURI(refNum);
    //var customerInfoObj =  {"email": email, "refnum": refNum}
    var customerInfoObj = {"email": email, "refnum": refNum};
    //customerInfoObj.append('email', email);
    //customerInfoObj.append('refnum', refNum);
    return this.api.sendPostRequestOTP(`/otp/customer/service/create`,
       customerInfoObj,
        new HttpHeaders(),
        new HttpParams());
  }
  createEmailOTP_View_Resend(email: string, refNum: string) {
    //email = encodeURI(email);
    //refNum = encodeURI(refNum);
    //var customerInfoObj =  {"email": email, "refnum": refNum, "resend": 1}
    var customerInfoObj = {"email": email, "refnum": refNum, "resend": 1};
    //customerInfoObj.append('email', email);
    //customerInfoObj.append('refnum', refNum);
    //customerInfoObj.append("resend", '1');
    return this.api.sendPostRequestOTP(`/otp/customer/service/create`,
       customerInfoObj,
        new HttpHeaders(),
        new HttpParams());
  }
  verifySMSOTP(mobileNumber: string, otp: string) {
    mobileNumber = mobileNumber.replace('+', '%2B');
    return this.api.sendGetRequest(`/otp/verify?mobile=${mobileNumber}&code=${otp}`,
                                    new HttpHeaders() ,
                                    new HttpParams());
  }
  verifyEmailOTP(email: string, otp: string) {
    return this.api.sendGetRequest(`/otp/verify?email=${email}&code=${otp}`,
        new HttpHeaders() ,
        new HttpParams());
  }


}
