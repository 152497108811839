// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_customer_body {
  width: 500px;
  height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/cbm-rating-modal/cbm-rating-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF","sourcesContent":[".modal_customer_body{\n  width: 500px;\n  height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
