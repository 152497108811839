import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-stardetails',
  templateUrl: './stardetails.component.html',
  styleUrls: ['./stardetails.component.scss']
})
export class StardetailsComponent {
  @Input() rating: number;
  @Input() reviews: number;
  @Input() starOnly: boolean;

  private _rating: number;
}
