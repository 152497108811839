import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'navbar-cmp',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  showLoginModal: boolean = false;
  showDropdown: boolean = false;
  constructor() { }

  ngOnInit() {
  }
  onLogin() {
    window.open('https://contractor.peccbm.ph', '_self', 'noopener');
  }

}
