import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';



@Component({
  selector: 'cbm-rating',
  templateUrl: './cbm-rating.component.html',
  styleUrls: ['./cbm-rating.component.scss']
})
export class CbmRatingComponent implements OnInit {
  @Input() title: string = 'Rating Title';
  @Output() onRatingSelected = new EventEmitter<number>();
  rating: number = 0;
  hoverRange: number = 0;
  constructor() { }

  ngOnInit() {
  }
  onItemSelected(rating: number) {
    this.rating = rating;
    this.onRatingSelected.emit(rating);
  }
  onHover(val: number) {
    this.hoverRange = val;
  }


}
