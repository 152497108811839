import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  cbmList: any = [];
  date: string = "";
  constructor() { }
  ngOnInit() {
      // this.initList();
      this.readList();
  }
  initList() {
    this.cbmList.push({
        name: '3BS Electrical and Construction Corp.',
        number: '34137',
        areas: [{name: 'Metro Manila',
                cities: 'Manila, Makati, Mandaluyong, San Juan, Alabang, Quezon City, Novaliches'}
                ]
    });
    this.cbmList.push({
        name: '3D Matucenio Electrical Services',
        number: '50854',
        areas: [{name: 'South Area',
                 cities: 'Entire Cavite'}
        ]
    });
    this.cbmList.push({
          name: 'A.C. Quick Electrical Services',
          number: '48060',
          areas: [{name: 'East Area',
                   cities: 'Rizal: Angono, Binangonan, Teresa, Cardona, Morong, Baras, Tanay, Pililia, Jalajala'}
          ]
      });
      this.cbmList.push({
          name: 'A.D. Ocampo Electrical Services',
          number: '48121',
          areas: [{name: 'North Area',
                    cities: 'Bulacan: Meycauayan, Obando'},
                  {name: 'Metro Manila',
                    cities: 'Quezon City, Caloocan, Novaliches, Valenzuela'}
          ]
      });
      this.cbmList.push({
          name: 'ACDC Engineering Services',
          number: '48661',
          areas: [{name: 'North Area',
                   cities: 'Bulacan: Sta. Maria, Angat, Norzagaray, San Jose Del Monte, Doña Remedios Trinidad'},
                  {name: 'South Area',
                      cities: 'Laguna: San Pedro, Sta. Rosa'},
                 ]
      });
      this.cbmList.push({
          name: 'Alfredo Y. Gomez Electrical Contractor',
          number: '28147',
          areas: [{name: 'Metro Manila',
                   cities: 'Manila, Pasay'}
          ]
      });
      this.cbmList.push({
          name: 'Al-Suffyan Engineering Services and Supply',
          number: '47536',
          areas: [{name: 'North Area',
                   cities: 'Entire Bulacan'},
                  {name: 'Metro Manila',
                   cities: 'Valenzuela'},
                  {name: 'South Area',
                   cities: 'Laguna: Calamba, Biñan, Cabuyao, Bay, Los Baños, San Pablo, Calauan, Alaminos, Rizal, Sta. Cruz, Liliw, ' +
                           'Luisiana, Magdalena, Majayjay, Nagcarlan, Pila, Victoria,' +
                          '\n\n' +
                          'Batangas: Sto. Tomas, Batangas City, San Pascual,' +
                          '\n\n' +
                          'Quezon: Lucena, Pagbilao, Sariaya, Mauban, Lucban, Sampaloc, Tayabas, Tiaong, San Antonio, Candelaria, Dolores'},
          ]
      });
      this.cbmList.push({
          name: 'Andi\'s Electrical Supplies and Services',
          number: '45301',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'Archie and Evelyn Electrical Services Company',
          number: '48162',
          areas: [{name: 'North Area',
                   cities: 'Bulacan: Balagtas, Bocaue, Bulacan, Guiguinto, Marilao, Pandi, Meycauayan, Obando'},
                  {name: 'Metro Manila',
                      cities: 'Quezon City, Caloocan, Novaliches, Valenzuela'}
          ]
      });
      this.cbmList.push({
          name: 'ASH Basics Electrical',
          number: '50647',
          areas: [{name: 'North Area',
                   cities: 'Bulacan: Balagtas, Bocaue, Bulacan, Guiguinto, Marilao, Pandi'},
          ]
      });
      this.cbmList.push({
          name: 'B. Angeles Electrical Consultancy and Services',
          number: '47239',
          areas: [
              {name: 'Metro Manila',
                  cities: 'Marikina, Pasig'},
              {name: 'East Area',
                  cities: 'Entire Rizal'},
          ]
      });
      this.cbmList.push({
          name: 'Beavers Engineering and Architectural Services',
          number: '45760',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Calumpit, Plaridel, Hagonoy, Paombong, ' +
                       'Macabebe, San Miguel, San Ildefonso, Pulilan, Baliwag, San Rafael, Bustos,\n\n' +
                       'Pampanga: Candaba, Apalit, Balagtas, Guiguinto, Bulakan, Pandi, Bocaue, Marilao'},
              {name: 'Metro Manila',
               cities: 'Entire Metro Manila'},
              {name: 'East Area',
               cities: 'Entire Rizal'},
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon,\n\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'}
          ]
      });
      this.cbmList.push({
          name: 'BELCON Electrical Contractor',
          number: '51063',
          areas: [
              {name: 'North Area',
                  cities: 'Entire Bulacan'},
              {name: 'Metro Manila',
                  cities: 'Quezon City, Caloocan, Novaliches, Malabon, Navotas, Valenzuela'}
          ]
      });
      this.cbmList.push({
        name: 'Bhong Electrical Contractor',
        number: '45760',
        areas: [
            {name: 'North Area',
                cities: 'Entire Bulacan'},
            {name: 'Metro Manila',
                cities: 'Quezon City, Caloocan, Novaliches, Malabon, Navotas, Valenzuela'}
        ]
    });
      this.cbmList.push({
          name: 'Blucid Enterprise Inc',
          number: '49053',
          areas: [
              {
               name: 'Metro Manila',
               cities: 'Muntinlupa'
              },
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon,\n\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'BM Power General Construction and Sales Corporation',
          number: '44835',
          areas: [{name: 'Metro Manila',
                   cities: 'Entire Metro Manila'},
                  {name: 'East Area',
                   cities: 'Entire Rizal'},
                  {name: 'South Area',
                   cities: 'Entire Cavite, Laguna and Quezon,\n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'Braw Electrical Enterprises',
          number: '49433',
          areas: [{name: 'North Area',
                   cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'BRJ Electrical Services',
          number: '47009',
          areas: [
              {name: 'Metro Manila',
               cities: 'Makati, Pasay, Mandaluyong, San Juan, Marikina, Pasig, ' +
                        'San Juan, Muntinlupa, Taguig, Las Piñas, Parañaque'},
              {name: 'East Area',
               cities: 'Entire Rizal'},
              {name: 'South Area',
               cities: 'Laguna: Calamba, Biñan, Cabuyao, Bay, Los Banos,\n' +
                       '\n' +
                       'Batangas: Sto. Tomas,\n' +
                       '\n' +
                       'Cavite: Dasmarinas, Carmona, GMA, Silang, San Pedro, Sta. Rosa'},
          ]
      });
      this.cbmList.push({
          name: 'C.L. Perez Electrical Contractor',
          number: '47621',
          areas: [{name: 'Metro Manila',
                    cities: 'Manila, Mandaluyong, San Juan, Marikina, Pasig'},
                    {name: 'East Area',
                    cities: 'Entire Rizal'},
          ]
      });
      this.cbmList.push({
          name: 'Cada Connecta Liga Electrical Contractor',
          number: '47620',
          areas: [
              {name: 'South Area',
                  cities: 'Quezon: Lucena, Pagbilao, Sariaya, Mauban, Lucban, Sampaloc, ' +
                          'Tayabas, Tiaong, San Antonio, Candelaria, Dolores,\n' +
                          '\n' +
                          'Laguna: San Pablo, Calauan, Alaminos, Rizal, Sta. Cruz, ' +
                          'Liliw, Luisiana, Magdalena, Majayjay, Nagcarlan, Pila, Victoria'}
          ]
      });
      this.cbmList.push({
          name: 'Cealj Electrical Contractor Corporation',
          number: '48057',
          areas: [
              {name: 'North Area',
              cities: 'Bulacan: Meycauayan, Obando'},
              {name: 'Metro Manila',
              cities: 'Quezon City, Caloocan, Novaliches, Valenzuela'},
          ]
      });
      this.cbmList.push({
          name: 'Celcotec Industrial Corporation',
          number: '26379',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'Conque Electrical Services',
          number: '49355',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Balagtas, Calumpit, Malolos, Plaridel, Hagonoy, Paombong, ' + 
                'Meycauayan, Obando, Guiguinto, Bulakan, Pandi, Bocaue, Marilao, Sta. Maria,\n\n' +
                    'Pampanga: Macabebe'},
              {name: 'Metro Manila',
               cities: 'Las Piñas, Manila, Pasig, Pateros, Quezon City, Valenzuela'},
              {name: 'East Area',
               cities: 'Cainta, Taytay'},
              {name: 'South Area',
               cities: 'Cavite: Bacoor, Imus, Alfonso, Amadeo, Bailen, ' + 
                        'Indang, Magallanes, Mendez, Silang, Tagaytay,\n\n' +
               'Laguna: Liliw, Luisiana, Magdalena, Majayjay, Nagcarlan, Pila, Sta. Cruz, Victoria,\n\n' +
               'Quezon: Lucena, Pagbilao, Sariaya, Mauban, Lucban, Sampaloc, Tayabas'
            },
          ]
      });
      this.cbmList.push({
          name: 'CVRT Electrical Services',
          number: '47393',
          areas: [
              {name: 'Metro Manila',
               cities: 'Las Piñas'},
              {name: 'South Area',
               cities: 'Entire Cavite'}
          ]
      });
      this.cbmList.push({
          name: 'DEKATAN Builders and Construction Supply',
          number: '45007',
          areas: [
              {name: 'South Area',
               cities: 'Lucena'}
          ]
      });
      this.cbmList.push({
          name: 'DLTRI Power Five General Services Inc.',
          number: '47792',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Meycauayan, Obando'},
              {name: 'Metro Manila',
               cities: 'Quezon City, Caloocan, Novaliches, Valenzuela'},
          ]
      });
      this.cbmList.push({
          name: 'E. B. Dela Cruz Enterprises',
          number: '47800',
          areas: [
              {name: 'South Area',
               cities: 'Laguna: Calamba, Binan, Cabuyao, Bay, Los Banos, ' +
                       'Sto. Tomas, San Pablo, Calauan, Alaminos, Rizal, San Pedro, Sta. Rosa,\n' +
                       '\n' +
                       'Quezon: Tiaong, San Antonio, Candelaria, Dolores'}
          ]
      });
      this.cbmList.push({
          name: 'EBLL Electrical Engineering Services',
          number: '45835',
          areas: [
              {name: 'South Area',
               cities: 'Entire Quezon,\n' +
                       '\n' +
                       'Laguna: San Pablo, Calauan, Alaminos, Rizal, Sta. Cruz, ' +
                       'Liliw, Luisiana, Magdalena, Majayjay, Nagcarlan, Pila, Victoria'}
          ]
      });
      this.cbmList.push({
          name: 'Edrey Electrical Contractor and Supplies',
          number: '47122',
          areas: [
              {name: 'North Area',
               cities: 'Entire Bulacan'},
              {name: 'Metro Manila',
                  cities: 'Quezon City, Caloocan, Novaliches, Malabon, Navotas, Valenzuela'}
          ]
      });
      this.cbmList.push({
          name: 'Eight Wires Electrical Services',
          number: '50632',
          areas: [{name: 'North Area',
                   cities: 'Entire Bulacan,\n' +
                   '\n' + 
                   'Pampanga: Apalit, Candaba, San Simon'}
          ]
      });
      this.cbmList.push({
          name: 'Elektrisidad Electrical Services',
          number: '48256',
          areas: [
              {name: 'Metro Manila',
                  cities: 'Parañaque, Taguig, Muntinlupa, Las Piñas'},
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon,\n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'}
          ]
      });
      this.cbmList.push({
          name: 'Emecont Services Inc.',
          number: '50466',
          areas: [
              {name: 'Metro Manila',
               cities: 'Manila, Pasay, Muntinlupa, Las Piñas, Parañaque'},
              {name: 'South Area',
               cities: 'Cavite: Bacoor, Imus, Rosario, Gen. Trias, Tanza, ' +
                       'Naic, Maragondon, Ternate, Kawit, Trece Martirez, Noveleta, Cavite City'}
          ]
      });
      this.cbmList.push({
          name: 'Emmanuel Electrical Services',
          number: '47430',
          areas: [
              {name: 'Metro Manila',
               cities: 'Quezon City, Malabon, Navotas, Caloocan, Novaliches'}
          ]
      });
      this.cbmList.push({
          name: 'Ezekiel Electrical Services',
          number: '48982',
          areas: [
              {name: 'Metro Manila',
               cities: 'Muntinlupa, Taguig'}
          ]
      });
      this.cbmList.push({
          name: 'F.C. de Jesus Electrical Services',
          number: '48349',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Balagtas, Bocaue, Bulacan, Guiguinto, Marilao, Pandi, Baliwag, ' +
                       'Bustos, Pulilan, San Ildefonso, San Miguel, San Rafael, Sta. Maria, Angat, ' +
                       'Norzagaray, San Jose Del Monte, Doña Remedios Trinidad, Meycauayan, Obando,\n' +
                        '\n' +
                       'Pampanga: San Simon, Candaba'},
              {name: 'Metro Manila',
               cities: 'Quezon City, Malabon, Navotas, Caloocan, Novaliches, Valenzuela'}
          ]
      });
      this.cbmList.push({
          name: 'GGGB Electrical Services',
          number: '48008',
          areas: [
              {name: 'Metro Manila',
               cities: 'Muntinlupa, Taguig'}
          ]
      });
      this.cbmList.push({
          name: 'Giga Joule Builders & Trading',
          number: '46724',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Baliwag, Bustos, Pulilan, San Ildefonso, San Miguel, ' +
                       'San Rafael, Malolos, Calumpit, Hagonoy, Paombong, Plaridel, Sta. Maria, ' +
                       'Angat, Norzagaray, San Jose Del Monte, Doña Remedios Trinidad, Meycauayan, Obando,\n' +
                       '\n' +
                       'Pampanga: Apalit, Candaba, San Simon'},
              {name: 'Metro Manila',
               cities: 'Caloocan, Novaliches, Valenzuela, Marikina, Pasig, Parañaque'},
              {name: 'East Area',
               cities: 'Entire Rizal'},
              {name: 'South Area',
               cities: 'Cavite: Dasmarinas, Carmona, GMA, Silang'},
          ]
      });
      this.cbmList.push({
          name: 'Gined Building Solutions, Inc.',
          number: '42068',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'GP Abacan Electrical Services',
          number: '47806',
          areas: [
              {name: 'Metro Manila',
               cities: 'Parañaque, Taguig, Muntinlupa, Las Piñas'},
              {name: 'South Area',
               cities: ' Entire Cavite, Laguna and Quezon,\n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'Harmonic System Inc.',
          number: '30832',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'JN Layog Electrical Contractor, Inc.',
          number: '46993',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Meycauayan, Obando'},
              {name: 'Metro Manila',
               cities: 'Caloocan, Novaliches, Valenzuela'},
          ]
      });
      this.cbmList.push({
          name: 'JanoJun Electrical Services',
          number: '47788',
          areas: [
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon,\n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'JBPL Electrical Consultancy & Engineering Services',
          number: '32934',
          areas: [
              {name: 'Metro Manila',
               cities: 'Metro Manila: Muntinlupa, Taguig'},
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon,\n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'}
          ]
      });
      this.cbmList.push({
          name: 'JFA Electrical Construction and General Services, Inc.',
          number: '28428',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'JM Samonte Electrical Engineering Services',
          number: '46971',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Meycauayan, Obando'},
              {name: 'Metro Manila',
               cities: 'Quezon City, Caloocan, Novaliches, Valenzuela'}
          ]
      });
      this.cbmList.push({
          name: 'Jonas Electrical Contractor',
          number: '35067',
          areas: [
              {name: 'South Area',
               cities: 'Entire Quezon,\n' +
                       '\n' +
                       'Laguna: San Pablo, Calauan, Alaminos, Rizal, Sta. Cruz, Liliw, Luisiana, Magdalena, ' +
                       'Majayjay, Nagcarlan, Pila, Victoria'},
          ]
      });
      this.cbmList.push({
          name: 'JPA Electrical Engineering Services',
          number: '38372',
          areas: [
              {name: 'Metro Manila',
               cities: 'Manila, Pasay'},
          ]
      });
      this.cbmList.push({
          name: 'JVG22 General Construction, Inc.',
          number: '43647',
          areas: [
              {name: 'Metro Manila',
               cities: 'Marikina'},
              {name: 'East Area',
               cities: 'Entire Rizal'},
          ]
      });
      this.cbmList.push({
          name: 'Kenlie Jade Engineering Services',
          number: '48900',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Balagtas, Bocaue, Bulacan, Guiguinto, Marilao, Pandi, Baliwag, Bustos, Pulilan, ' +
                       'San Ildefonso, San Miguel, San Rafael, Malolos, ' +
                       'Calumpit, Hagonoy, Paombong, Plaridel,\n' +
                       '\n' +
                      'Pampanga: Apalit, Candaba, San Simon'}
          ]
      });
      this.cbmList.push({
          name: 'Kirkate Enterprise',
          number: '45363',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                  cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'L. A. Garcia Electrical Contractor',
          number: '47140',
          areas: [
              {name: 'Metro Manila',
               cities: 'Makati, Manila, Pasay, Muntinlupa, Taguig, Las Piñas'},
              {name: 'South Area',
               cities: 'Entire Cavite and Quezon,\n' +
                       '\n' +
                       'Laguna: San Pablo, Calauan, Alaminos, Rizal, San Pedro, Sta. Rosa,\n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'Laguna Installer Services and Enterprisesr',
          number: '41262',
          areas: [
              {name: 'Metro Manila',
               cities: 'Rizal: Angono, Binangonan, Teresa, Cardona, Morong, Baras, Tanay, Pililia, Jalajala'},
              {name: 'South Area',
               cities: 'Laguna: Calamba, Binan, Cabuyao, Bay, Los Baños, San Pablo, Calauan, Alaminos, Rizal, \n' +
                       '\n' +
                       'Batangas: Sto. Tomas,\n' +
                       '\n' +
                       'Quezon: Tiaong, San Antonio, Candelaria, Dolores, \n' +
                       '\n' +
                       'Cavite: Dasmarinas, Carmona, GMA, Silang, Rosario, Gen. Trias, Tanza, Naic, Maragondon, ' +
                       'Ternate, Kawit, Trece Martirez, Noveleta, Cavite City'},
          ]
      });
      this.cbmList.push({
          name: 'LRM Electrical Services',
          number: '45414',
          areas: [
              {name: 'Metro Manila',
               cities: 'Metro Manila: Muntinlupa, Taguig'},
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon, \n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'Madz Electrical Services',
          number: '48688',
          areas: [
              {name: 'North Area',
                  cities: 'Bulacan: Sta. Maria, Angat, Norzagaray, San Jose Del Monte, Doña Remedios Trinidad'}
          ]
      });
      this.cbmList.push({
          name: 'Marcy Electrical Services',
          number: '47679',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Meycauayan, Obando'},
              {name: 'Metro Manila',
               cities: 'Quezon City, Caloocan, Novaliches, Valenzuela'},
          ]
      });
      this.cbmList.push({
          name: 'Marichton Electrical Services',
          number: '40850',
          areas: [
              {name: 'Metro Manila',
               cities: 'Makati, Pasay, Parañaque'}
          ]
      });
      this.cbmList.push({
          name: 'Maxrate Construction Inc.',
          number: '43678',
          areas: [{name: 'North Area',
              cities: 'Entire Meralco franchise area'},
              {name: 'Metro Manila',
                  cities: 'Entire Meralco franchise area'},
              {name: 'East Area',
                  cities: 'Entire Meralco franchise area'},
              {name: 'South Area',
                  cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'Mecoret Electrical Services Inc.',
          number: '46605',
          areas: [
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon, \n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'}
          ]
      });
      this.cbmList.push({
          name: 'Merle Builders and Construction Supply',
          number: '46322',
          areas: [{name: 'South Area',
                  cities: 'Entire Laguna and Quezon,\n' +
                      '\n' +
                      'Batangas: Batangas City, San Pascual, Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'Mobilect Power Corporation',
          number: '34293',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'MTT Electrical Contractor and General Services',
          number: '46039',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'NJ5 Electrical Services',
          number: '46913',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'NSS Electrical Services, Inc.',
          number: '36912',
          areas: [
              {name: 'Metro Manila',
               cities: 'Marikina, Pasig'},
              {name: 'East Area',
               cities: 'Angono, Binangonan, Teresa, Cardona, Morong, Baras, Tanay, ' +
                       'Pililia, Jalajala, Cainta, Taytay, Antipolo, San Mateo, Rodriguez'},
          ]
      });
      this.cbmList.push({
          name: 'O. C. Lustre Electrical Contractor',
          number: '38360',
          areas: [
              {name: 'Metro Manila',
               cities: 'Manila'}
          ]
      });
      this.cbmList.push({
          name: 'OSOLLE Electrical Engineering Services',
          number: '47458',
          areas: [
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon, \n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'}
          ]
      });
      this.cbmList.push({
          name: 'P.A. Castillo Electrical Inc',
          number: '40292',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'PAV JR. Electrical Services',
          number: '45272',
          areas: [
              {name: 'East Area',
               cities: 'Bacoor, Dasmarinas, Rosario, Tagaytay, Batangas'}
          ]
      });
      this.cbmList.push({
        name: "Power D's Electrical Services",
        number: '47501',
        areas: [
            {name: 'North Area',
             cities: 'Entire Bulacan'},
            {name: 'Metro Manila',
             cities: 'Quezon City, Caloocan, Novaliches, Navotas, Valenzuela'},
        ]
    });
      this.cbmList.push({
          name: 'Power M Builders',
          number: '47676',
          areas: [
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon, \n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual, Sto. Tomas'}
          ]
      });
      this.cbmList.push({
          name: 'Powercore Electrical Construction Corp.',
          number: '40016',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'Powerserver Multi-Services Corp.',
          number: '48166',
          areas: [
              {name: 'Metro Manila',
               cities: 'Muntinlupa, Las Piñas, Parañaque, '},
              {name: 'South Area',
               cities: 'Entire Laguna and Quezon,\n' +
                       '\n' +
                       'Batangas: Batangas City, San Pascual and Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'Premiere Compliance Engineering and Services',
          number: '41186',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'R.A. Dela Rosa Electrical Services',
          number: '45307',
          areas: [
              {name: 'Metro Manila',
               cities: 'Makati, Manila, Pasay, Mandaluyong, San Juan, Pasig, Taguig, Parañaque'}
          ]
      });
      this.cbmList.push({
          name: 'RD Alunday Electrical Services',
          number: '46930',
          areas: [
              {name: 'Metro Manila',
               cities: 'Quezon City, Novaliches, Caloocan, Marikina, Pasig, Taguig, Las Piñas, Parañaque'},
              {name: 'East Area',
               cities: 'San Mateo, Rodriguez'}
          ]
      });
      this.cbmList.push({
          name: 'Regen Engineering and Industrial Services',
          number: '49094',
          areas: [
              {name: 'North Area',
              cities: 'Bulacan: Baliwag, Bustos, Pulilan, San Ildefonso, San Miguel, ' +
                      'San Rafael, Malolos, Calumpit, Hagonoy, Paombong, Plaridel,\n' +
                      '\n' +
                      'Pampanga: Apalit, Candaba, San Simon'}
          ]
      });
      this.cbmList.push({
          name: 'Riels Enterprises',
          number: '46722',
          areas: [
              {name: 'South Area',
               cities: 'Cavite: Dasmarinas, Carmona, GMA, Silang, Tagaytay, Alfonso, ' +
                       'Amadeo, Bailen, Indang, Magallanes, Mendez, Rosario, Gen. Trias, ' +
                       'Tanza, Naic, Maragondon, Ternate, Kawit, Trece Martirez, Noveleta, Cavite City'}
          ]
      });
      this.cbmList.push({
          name: 'Roleo Electrical Contractor',
          number: '46159',
          areas: [
              {name: 'South Area',
                  cities: 'Cavite: Cavite City, General Trias, Tanza, Naic, ' + 
                  'Maragondon, Ternate, Kawit, Trece Martirez, Noveleta, Rosario'}
          ]
      });
      this.cbmList.push({
          name: 'Ronette Electrical Services',
          number: '5132',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Balagtas, Bocaue, Bulacan, Guiguinto, Marilao, Pandi, Baliwag, ' +
                       'Bustos, Pulilan, San Ildefonso, San Miguel, San Rafael, Candaba, ' +
                       'San Simon, Malolos, Calumpit, Hagonoy, Paombong, Plaridel, ' +
                       'Apalit, Meycauayan, Obando'},
              {name: 'Metro Manila',
               cities: 'Valenzuela'}
          ]
      });
      this.cbmList.push({
          name: 'RRC Electrical Contractor and Construction',
          number: '38802',
          areas: [
              {name: 'Metro Manila',
               cities: 'Manila, Pasay, Mandaluyong, San Juan, Pasig'}
          ]
      });
      this.cbmList.push({
          name: 'RSDG Electrical Services Co.',
          number: '47224',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Sta. Maria, Angat, Norzagaray, San Jose Del Monte, Doña Remedios Trinidad'}
          ]
      });
      this.cbmList.push({
          name: 'Stalwart 360 inc.',
          number: '49077',
          areas: [
              {name: 'North Area',
              cities: 'Bulacan: Baliwag, Bustos, Pulilan, San Ildefonso, San Miguel, San Rafael, ' +
                      'Malolos, Calumpit, Hagonoy, Paombong, Plaridel,\n' +
                      '\n' +
                      'Pampanga: Apalit, Candaba, San Simon'},
              {name: 'Metro Manila',
               cities: 'Quezon City, Manila, Makati, Pasay, Mandaluyong, San Juan, Marikina'},
              {name: 'East Area',
               cities: 'Entire Rizal'}
          ]
      });
      this.cbmList.push({
          name: 'Starfern Electrical Services',
          number: '47542',
          areas: [
              {name: 'South Area',
               cities: 'Entire Cavite,\n' +
                       '\n' +
                       'Laguna: San Pedro, Sta. Rosa'}
          ]
      });
      this.cbmList.push({
          name: 'Suay Electrical, Building and Grounds Maintenance Services',
          number: '50423',
          areas: [
              {name: 'South Area',
                  cities: 'Entire Cavite, Laguna and Quezon,\n' +
                          '\n' +
                          'Batangas: Batangas City, San Pascual and Sto. Tomas'}
          ]
      });
      this.cbmList.push({
          name: 'T&D Power Systems Corporation',
          number: '34907',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'Tandem Electrical Corp.',
          number: '39046',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'Tibay electrical installation services',
          number: '48538',
          areas: [
              {name: 'East Area',
               cities: 'Angono, Binangonan, Teresa, Cardona, Morong, Baras,' +
                'Tanay, Pililia, Jalajala, Cainta, Taytay, Antipolo'}
          ]
      });
      this.cbmList.push({
          name: 'Triple DJ Electrical Supplies & Services',
          number: '45269',
          areas: [{name: 'North Area',
                  cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                      cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                      cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'Uni-mark electrical services',
          number: '47447',
          areas: [
              {name: 'North Area',
               cities: 'Bulacan: Balagtas, Bocaue, Bulacan, Guiguinto, Marilao, Pandi, Baliwag, Bustos, Pulilan, ' +
                       'San Ildefonso, San Miguel, San Rafael, Malolos, Calumpit, Hagonoy, Paombong, ' +
                       'Plaridel, Sta. Maria, Angat, Norzagaray, San Jose Del Monte, Doña Remedios Trinidad,\n' +
                       '\n' +
                       'Pampanga: Apalit, Candaba, San Simon'},
              {name: 'Metro Manila',
               cities: 'Muntinlupa, Taguig, Las Piñas, Parañaque'},
              {name: 'South Area',
               cities: 'Entire Cavite,\n' +
                       '\n' +
                       'Laguna: San Pablo, Calauan, Alaminos, Rizal, Sta. Cruz, Liliw, Luisiana, Magdalena, ' +
                       'Majayjay, Nagcarlan, Pila, Victoria, San Pedro, Sta. Rosa,\n' +
                       '\n' +
                       'Quezon: Tiaong, San Antonio, Candelaria, Dolores'}
          ]
      });
      this.cbmList.push({
          name: 'Unitec Resources, Inc.',
          number: '27756',
          areas: [{name: 'North Area',
                   cities: 'Entire Meralco franchise area'},
                  {name: 'Metro Manila',
                   cities: 'Entire Meralco franchise area'},
                  {name: 'East Area',
                   cities: 'Entire Meralco franchise area'},
                  {name: 'South Area',
                   cities: 'Entire Meralco franchise area'},
          ]
      });
      this.cbmList.push({
          name: 'VBAC Electrical Engineering Services',
          number: '48866',
          areas: [
              {name: 'Metro Manila',
              cities: 'Manila'},
              {name: 'East Area',
               cities: 'Angono, Binangonan, Teresa, Cardona, Morong, Baras, Tanay, \n' +
               '\n' +
               'Pililla, Pinugay, Sampaloc, Daraitan, Jala-Jala, Cainta, Antipolo'}
          ]
      });
      this.cbmList.push({
          name: 'WCCC Electrical Services',
          number: '45238',
          areas: [
              {name: 'North Area',
               cities: 'Balagtas, Baliwag, Malolos, Sta. Maria'},
              {name: 'Metro Manila',
               cities: 'Entire Metro Manila'},
              {name: 'East Area',
              cities: 'Entire Rizal'},
              {name: 'South Area',
               cities: 'Entire Cavite, Laguna and Quezon,\n' +
                        '\n' + 
                      'Batangas: Batangas City, San Pascual and Sto. Tomas'},
          ]
      });
      this.cbmList.push({
          name: 'Wirezone Electrical Services',
          number: '8187',
          areas: [
              {name: 'North Area',
               cities: 'Entire Bulacan, Pampanga: Apalit, \n' +
                       'Candaba, San Simon'}
          ]
      });
  }
  readList() {

      fetch('/assets/json/contractors.json')
          .then( r => r.text())
          .then( t => {
                const data = JSON.parse(t);
                this.cbmList = data.cbm_list;
                this.date = data.date;
          } );
  }

}
