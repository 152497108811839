import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostListener,
} from "@angular/core";

@Component({
  selector: "applied-load-guide-modal",
  templateUrl: "./applied-load-guide.component.html",
  styleUrls: ["./applied-load-guide.component.scss"],
})
export class AppliedLoadGuideModalComponent implements OnInit {
  @Input() title: string = "";
  @Input() isMobileView: boolean;
  @Input() confirmText: string = "OK";
  @Input() visible: boolean = true;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  isTablet: boolean = false;
  isDesktop: boolean = false;

  isLandscape: boolean = false;
  isPortrait: boolean = false;

  private landscapeMediaQuery: MediaQueryList;
  private portraitMediaQuery: MediaQueryList;

  @HostListener("window:resize")
  onResize() {
    this.handleOrientationChange()
    this.checkViewport();
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event: Event) {
    this.checkViewport();
  }

  constructor() {}
  ngOnInit() {
    console.log("INIT APPLIED LOAD MODAL, VIEW IS = ", this.isMobileView);
    this.checkViewport();
  }

  confirm() {
    this.onConfirm.emit();
    this.visible = false;
  }

  checkViewport() {
    this.checkMobileViewport()
    this.isTablet = window.matchMedia('(min-width: 768px) and (max-width: 1024px) and (min-height: 768px)').matches;
    this.isDesktop = window.matchMedia('(min-width: 1024px)').matches;
  }


  checkMobileViewport() {
    this.landscapeMediaQuery = window.matchMedia('(max-height: 420px) and (orientation: landscape)');
    this.portraitMediaQuery = window.matchMedia('(max-width: 420px) and (orientation: portrait)');
  }
  
  handleOrientationChange() {
    if (window.matchMedia('(orientation: landscape)').matches) {
      this.isMobileView = this.landscapeMediaQuery.matches
      this.isLandscape = this.landscapeMediaQuery.matches;
    } else {
      this.isMobileView = this.portraitMediaQuery.matches
      this.isPortrait = this.portraitMediaQuery.matches;
    }
  }
}
