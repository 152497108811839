// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  align-items: center;
  padding: 0;
}

.rating-container {
  position: relative;
}

.rating {
  color: #e7711b;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 22px;
  background-color: orange;
  -webkit-mask-image: url('cb_star_active.svg');
  mask-image: url('cb_star_active.svg');
}

.empty {
  width: 124px;
  height: 22px;
  top: 0;
  background-color: #808080;
  -webkit-mask-image: url('cb_star_inactive.svg');
  mask-image: url('cb_star_inactive.svg');
}

.review-container {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/stardetails/stardetails.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,UAAA;AACJ;;AAEE;EACE,kBAAA;AACJ;;AAEE;EACE,cAAA;EACA,UAAA;EACA,kBAAA;EACA,UAAA;EACA,cAAA;EACA,MAAA;EACA,OAAA;EACA,gBAAA;EACA,YAAA;EACA,wBAAA;EACA,6CAAA;EACA,qCAAA;AACJ;;AAEE;EACE,YAAA;EACA,YAAA;EACA,MAAA;EACA,yBAAA;EACA,+CAAA;EACA,uCAAA;AACJ;;AAEE;EACE,gBAAA;AACJ","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    padding: 0;\n  }\n  \n  .rating-container {\n    position: relative;\n  }\n  \n  .rating {\n    color: #e7711b;\n    padding: 0;\n    position: absolute;\n    z-index: 1;\n    display: block;\n    top: 0;\n    left: 0;\n    overflow: hidden;\n    height: 22px;\n    background-color: orange;\n    -webkit-mask-image: url('~assets/img/icons/cb_star_active.svg');\n    mask-image: url('~assets/img/icons/cb_star_active.svg');\n  }\n  \n  .empty {\n    width: 124px;\n    height: 22px;\n    top: 0;\n    background-color: #808080;\n    -webkit-mask-image: url('~assets/img/icons/cb_star_inactive.svg');\n    mask-image: url('~assets/img/icons/cb_star_inactive.svg');\n  }\n  \n  .review-container {\n    margin-left: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
