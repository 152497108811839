import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//import { NgbModule, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { NgbdCarouselBasic } from './carousel-basic.component';

@NgModule({
  imports: [BrowserModule],
  declarations: [NgbdCarouselBasic],
  exports: [NgbdCarouselBasic],
  bootstrap: [NgbdCarouselBasic]
})

export class NgbdCarouselBasicModule{

}