import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'otp-expiredtimer',
  templateUrl: './otp-expired.component.html',
  styleUrls: ['./otp-expired.component.scss']
})
export class OtpExpiredComponent implements OnInit {
  @Input() visible: boolean;
  @Input() selectionVisible: boolean;
  @Input() intervalVisible: boolean;
  @Input() isSMS: boolean;
  @Input() inputtedSMSorEmail: string;
  @Input() remainingAttempts: string;
  @Output() onRetryMobile: EventEmitter<string> = new EventEmitter();
  @Output() onRetryEmail: EventEmitter<string> = new EventEmitter();
  @Output() onCancel: EventEmitter <string>  = new EventEmitter();

  // UI
  mobileVisible: boolean = false;
  emailVisible: boolean = false;
  form: UntypedFormGroup ;

  constructor(private fb: UntypedFormBuilder) { }
  ngOnInit() {
    // console.log("BAM - start of OTP EXPIRED");
  
    // console.log("IS SMS = ", this.isSMS);
    this.initForm();
  }
  initForm() {
    this.form = this.fb.group({
      mobile: ['', [Validators.required, Validators.pattern('^\\+639\\d{9}$')]],
      email: ['', [Validators.required,
                   Validators.minLength(10),
                   Validators.maxLength(255),
                   Validators.pattern('^[A-Za-z0-9\\.\\-\\_]+@[\\w\\-\\.]+\\.[A-Za-z]{2,3}$')]]
    });
  }
 
  newMobile() {
    this.showMobile();
  }
  newEmail() {
    this.showEmail();
  }
  showEmail() {
    this.selectionVisible = false;
    this.mobileVisible = false;
    this.emailVisible = true;
  }
  showMobile() {
    this.selectionVisible = false;
    this.mobileVisible = true;
    this.emailVisible = false
  }
  showSelection() {
    this.selectionVisible = true;
    this.mobileVisible = false;
    this.emailVisible = false
  }
  retryEmail() {
    if(this.intervalVisible == false)
    {
      this.onRetryEmail.emit(this.inputtedSMSorEmail);
      this.visible = false;
    }
    else
    {
      this.onRetryEmail.emit('');
      this.visible = false;
    }
    //this.showSelection();
  }
  retryMobile() {
    if(this.intervalVisible == false)
    {
      this.onRetryMobile.emit(this.inputtedSMSorEmail);
      this.visible = false;
    }
    else
    {
      this.onRetryMobile.emit('');
      this.visible = false;
    }
    //this.showSelection();
  }

  cancel() {
    this.onCancel.emit();
  }




}
