import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';


@Component({
  selector: 'cbm-detail-modal',
  templateUrl: './cbm-detail.component.html',
  styleUrls: ['./cbm-detail.component.scss'],
})
export class CbmDetailComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() cbmID: string = '';
  // @Input() cbmRating: number;
  @Input() cbmData: any = null;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<string> = new EventEmitter();
  @Output() onViewAll: EventEmitter<string> = new EventEmitter();

  feedbacks: any[] = [];
  sortType: string = 'Rating';
  expandCities: boolean = false;
  cbmDetails: any = null;

  constructor(private cbmService: ApiService) {
    this.cbmDetails = this.cbmData;
  }
  ngOnInit() {
    //  console.log('this is the rating', this.cbmRating);
  }
  dismiss() {
    this.onDismiss.emit();
  }
  select() {
    this.onSelect.emit(this.cbmID);
    this.onDismiss.emit();
  }
  ceil(value: number): number {
    return Math.ceil(value);
  }
  viewAll() {
    this.onViewAll.emit(this.cbmID);
    this.onDismiss.emit();
  }

  getRatingValue(rating: string | number): number {
    if(!rating) return 0
    if (typeof rating === 'string') {
      rating = parseFloat(rating);
    }
    return parseFloat(rating.toFixed(1));
  }
  getRating(): number {
    return this.cbmData['contractor']['rating'];
  }
  getReviews(sort = 'Rating') {
    const list =
      sort === 'Rating'
        ? this.sortFeedbackByRating()
        : this.sortFeedbackByDate();
    const res = [];
    let ctr = 0;
    for (const f of list) {
      if (ctr >= 5) {
        break;
      }
      res.push(f);
      ctr++;
    }
    return res;
  }
  sortFeedbackByRating() {
    const list = this.cbmData['feedback'];
    for (let c = 0; c < list.length; c++) {
      let maxIndex = c;
      let maxRating = this.computeRating(list[c]);
      for (let d = c; d < list.length; d++) {
        const rating = this.computeRating(list[d]);
        if (rating > maxRating) {
          maxRating = rating;
          maxIndex = d;
        }
      }
      const temp = list[c];
      list[c] = list[maxIndex];
      list[maxIndex] = temp;
    }
    return list;
  }
  sortFeedbackByDate() {
    const list = this.cbmData['feedback'];
    for (let c = 0; c < list.length; c++) {
      let maxIndex = c;
      let maxDate = list[c]['date_created'];
      for (let d = c; d < list.length; d++) {
        const date = list[d]['date_created'];
        if (date > maxDate) {
          maxDate = date;
          maxIndex = d;
        }
      }
      const temp = list[c];
      list[c] = list[maxIndex];
      list[maxIndex] = temp;
    }
    return list;
  }
  computeRating(feedback: any) {
    const sum =
      feedback['customer_service'] +
      feedback['work_quality'] +
      feedback['response_time'];
    return sum / 3;
  }
  hideName(name: string) {
    let temp = '';
    name = name.toLowerCase();
    for (let c = 0; c < name.length; c++) {
      if (c === 0 || c === name.length - 1) {
        temp += name.charAt(c);
        continue;
      }
      temp += '*';
    }
    return temp;
  }
}
