import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'otp-retry',
  templateUrl: './otp-retry.component.html',
  styleUrls: ['./otp-retry.component.scss']
})
export class OtpRetryComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() isSMS: boolean;
  @Output() onRetryMobile: EventEmitter<string> = new EventEmitter();
  @Output() onRetryEmail: EventEmitter<string> = new EventEmitter();
  @Output() onCancel: EventEmitter <string>  = new EventEmitter();

  // UI
  isMobile: boolean = false;
  selectionVisible: boolean = true;
  mobileVisible: boolean = false;
  emailVisible: boolean = false;
  form: UntypedFormGroup ;

  constructor(private fb: UntypedFormBuilder) { }
  ngOnInit() {
    // console.log("BAM - start of OTP RETRY");
  
    // console.log("IS RETRY MOBILE", + this.isSMS);
    this.isMobile = this.isSMS;
    this.initForm();
  }
  initForm() {
    this.form = this.fb.group({
      mobile: ['', [Validators.required, Validators.pattern('^\\+639\\d{9}$')]],
      email: ['', [Validators.required,
                   Validators.minLength(10),
                   Validators.maxLength(255),
                   Validators.pattern('^[A-Za-z0-9\\.\\-\\_]+@[\\w\\-\\.]+\\.[A-Za-z]{2,3}$')]]
    });
  }

  newMobile() {
    this.showMobile();
  }
  newEmail() {
    this.showEmail();
  }
  showEmail() {
    this.selectionVisible = false;
    this.mobileVisible = false;
    this.emailVisible = true;
  }
  showMobile() {
    this.selectionVisible = false;
    this.mobileVisible = true;
    this.emailVisible = false
  }
  showSelection() {
    this.selectionVisible = true;
    this.mobileVisible = false;
    this.emailVisible = false
  }
  retryEmail() {

    this.selectionVisible = true;
    this.mobileVisible = false;
    this.emailVisible = false;
    this.onRetryEmail.emit(this.form.controls['email'].value);
    this.visible = false;
    //this.showSelection();
  }
  retryMobile() {
    this.selectionVisible = true;
    this.mobileVisible = false;
    this.emailVisible = false;
    this.onRetryMobile.emit(this.form.controls['mobile'].value);
    this.visible = false;
    this.showSelection();
  }

  cancel() {
    this.mobileVisible = false;
    this.emailVisible = false;
    this.onCancel.emit();
  }




}
