import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Output, Input, EventEmitter } from '@angular/core';


@Component({
  selector: 'select-cst',
  templateUrl: './select-cst.component.html',
  styleUrls: ['./select-cst.component.scss']
})
export class SelectCstComponent implements OnInit {
  @Input() items: string[] = ['Item 1', 'Item 2', 'Item 3'];
  @Input() selectedItem = '';
  @Input() placeHolder = 'Select an Item';
  @Output() itemSelected: EventEmitter<String> = new EventEmitter();
  @Input() width = '324px';
  @Input() titleCase = false;
  @Input() disabled = false;
  dropdownVisible: boolean;
  focused: boolean;
  hovered: boolean;
  hasItemSelected: boolean;
  constructor() {
    this.dropdownVisible = false;
    this.hovered = false;
    this.hasItemSelected = false;
  }
  ngOnInit() {
  }
  hideDropdown() {
    this.focused = false;
  }
  toggleFocus() {
    if (this.disabled) {
      return;
    }
    if ( this.items.length === 0) {
        this.focused = false;
        return;
    }
    this.focused = ! this.focused;
  }
  onItemSelected(value: string) {
    this.focused = false;
    this.selectedItem = value;
    this.hasItemSelected = true;
    this.itemSelected.emit(value);
  }
  onMouseOver() {
    this.hovered = true;
  }
  onMouseLeft() {
    this.hovered = false;
  }
}
