import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ReferenceDataProviderService } from './http-provider.service';
// import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  constructor(private httpProvider: ReferenceDataProviderService) { }

  public getOfficeAssignment(){
    return this.httpProvider.sendGetRequest('/list/OfficeAssignment', new HttpHeaders, new HttpParams);
  }

  public getAllCities(){
    return this.httpProvider.sendGetRequest('/list/City', new HttpHeaders, new HttpParams);
  }

  public getProvinces(){
    return this.httpProvider.sendGetRequest('/cxe/list/locations', new HttpHeaders, new HttpParams);
  }

  public getCities(provinceId:string){
    return this.httpProvider.sendGetRequest(`/cxe/list/locations?provinceId=${provinceId}`, new HttpHeaders, new HttpParams);
  }

  public getBarangay(cityId:string){
    return this.httpProvider.sendGetRequest(`/cxe/list/locations?cityId=${cityId}`, new HttpHeaders, new HttpParams);
  }

  public getBundle(){
    return this.httpProvider.sendGetRequest('/list/getBundle', new HttpHeaders, new HttpParams);
  }

  public getUserType(){
    return this.httpProvider.sendGetRequest('/list/getUserType', new HttpHeaders, new HttpParams);
  }

  public getServiceType(){
    return this.httpProvider.sendGetRequest('/list/ServiceType', new HttpHeaders, new HttpParams);
  }

  public getServiceStatus(type: string){
    type = encodeURI(type);
    return this.httpProvider.sendGetRequest(`/list/ServiceStatus?type=${type}`, new HttpHeaders, new HttpParams);
  }

  public getMembershipType(){
    return this.httpProvider.sendGetRequest('/list/MembershipType', new HttpHeaders, new HttpParams);
  }


}