// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 0px;
  margin: 0px;
  background-color: #5F96BA;
  z-index: 500;
}

.footer-container {
  display: flex;
  flex-direction: row;
  height: 165px;
  padding: 50px;
}

.footer-copyright {
  flex: 1;
  font-size: 13px;
  color: #FFF;
}

.footer-icon {
  width: 18px;
  height: 18px;
  margin: 5px;
}

.footer-lg {
  font-family: "Gotham-Bold", serif;
  color: #FFF;
  font-size: 22px;
}

.footer-sm {
  font-family: "Lato-Regular", serif;
  color: #FFF;
  font-size: 18px;
}

.footer-link {
  color: inherit;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .footer-container {
    flex-direction: column;
    height: auto;
  }
  .footer-sm {
    font-size: 16px;
  }
  .footer-lg {
    font-size: 20px;
  }
  .about-us {
    margin-top: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;AAEF;;AAAA;EACE,OAAA;EACA,eAAA;EACA,WAAA;AAGF;;AADA;EACE,WAAA;EACA,YAAA;EACA,WAAA;AAIF;;AAFA;EACE,iCAAA;EACA,WAAA;EACA,eAAA;AAKF;;AAHA;EACE,kCAAA;EACA,WAAA;EACA,eAAA;AAMF;;AAJA;EACE,cAAA;EACA,qBAAA;AAOF;;AALA;EACE,qBAAA;AAQF;;AANA;EACE;IACE,sBAAA;IACA,YAAA;EASF;EAPA;IACE,eAAA;EASF;EAPA;IACE,eAAA;EASF;EAPA;IACE,gBAAA;EASF;AACF","sourcesContent":[".footer{\n  padding: 0px;\n  margin: 0px;\n  background-color: #5F96BA;\n  z-index: 500;\n}\n.footer-container{\n  display: flex;\n  flex-direction: row;\n  height: 165px;\n  padding: 50px;\n}\n.footer-copyright{\n  flex: 1;\n  font-size: 13px;\n  color: #FFF;\n}\n.footer-icon{\n  width: 18px;\n  height: 18px;\n  margin: 5px;\n}\n.footer-lg {\n  font-family: \"Gotham-Bold\", serif;\n  color: #FFF;\n  font-size: 22px;\n}\n.footer-sm {\n  font-family: \"Lato-Regular\", serif;\n  color: #FFF;\n  font-size: 18px;\n}\n.footer-link {\n  color: inherit;\n  text-decoration: none;\n}\n.footer-link:hover{\n  text-decoration: none;\n}\n@media screen and (max-width: 1000px){\n  .footer-container{\n    flex-direction: column;\n    height: auto;\n  }\n  .footer-sm {\n    font-size: 16px;\n  }\n  .footer-lg{\n    font-size: 20px;\n  }\n  .about-us{\n    margin-top: 40px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
