import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'select-cbm-stars',
  templateUrl: './select-cbm-stars.component.html',
  styleUrls: ['./select-cbm-stars.component.scss']
})
export class SelectCbmStarsComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() selectedIndex: number[] = [];
  @Input() placeHolder = 'Select Star Rating';
  @Input() width = '285px';
  @Input()  showSelectAll: boolean = true;
  @Output() onSelectionChanged: EventEmitter<String[]> = new EventEmitter();
  @Output() onIndexSelectionChanged: EventEmitter<number[]> = new EventEmitter();
  maxRating: number = 5;
  dropdownVisible: boolean;
  focused: boolean;
  hovered: boolean;
  hasItemSelected: boolean;
  selectAll: boolean = false;

  constructor() {
    this.dropdownVisible = false;
    this.hovered = false;
    this.hasItemSelected = false;
    this.items = [ { rating: 5 , name: '5 Stars' },
                   { rating: 4 , name: '4 Stars' },
                   { rating: 3 , name: '3 Stars' },
                   { rating: 2 , name: '2 Stars' },
                   { rating: 1 , name: '1 Star' },
                   { rating: 0 , name: 'No Ratings' }];
  }
  ngOnInit() {
  }
  hideDropdown() {
    this.focused = false;
  }
  toggleFocus() {
    this.focused = ! this.focused;
  }
  onMouseOver() {
    this.hovered = true;
  }
  onMouseLeft() {
    this.hovered = false;
  }
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.onSelectAll();
  }
  onSelectAll() {
    this.selectedIndex = [];
    if (this.selectAll) {
      for (let c = 0 ; c < this.items.length; c++) {
        this.selectedIndex.push(c);
      }
    }
    this.selectionChanged();
  }
  onItemSelected(index: number) {
    if ( !this.selectedIndex.includes(index)) {
      this.addItem(index);
    } else {
      this.removeItem(index, null);
    }
  }
  removeItem(index: number, $event = null) {
    if ( this.selectedIndex.includes(index)) {
      this.selectedIndex.splice(this.selectedIndex.indexOf(index), 1);
      this.selectionChanged();
    }
    if ($event != null) {
      $event.stopPropagation();
    }
  }
  addItem(index: number) {
    if ( !this.selectedIndex.includes(index)) {
      this.selectedIndex.push(index);
      this.selectionChanged();
    }
  }
  selectionChanged() {
    const selectedItems: string[] = [];
    for (const index of this.selectedIndex) {
      selectedItems.push(this.items[index].rating + '');
    }
    this.onSelectionChanged.emit(selectedItems);
    this.onIndexSelectionChanged.emit(this.selectedIndex);
  }

}
