import { Routes } from '@angular/router';
import { LandingComponent} from './pages/landing/landing.component';
import { ViewRequestComponent} from './pages/servicerequest/view/view-request.component';
import {TrackComponent} from './pages/servicerequest/track/track.component';
import {CreateComponent} from './pages/servicerequest/create/create-request.component';
import {ContractorDetailComponent} from './pages/contractor-detail/contractor-detail.component';
import {AboutComponent} from './pages/about/about.component';
import {TermsComponent} from './pages/terms/terms.component';


export const AppRoutes: Routes = [
  { path: 'landing',
    component: LandingComponent,
    pathMatch: 'full',
  }, {
    path: 'create',
    component: CreateComponent,
    pathMatch: 'full',
  }, {
    path: 'track',
    component: TrackComponent,
    pathMatch: 'full',
  }, {
    path: 'viewrequest',
    component: ViewRequestComponent,
    pathMatch: 'full',
  }, {
    path: 'contractor-detail/:uuid',
    component: ContractorDetailComponent,
    pathMatch: 'full',
  }, {
    path: 'about',
    component: AboutComponent,
    pathMatch: 'full',
  }, {
    path: 'terms',
    component: TermsComponent,
    pathMatch: 'full',
  }, {
    path: '**',
    redirectTo: 'landing'
  }
]
