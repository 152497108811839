import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cbm-selector-item',
  templateUrl: './cbm-selector-item.component.html',
  styleUrls: ['./cbm-selector-item.component.scss']
})
export class CbmSelectorItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
