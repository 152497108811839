import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'select-cbm-pcab',
  templateUrl: './select-cbm-pcab.component.html',
  styleUrls: ['./select-cbm-pcab.component.scss']
})
export class SelectCbmPcabComponent implements OnInit {
  @Input() items: string[] = [];
  @Input() selectedIndex: number[] = [];
  @Input() placeHolder = 'PCAB Category';
  @Input()  showSelectAll: boolean = true;
  @Output() onSelectionChanged: EventEmitter<String[]> = new EventEmitter();
  @Output() onIndexSelectionChanged: EventEmitter<number[]> = new EventEmitter();
  selectedItems: string[]  = [];
  dropdownVisible: boolean;
  focused: boolean;
  hovered: boolean;
  hasItemSelected: boolean;
  selectAll: boolean = false;

  constructor() {
    this.dropdownVisible = false;
    this.hovered = false;
    this.hasItemSelected = false;
    this.items = ['AAAA',
                  'AAA',
                  'AA',
                  'A',
                  'B',
                  'C',
                  'D',
                  'E'];
  }
  ngOnInit() {
  }
  hideDropdown() {
    this.focused = false;
  }
  toggleFocus() {
    this.focused = ! this.focused;
  }
  onMouseOver() {
    this.hovered = true;
  }
  onMouseLeft() {
    this.hovered = false;
  }
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.onSelectAll();
  }
  onSelectAll() {
    this.selectedIndex = [];
    if (this.selectAll) {
      for (let c = 0 ; c < this.items.length; c++) {
        this.selectedIndex.push(c);
      }
    }
    this.selectionChanged();
  }
  onItemSelected(index: number) {
    if ( !this.selectedIndex.includes(index)) {
      this.addItem(index);
    } else {
      this.removeItem(index);
    }
  }
  removeItem(index: number, $event = null) {
    if ( this.selectedIndex.includes(index)) {
      this.selectedIndex.splice(this.selectedIndex.indexOf(index), 1);
      this.selectionChanged();
    }
    if ($event != null) {
      $event.stopPropagation();
    }
  }
  addItem(index: number) {
    if ( !this.selectedIndex.includes(index)) {
      this.selectedIndex.push(index);
      this.selectionChanged();
    }
  }
  selectionChanged() {
    const selectedItems: string[] = [];
    for (const index of this.selectedIndex) {
      selectedItems.push(this.items[index]);
    }
    this.onSelectionChanged.emit(selectedItems);
    this.onIndexSelectionChanged.emit(this.selectedIndex);
  }
}
