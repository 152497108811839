import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'pagination-customer',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() pageCount = 7;
  @Input() selectedPage = 1;
  @Input() pagesPerView = 5;
  @Input() offset = 0;
  @Output() onPageSelected: EventEmitter<number> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }
  updateSelectors() {

  }

  pageSelect(page:  number) {
    this.selectedPage = page;
    this.onPageSelected.emit(page)
    const maxPage = this.offset + this.pagesPerView;
    const minPage = this.offset + 1;
    if (page > maxPage) {
        this.offset += this.pagesPerView;
        return;
    }
    if (page < minPage) {
      this.offset -= this.pagesPerView;
      return;
    }


  }
  nextPage() {
    if ( this.selectedPage + 1 > this.pageCount ) {
      return
    }
    this.pageSelect(this.selectedPage + 1);
  }
  previousPage() {
    if ( this.selectedPage === 1 ) {
      return
    }
    this.pageSelect(this.selectedPage - 1);
  }




}
