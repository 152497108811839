import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';

@Component({
  selector: 'app-cbm-selector',
  templateUrl: './cbm-selector.component.html',
  styleUrls: ['./cbm-selector.component.scss']
})
export class CbmSelectorComponent implements OnInit {
  @Input() city: string = '';
  @Input() rating: number[] = [];
  @Input() pcabCategory: string[] = [];
  @Input() serviceType: string = 'Solar Installation';
  @Input() contractor_class: string = '';
  @Input() selectedCBMS: string[];
  @Output() onItemSelected: EventEmitter<string[]> = new EventEmitter();

  ratingIndex: number[] = []; // holds indexes of selected rating
  pcabCategoryIndex: number[] = []; // holds indexes of selected category
  searchQuery: string = '';

  cbmList: any = [];
  cbmRecommendedID: string[] = [];
  firstChoice: string = '';
  secondChoice: string = '';
  cbmViewId:   string = ''; // holds cbm id to be viewed on dialog
  cbmViewData: any = null; // holds cbm data to be viewed on dialog
  showPagination: boolean = false;
  showMore: boolean = true;
  page: number = 1;
  pageCount: number = 10;
  pageOffset: number = 0 ;
  resultCount: number = 0;
  showProgress: boolean = true;
  // MODALS
  showDetailModal: boolean = false;
  modal_info: boolean = false;
  modal_filter: boolean = false;
  info_title: string = '';
  info_message: string = '';

  constructor(private api: ApiService) {
  }
  ngOnInit() {
    // this.fetchCbm();
    this.fetchRecommended(false);
    if (this.selectedCBMS != null) {
      this.firstChoice = this.selectedCBMS[0];
      this.secondChoice = this.selectedCBMS[1];
    }
  }
  fetchCbm() {

    if(this.searchQuery.trim() !== '') {
      this.page = 1
    }

    const body = {
      city: this.city,
      serviceType: this.serviceType,
      filters: {
        rating: this.rating ,
        pcabCategory: this.pcabCategory
      },
      page: this.page + '',
      keyword: this.searchQuery,
      contractor_class: this.contractor_class
    };
    this.showProgressAnim(true);
    this.api.getCBMList(body).subscribe(
        data => {
          this.showProgressAnim(false);
          const res = data['body'];
          this.resultCount = res['results'] ;
          const list = res['contractors'];
          console.log("CBM LIST = " + list);
          for (const cbm of list) {
            console.log('cbm rating from selector', cbm['rating']);
            this.addCbm(cbm);
          }
          this.updatePagination();
        },
        error => {
          this.showProgressAnim(false);
          console.log(error);
        }
    );
  }
  fetchRecommended(fetchOthers: boolean) {
    this.showProgressAnim(true);
    this.api.getRecommended(this.city, this.serviceType, this.contractor_class)
        .subscribe(
            data => {
              this.showProgressAnim(false);
              const res = data['body'];
              this.cbmRecommendedID = [];
             
              for (const cbm of res) {
               
                this.cbmRecommendedID.push(cbm['uuid']);
                this.addCbm(cbm);
              }
              if (fetchOthers || res.length === 0) {
                this.fetchCbm();
              }
            },
            error => {
              this.showProgressAnim(false);
              console.log(error); }
        );
  }
  onCbmSelected(uuid: string) {
    if (uuid === this.firstChoice) {
      this.firstChoice = '';
      this.itemSelected();
      return;
    }
    if (uuid === this.secondChoice) {
      this.secondChoice = '';
      this.itemSelected();
      return;
    }
    if (this.firstChoice === '' ) {
      this.firstChoice = uuid;
      this.itemSelected();
      return;
    } else if (this.secondChoice === '') {
      this.secondChoice = uuid;
      this.itemSelected();
      return;
    } else {
      this.modal_info = true;
    }
  }
  itemSelected(): void {
    this.onItemSelected.emit([this.firstChoice , this.secondChoice]);
  }
  isSelected(uuid: string): boolean {
    return this.firstChoice === uuid || this.secondChoice === uuid;
  }
  getSelectionOrder(uuid: string) {
    if (uuid === this.firstChoice) {
      return 0;
    } else if (uuid === this.secondChoice) {
      return 1;
    } else {
      return -1;
    }
  }
  onPcabChanged(data: any) {
    this.pcabCategory = data;
  }
  onRatingChanged(data: any) {
    this.rating = data;
  }
  clearFilters() {
    this.modal_filter = false;
    this.clearRating();
    this.clearPcab();
    this.searchQuery = '';
    this.applyFilter();
  }
  clearRating() {
    this.rating = [];
    this.ratingIndex = [];
  }
  clearPcab() {
    this.pcabCategory = [];
    this.pcabCategoryIndex = [];
  }
  applyFilter() {
    this.modal_filter = false;
    this.cbmList = [];
    this.clearSelection();
    this.fetchCbm();
  }
  round(value: number) {
    return Math.round(value);
  }
  floor(value: number): number {
    return Math.floor(value);
  }
  onShowMore() {
    this.showMore = false;
    this.fetchCbm();
  }
  clearSelection() {
    this.firstChoice  = '';
    this.secondChoice = '';
    this.itemSelected();
  }
  onCbmViewSelected(uuid: string) {
    if (!this.isSelected(uuid)) {
      this.onCbmSelected(uuid);
    }
  }
  listContains(uuid: string): boolean {
    for (const cbm of this.cbmList) {
      if ( cbm['uuid'] === uuid) {
        return true;
      }
    }
    return false;
  }
  isRecommended(uuid: string): boolean {
    for (const id of this.cbmRecommendedID) {
      if ( id === uuid) {
        return true;
      }
    }
    return false;
  }
  addCbm(cbm: any): void {
    if (this.listContains(cbm['uuid'])) {
      return;
    }
    if (this.isRecommended(cbm['uuid'])) {
      cbm = {...cbm, recommended: true };
    }
    this.cbmList.push(cbm);
  }
  updatePagination() {
    this.showPagination = this.resultCount > 0 ;
    this.showMore = false;
    this.pageCount = Math.ceil(this.resultCount / 10) ;
  }
  showPage(page: number) {
    if (page === this.page) {
      return;
    }
    this.page = page;
    this.cbmList = [];
    if ( page === 1) {
      this.fetchRecommended(true);
    } else {
      this.fetchCbm();
    }
  }
  showProgressAnim(visible: boolean) {
    this.showProgress = visible;
  }
  showPaginate(visible: boolean) {
    this.showPagination = visible;
  }
  // CBM DETAIL DIALOG
  viewCbmPage(uuid: string) {
    window.open('/contractor-detail/' + uuid, '_blank');
  }
  viewDetail(index: number) {
    const cbmID = this.cbmList[index]['uuid'];
    this.cbmViewId = cbmID;
    this.api.getCBM(cbmID).subscribe(
        data => {
          this.cbmViewData = data['body'];
          console.log("Cmbdata:", this.cbmViewData);
          this.sortCities(this.cbmViewData);
          this.popSelectedCity(this.cbmViewData);
          this.showDetailModal = true;
        },
        e => {
          console.log(e);
        }
    )
  }
  getMaxDate() {
    let str = '';
    const date = new Date();
    const month = date.getMonth() + 1;
    const day   = date.getDate();

    str += date.getFullYear() - 18 ;
    str += '-';
    str += month < 10 ? '0' + month : month ;
    str += '-';
    str += day < 10 ? '0' + day : day;
    return str;
  }
  sortCities(cbmData: any) {
    const list = cbmData['city'];
    let minIndex = 0;
    let minString = '';
    let temp ;
    for (let c = 0 ; c < list.length; c++) {
      minIndex = c;
      minString = list[c]['value'];
      for (let d = c ; d < list.length ; d++) {
        if ( list[d]['value'].localeCompare(minString) < 0 ) {
          minString = list[d]['value'];
          minIndex = d;
        }
      }
      temp = list[c];
      list[c] = list[minIndex];
      list[minIndex] = temp;
    }
  }
  popSelectedCity(cbmData: any) {
    if ( this.city === '') {
      return
    }
    const list = cbmData['city'];
    let index = -1;
    for (let c = 0 ; c < list.length; c++) {
      if (list[c]['value'].localeCompare(this.city, 'en', { sensitivity: 'base' }) === 0) {
        index = c;
        break;
      }
    }
    if (index > -1) {
      const temp = list[0];
      list[0] = list[index];
      list[index] = temp;
    }
  }

}
