import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'customer-confirm-modal',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() title: string = 'Modal Title'
  @Input() message: string = ''
  @Input() confirmText: string = 'OK'
  @Input() cancelText: string = 'Cancel'
  @Input() visible: boolean = true;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter()
  @Output() onConfirm: EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit() {

  }

  dismiss() {
    this.onDismiss.emit();
    this.visible = false;
  }
  confirm() {
    this.onConfirm.emit();
    this.visible = false;
  }







}
