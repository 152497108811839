import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-starratemodal',
  templateUrl: './starratemodal.component.html',
  styleUrls: ['./starratemodal.component.scss']
})
export class StarratemodalComponent {
  @Input() rating: number;
  @Input() reviews: number;
  @Input() starOnly: boolean;

  private _rating: number;
  // ratingValue: number
 
  // ngOninit()
  // {
  //   console.log('rating from parent', this.rating);
  //    this.ratingValue = Math.floor(this.rating * 10)/10;
  //    console.log("rating after floor", this.ratingValue);
  // }
  

}
