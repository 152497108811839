import { Injectable } from '@angular/core';
import { RequestHTTP } from './http-provider.service';
import {HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestService {
  constructor(private httpProvider: RequestHTTP) { }
  public getRequest(id: any, email: string, mobile: string, key: string) {
      const body = {
          email: email,
          mobile: mobile,
          api_key: key
      };
    return this.httpProvider.sendPostRequest(`/details/${id}`,
        body,
        new HttpHeaders,
        new HttpParams);
  }
  public createRequest(body: any, api_key: string) {
    return this.httpProvider.sendPostRequest(`/create?api_key=${api_key}`,
        body,
        new HttpHeaders,
        new HttpParams);
  }
  public searchServiceRequest(email: String, mobile: String, reference_number: String) {
    return this.httpProvider.sendPostRequest('/track',
        {
          'email': email,
          'mobile': mobile,
          'referenceNumber': reference_number
        },
        new HttpHeaders,
        new HttpParams);
  }
  //rodel
  public getIfReworkStatusExist(refNum: string){
    return this.httpProvider.sentGetRequestServiceList(`/list-status/${refNum}`, new HttpHeaders, new HttpParams);
  }
}
