import { Injectable } from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {ApiHTTP} from './http-provider.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpProvider: ApiHTTP) { }
  public getServiceTypes() {
    return this.httpProvider.sendGetRequest('/service-types',
        new HttpHeaders,
        new HttpParams);
  }
  public getCities() {
    return this.httpProvider.sendGetRequest('/city-list',
        new HttpHeaders,
        new HttpParams);
  }
  public getServiceBundles() {
    return this.httpProvider.sendGetRequest('/service-bundles',
        new HttpHeaders,
        new HttpParams);
  }
  getRecommended(city: string, servicetype: string, service_class: string) {
    const body = {
      city : city,
      serviceType: servicetype,
      contractor_class: service_class
    }
    return this.httpProvider.sendPostRequest(
        '/contractor/recommended',
        body,
        new HttpHeaders,
        new HttpParams);
  }
  postFeedback(body: any, api_key: string) {
    return this.httpProvider.sendPostRequest(
        `/contractor/feedback?api_key=${api_key}`,
        body,
        new HttpHeaders,
        new HttpParams);
  }
  getCBMList(body: any) {
    return this.httpProvider.sendPostRequest(
        '/contractor/list ',
        body,
        new HttpHeaders,
        new HttpParams);
  }
  getCBM(uuid: string) {
    const body = {
      id: uuid
    };
    return this.httpProvider.sendGetRequest(
        `/contractor/details?id=${uuid}`,
        new HttpHeaders,
        new HttpParams);
  }
  getCBMFeedbacks(uuid: string) {
    const body = {
      id: uuid
    };
    return this.httpProvider.sendPostRequest(
        '/contractor/details',
        body,
        new HttpHeaders,
        new HttpParams);
  }
  blacklistDomain(email: string) {
    return this.httpProvider.sendPostRequest('/domain-blacklist/add',
                                              {'domain': email},
                                              new HttpHeaders(),
                                              new HttpParams())
  }
  validateEmail(email: string) {
    return this.httpProvider.sendPostRequest('/domain-blacklist/validate',
                                              {'email': email},
                                              new HttpHeaders(),
                                              new HttpParams())
  }

}
