import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../shared/services/api.service';

@Component({
  selector: 'contractor-detail',
  templateUrl: './contractor-detail.component.html',
  styleUrls: ['./contractor-detail.component.scss']
})
export class ContractorDetailComponent implements OnInit {

  @ViewChild('cities', {static: false}) element_cities: ElementRef;

  cbmData: any = null;
  cbmID: string = '';

  feedbacks: any[] = [];
  offset: number = 0;
  pageCount: number = 0;
  selectedPage: number = 1;
  sortBy: string = 'Most Recent';

  notfound: boolean = false;
  expandCities: boolean = false;

  constructor(private route: ActivatedRoute,
              private cbmService: ApiService) { }

  ngOnInit() {
      this.cbmID = this.route.snapshot.params.uuid;
      this.fetchCbm();
  }

  fetchCbm() {
    this.cbmService.getCBM(this.cbmID).subscribe(
      data => {
          const res = data['body'];
          if ( res['contractor'].length === 0) {
            this.onNotFound();
            return;
          }
          this.cbmData = res;
          this.feedbacks = res['feedback'];
          this.pageCount = Math.ceil(this.feedbacks.length / 5);
          this.sortByDate();
          this.sortCities();
      },
      error => {
          this.onNotFound();
      }
    );
  }
  onNotFound() {
    this.cbmData = null;
    this.notfound = true;
  }
  getRatingValue(rating: string | number): number {
    if (typeof rating === 'string') {
      rating = parseFloat(rating);
    }
    if(!rating) return 0
    return parseFloat(rating.toFixed(1));
  }
  getRating(): number {
    return this.cbmData['contractor']['rating'];
  }
  floor(value: number) {
    return Math.floor(value);
  }
  min(...num: number[]) {
    return Math.min(...num);
 }
  computeRating(feedback: any) {
    const sum = feedback['customer_service'] + feedback['work_quality'] + feedback['response_time'];
    const average = sum / 3;
    const rating = parseFloat(average.toFixed(1));
    return rating;
  }
  onPageSelected(page: number) {
    this.offset = (page - 1) * 5;
    this.selectedPage = page;
  }
  sortByRating() {
      for (let c = 0 ; c < this.feedbacks.length ; c++) {
          let maxIndex = c;
          let maxRating = this.computeRating(this.feedbacks[c]);
          for (let d = c ; d < this.feedbacks.length; d++) {
              const rating = this.computeRating(this.feedbacks[d]);
              if ( rating > maxRating ) {
                  maxRating = rating;
                  maxIndex = d;
              }
          }
          const temp = this.feedbacks[c];
          this.feedbacks[c] = this.feedbacks[maxIndex];
          this.feedbacks[maxIndex] = temp;
      }
  }
  sortByDate() {
      for (let c = 0 ; c < this.feedbacks.length ; c++) {
          let maxIndex = c;
          let  maxDate = this.feedbacks[c]['date_created'];
          for (let d = c ; d < this.feedbacks.length; d++) {
              const date = this.feedbacks[d]['date_created'];
              if ( date > maxDate ) {
                  maxDate = date;
                  maxIndex = d;
              }
          }
          const temp = this.feedbacks[c];
          this.feedbacks[c] = this.feedbacks[maxIndex];
          this.feedbacks[maxIndex] = temp;
      }
  }
  onSort(sortBy: string) {
      if (sortBy === 'Rating') {
          this.sortByRating();
      } else {
          this.sortByDate();
      }
  }
  makeArray(size: number, sortBy: string) {
      this.sortBy = sortBy;
      if (sortBy === 'Rating') {
          this.sortByRating();
      } else {
          this.sortByDate();
      }
      return new Array(size);
  }
  sortCities() {
    const cities = this.cbmData['city'];
    for (let c = 0 ; c < cities.length ; c++) {
        let min_val = cities[c]['value'];
        let min_index = c ;
        for (let d = c ; d < cities.length ; d++) {
            if ( cities[d]['value'].localeCompare(min_val) <  0 ) {
               min_index = d;
               min_val = cities[d]['value'];
            }
        }
        const temp = cities[c]['value'];
        cities[c]['value'] = cities[min_index]['value'];
        cities[min_index]['value'] = temp;
    }
  }
  onCityResized() {
      console.log('aa');
  }
  expandCitiesList() {

  }

  isOverflow(element: ElementRef) {
    if (element === undefined) {
       return false;
    }
    return element.nativeElement.scrollHeight > this.element_cities.nativeElement.clientHeight ||
           element.nativeElement.scrollWidth > this.element_cities.nativeElement.clientWidth;
  }
  hideName(name: string) {
        let temp = '';
        name = name.toLowerCase();
        for (let c = 0 ; c < name.length ; c++) {
            if (c === 0 || c === name.length - 1 ) {
                temp += name.charAt(c);
                continue;
            }
            temp += '*';
        }
        return temp ;
    }
}
