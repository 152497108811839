import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacypolicyComponent implements OnInit {
  @Input() visible: boolean = true;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter();
  acceptPolicy: boolean = false;
  acceptTerms: boolean = false;
  constructor() { }

  ngOnInit() {
  }
  dismiss() {
    this.visible = false;
    this.onDismiss.emit();
  }
  accept() {
    this.visible = false;
  }

}
