import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
  @Input() title: string = 'Modal Title'
  @Input() message: string = ''
  @Input() confirmText: string = 'OK'
  @Input() visible: boolean = true;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }
  confirm() {
    this.onConfirm.emit();
    this.visible = false;
  }


}
