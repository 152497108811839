import {
  Component,
  ElementRef,
  HostListener,
  Input,
  NgModule,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  UntypedFormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
  AbstractControl,
} from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { ServiceRequestService } from '../../../shared/services/service-request.service';
import { OtpProviderService } from '../../../shared/services/otp-provider.service';
import { ReferenceDataService } from 'app/shared/services/referenceData.service';
import { Router } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import dataOTP from '../../../../assets/json/otp_config.json';
import screenData from '../../../../assets/json/screen-info.json';

interface OTPConfig {
  otp_timeout: number;
  otp_attempt_limit: number;
  otp_resend_limit: number;
  otp_resend_limit_duration: number;
}

//FOR SCREEN DETECTION
interface ScreenInfo {
  max_width_mobile_portrait: number;
  max_width_mobile_landscape: number;
  min_width_desktop: number;
}

@Component({
  selector: 'create-request',
  templateUrl: './create-request.component.html',
  styleUrls: ['./create-request.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }],
})
export class CreateComponent implements OnInit {
  @ViewChild('top', { static: false }) top: ElementRef;

  //Convert value of service for All to Mixed when submitting to API
  serviceForVal: any;

  //Check if mobile or desktop
  screenData: ScreenInfo[] = screenData as ScreenInfo[];
  isMobileView: boolean = false;
  isSMS: boolean = false; //for expired otp sms or email

  idleTimerIsEnabled: boolean = false;

  //OTP enhancement
  isSubmitButtonClicked: boolean = false;

  passedByExpired: boolean = false;
  isNewCall: boolean = false;
  mobileFromRetry: string = 'none';
  emailFromRetry: string = 'none';
  otpConfig: OTPConfig[] = dataOTP[0].data.config;

  detectBlock: number = 0;
  resendCtr: number = 0;
  showIntervalBlock: boolean = false;
  isResend: boolean = false;
  intervalTimerVal: number;
  otp_resend_interval: any;

  attemptCtrDsp: number = 0;
  resendCtrDsp: number = 0;

  //timer updating
  updateInterval: any;
  updateDuration: any;
  smsOrEmailValRetry: string = '';

  // Pages navs
  pageIndex: number;
  createPage: boolean = true;
  resultPage: boolean = false;
  loadingPage: boolean = false;
  // Modals
  modal_confirmExit: boolean = false;
  modal_verifyCancel: boolean = false;
  modal_privacyPolicy: boolean = true;
  modal_appliedLoadGuide: boolean = false;
  modal_message: boolean = false;
  modal_message_title: string = '';
  modal_message_body: string = '';
  // ---
  estimate_cost: number = 0;
  user_type: string;
  application_count: string;
  customerRelationships: string[];
  relationTypes: string[];
  cities_raw: any[] = [];
  cities_name: any[] = [];
  demoCities: any = {};
  // FORMS
  appliedAs = 'customer';
  form_serviceInformation: UntypedFormGroup;
  form_customerInformation: UntypedFormGroup;
  form_representativeInformation: UntypedFormGroup;
  form_serviceAddress: UntypedFormGroup;
  form_cbmSelection: UntypedFormGroup;
  currentFormComplete: boolean;

  selected_province: string = '';
  selected_city: string = '';
  selected_barangay: string = '';
  blacklist_email_customer: boolean = false;
  blacklist_email_rep: boolean = false;
  // ADDRESS
  provinces = [];
  provincesData = [];
  cities = [];
  citiesData = [];
  barangays = [];
  barangaysData = [];
  // DATA STORE
  serviceTypes: string[] = [];
  serviceTypesData: any[] = [];
  serviceBundles: string[];
  serviceBundlesData: string[];
  selectedCBMS: string[];
  maxDate;
  // results
  sr_reference: string = '';
  sr_email: string = '';
  // OTP
  otp_timeout: number;
  otp_interval: any;
  otp_mobile: string;
  otp_email: string;
  modal_otp_sms: boolean = false;
  modal_otp_email: boolean = false;
  modal_otp_retry: boolean = false;
  modal_otp_expiredtimer: boolean = false;

  otp_expired_default: boolean = false; //3 mins timer expired
  otp_expired_interval: boolean = false; //1 hr timer expired

  max_otp_attempts: number = 0;
  otp_attempts: number = 0; //5;//3;
  max_resend_attempts: number = 0;
  resend_attempts: number = 0;
  api_key: string = '';
  otp_uri: string = '';
  otp_channel: string = '';
  otpRequest: any = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private customerRequestService: ServiceRequestService,
    private otp: OtpProviderService,
    private router: Router,
    private referenceData: ReferenceDataService,
    private cdr3: ChangeDetectorRef
  ) {
    this.pageIndex = 0;
    this.initDefaults();
    this.initForms();
    this.loadServiceTypes();
    this.loadServiceBundles();
    this.loadLocations();
    this.fetchProvinceList();
    // this.showResultPage();
  }

  ngOnInit() {
    console.log('BAM - start of create sr');

    localStorage.setItem(
      'hourTimerInterval',
      this.otpConfig[0].otp_resend_limit_duration.toString()
    );
    localStorage.setItem(
      'defaultTimerInterval',
      this.otpConfig[0].otp_timeout.toString()
    );

    //   localStorage.setItem('startTime', new Date().toTimeString());

    this.max_otp_attempts = this.otpConfig[0].otp_attempt_limit;
    this.max_resend_attempts = this.otpConfig[0].otp_resend_limit;
    this.otp_attempts = 0; //this.otpConfig[0].otp_attempt_limit;
    this.resend_attempts = 0; //this.otpConfig[0].otp_resend_limit;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;
  }
  initForms() {
    this.form_serviceInformation = this.formBuilder.group({
      service_type: ['Service Application', [Validators.required]],
      service_bundle: ['', [Validators.required]],
      application_count: ['Single', [Validators.required]],
      service_for: ['Residential', [Validators.required]],
      applied_load: ['', [Validators.pattern('^\\d+\\.{0,1}\\d*$')]],
    });
    this.form_customerInformation = this.formBuilder.group({
      customer_firstname: [
        '',
        [
          Validators.pattern('^[A-Za-z\\-\\s]{2,50}$'),
          this.isWhitespaceValidator,
        ],
      ],
      customer_middlename: [
        '',
        [Validators.pattern('^[A-Za-z\\-\\.\\s]{1,50}$')],
      ],
      customer_lastname: [
        '',
        [
          Validators.pattern('^[A-Za-z\\-\\s]{2,50}$'),
          this.isWhitespaceValidator,
        ],
      ],
      customer_suffix: ['', [Validators.pattern('^[A-Za-z\\.]{1,10}$')]],
      customer_birthDate: ['', [Validators.required]],
      customer_mobileNumber: ['', [Validators.pattern('^\\+639\\d{9}$')]],
      customer_primaryPhone: ['', [Validators.pattern('^\\+63\\d{8,9}$')]],
      customer_emailAddress: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(255),
          Validators.pattern(
            '^[A-Za-z0-9\\.\\-\\_]+@[\\w\\-\\.]+\\.[A-Za-z]{2,3}$'
          ),
        ],
      ],
      customer_notificationChannel: ['Both', [Validators.required]],
      customer_business_name: [
        '',
        [
          Validators.pattern('[a-zA-Z0-9.\\,()\\-/\'&:;*"\\s]+'),
          this.isWhitespaceValidator,
        ],
      ],
      customer_tin: ['', [Validators.pattern('[0-9]{12}')]],
    });
    this.form_representativeInformation = this.formBuilder.group({
      rep_firstname: [
        '',
        [
          Validators.required,
          this.isWhitespaceValidator,
          Validators.pattern('^[A-Za-z\\-\\s]{2,50}$'),
        ],
      ],
      rep_lastname: [
        '',
        [
          Validators.required,
          this.isWhitespaceValidator,
          Validators.pattern('^[A-Za-z\\-\\s]{2,50}$'),
        ],
      ],
      rep_notificationChannel: ['Both', [Validators.required]],
      rep_birthdate: ['', []],
      rep_mobile: ['', [Validators.pattern('^\\+639\\d{9}$')]],
      rep_email: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(255),
          Validators.pattern(
            '^[A-Za-z0-9\\.\\-\\_]+@[\\w\\-\\.]+\\.[A-Za-z]{2,3}$'
          ),
        ],
      ],
      rep_relation: ['', [Validators.required]],
      rep_affiliation_type: ['', []],
      rep_affiliation: [
        '',
        [
          Validators.pattern('[a-zA-Z0-9.\\,()\\-\\/\'&:;*"\\s]+'),
          this.isWhitespaceValidator,
        ],
      ],
    });
    this.form_serviceAddress = this.formBuilder.group({
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      barangay: ['', [Validators.required]],
      address: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9\s!@#$%^&*()-_+={}[]|\/:;"<>,.?'~`*/),
          this.isWhitespaceValidator,
        ],
      ],
      landmark: [
        '',
        [Validators.pattern(/^[a-zA-Z0-9\s!@#$%^&*()-_+={}[]|\/:;"<>,.?'~`'*/)],
      ],
    });
    this.form_cbmSelection = this.formBuilder.group({
      cbm1: ['', [Validators.required]],
      cbm2: ['', [Validators.required]],
    });
    // FORM LISTENERS
    this.form_serviceInformation.controls[
      'application_count'
    ].valueChanges.subscribe(data => {
      if (
        data === 'Single' &&
        this.form_serviceInformation.controls['service_for'].value === 'All'
      ) {
        this.form_serviceInformation.controls['service_for'].setValue(
          'Residential'
        );
      }
    });

    this.form_serviceInformation.valueChanges.subscribe(data => {
      this.validateCurrentForm();
      this.estimate_cost = this.getEstimate();
    });
    this.form_customerInformation.valueChanges.subscribe(data =>
      this.validateCurrentForm()
    );
    this.form_representativeInformation.valueChanges.subscribe(data =>
      this.validateCurrentForm()
    );
    this.form_serviceAddress.valueChanges.subscribe(data =>
      this.validateCurrentForm()
    );
    this.form_cbmSelection.valueChanges.subscribe(data => {
      this.validateCurrentForm();
    });
    this.form_serviceInformation.controls['service_for'].valueChanges.subscribe(
      data => {
        this.clearCbmSelection();
      }
    );
    this.form_serviceInformation.controls[
      'service_type'
    ].valueChanges.subscribe(data => {
      this.clearCbmSelection();
    });
    this.form_representativeInformation.controls[
      'rep_affiliation_type'
    ].valueChanges.subscribe(data => {
      this.form_representativeInformation.controls['rep_affiliation'].setValue(
        ''
      );
    });
  }
  clearRepForm() {
    // TO RE INIT FORM AFTER RESET
    Object.keys(this.form_representativeInformation.controls).forEach(key => {
      this.form_representativeInformation.controls[key].setValue('');
    });
  }
  initDefaults() {
    this.user_type = 'Customer';
    this.application_count = 'Single';
    this.currentFormComplete = false;
    this.serviceBundles = [
      'Full Bundle',
      'Technical Bundle',
      'Processing Bundle',
      'Documentary Bundle',
    ];
    this.customerRelationships = [
      'Immediate Family',
      'Relative',
      'Friend',
      'Other official representative',
    ];
    this.relationTypes = [
      'Attorney / Corporate secretary',
      'Agent',
      'Frontliner / Staff',
      'Property developer',
      'Other',
    ];
    // dcac44ea-614f-4507-b680-6b5c20aa87d8 sqt
    // a23b0e83-9c55-42ef-9368-9ea3e1750568 dev
    // this.serviceTypesData.push({
    //   serviceID: 'dcac44ea-614f-4507-b680-6b5c20aa87d8',
    //   serviceType: 'Service Application'
    // });
    this.serviceTypes.push('Service Application');
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr3.detectChanges();
  }

  resetDefaultConfigs() {
    this.max_otp_attempts = this.otpConfig[0].otp_attempt_limit;
    this.max_resend_attempts = this.otpConfig[0].otp_resend_limit;
    this.otp_attempts = 0; //this.otpConfig[0].otp_attempt_limit;
    this.resend_attempts = 0; //this.otpConfig[0].otp_resend_limit;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;
  }

  loadServiceTypes() {
    this.apiService.getServiceTypes().subscribe(
      data => {
        const res = data['body'];
        this.serviceTypes = [];
        this.serviceTypesData = res;
        for (let c = 0; c < res.length; c++) {
          this.serviceTypes.push(res[c]['serviceType']);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  loadServiceBundles() {
    this.apiService.getServiceBundles().subscribe(
      data => {
        const res = data['body'];
        console.log('DATA SBUNDLES = ', res);
        this.serviceBundlesData = res;
        console.log('DATA SBUNDLES FIN = ', res);
      },
      error => {
        console.log(error);
      }
    );
  }
  // NAV ACTIONS
  prevPage() {
    if (this.pageIndex > 0) {
      this.pageIndex--;
      this.currentFormComplete = false;
      this.validateCurrentForm();
      this.scrollToTop();
    }
  }
  nextPage() {
    if (this.pageIndex === 1) {
      if (
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_emailAddress']
        ) ||
        this.isControlNotEmptyAndValid(
          this.form_representativeInformation.controls['rep_email']
        )
      ) {
        this.validateEmail();
        return;
      }
    }
    if (this.pageIndex < 2) {
      this.pageIndex++;
      //console.log("I CHANGED PAGE BUNDLE = " + this.getServiceBundleID());
      this.currentFormComplete = false;
      this.validateCurrentForm();
      this.scrollToTop();
      return;
    }
    if (this.pageIndex === 2) {
      if (this.showIntervalBlock == true && this.passedByExpired === false) {
        if (this.isSMS == true) {
          this.modal_otp_sms = true;
          this.modal_otp_email = false;
        } else {
          this.modal_otp_email = true;
          this.modal_otp_sms = false;
        }

        return;
      } else {
        this.startOTP();
      }

      // this.submitSR();
      //return;
    }
  }
  cancel() {
    this.passedByExpired = true;

    this.isNewCall = false;
    this.detectBlock = 0;
    this.resendCtr = 0;

    this.isSMS = false;

    clearInterval(this.otp_interval);
    clearInterval(this.otp_resend_interval);

    this.otp_attempts = 0;
    this.resend_attempts = 0;

    this.showIntervalBlock = false;
    this.closeOTPModals();

    this.router.navigate(['/customer']);
  }
  // PAGE 0
  onServiceTypeSelected(item: string) {
    this.form_serviceInformation.controls['service_type'].setValue(item);
  }
  onServiceBundleSelected(item: string) {
    this.form_serviceInformation.controls['service_bundle'].setValue(item);
    //console.log("BUNDLE SEL" + item);
  }
  getServiceTypeID() {
    const serviceType =
      this.form_serviceInformation.controls['service_type'].value;
    for (const data of this.serviceTypesData) {
      if (data['serviceType'] === serviceType) {
        return data['serviceID'];
      }
    }
  }
  getServiceBundleID(): string {
    let application_count =
      this.form_serviceInformation.controls['application_count'].value;
    let serviceFor = this.form_serviceInformation.controls['service_for'].value;
    const appliedLoad =
      this.form_serviceInformation.controls['applied_load'].value;
    const serviceBundle =
      this.form_serviceInformation.controls['service_bundle'].value;

    application_count =
      application_count === 'Multi-meter' ? 'Multimeter' : application_count;

    let load = Number(appliedLoad);
    load = isNaN(load) ? 0 : load;
    load = load < 1.5 ? 1.5 : load;

    console.log('APPLIED LOAD = ' + load);

    if (serviceBundle === '') {
      return '-';
    }

    let min = 100000;
    let min_id = '';

    let max = 0;
    let max_id = '';

    serviceFor = serviceFor === 'Residential' ? serviceFor : 'Non-Residential';

    if (application_count === 'Multimeter') {
      for (var data of this.serviceBundlesData) {
        if (
          data['bundleName'] === serviceBundle &&
          data['applicationCount'] === 'Multimeter'
        ) {
          return data['uuid'];
        }
      }
    }

    for (var data of this.serviceBundlesData) {
      if (
        data['bundleName'] === serviceBundle &&
        data['class'] === serviceFor
      ) {
        console.log('FLOAT min = ' + String(data['min']).replace('kW', ''));
        console.log('FLOAT max = ' + String(data['max']).replace('kW', ''));

        var min_val = parseFloat(String(data['min']).replace('kW', ''));
        var max_val = parseFloat(String(data['max']).replace('kW', ''));
        var uuid = data['uuid'];

        console.log('UUID = ' + uuid);

        if (min_val < min) {
          min = min_val;

        }
        if (max_val > max) {
          max = max_val;

        }

        if (load >= min_val && load <= max_val) {
          console.log('ENTERED FIRST CONDITION = ' + uuid);
          return uuid;
        }
      }
    }

    // IF NOTING FOUND
    if (load <= min) {
      console.log('ENTERED 2nd COND = ' + min_id);
      return min_id;
    }
    if (load >= max) {
      console.log('ENTERED 3rd COND = ' + max_id);
      return max_id;
    } else {
      return '';
    }
  }
  test() { }
  getEstimate(): number {
    const service_type =
      this.form_serviceInformation.controls['service_type'].value;
    const service_bundle =
      this.form_serviceInformation.controls['service_bundle'].value;
    let application_count =
      this.form_serviceInformation.controls['application_count'].value;
    let service_for =
      this.form_serviceInformation.controls['service_for'].value;
    let applied_load =
      this.form_serviceInformation.controls['applied_load'].value;
    applied_load =
      Math.round((Number(applied_load) + Number.EPSILON) * 10) / 10;

    application_count =
      application_count === 'Multi-meter' ? 'Multimeter' : application_count;

    if (service_bundle === '' || service_type === '') {
      return 0;
    }

    if (
      this.form_serviceInformation.controls['applied_load'].invalid ||
      applied_load === '' ||
      applied_load === 0
    ) {
      return 0;
    }
    if (application_count === 'Multimeter') {
      return 0;
    }

    service_for =
      service_for === 'Residential' ? service_for : 'Non-Residential';
    service_for = service_for.toLowerCase();

    for (const data of this.serviceBundlesData) {
      if (
        data['applicationCount'] === application_count &&
        data['bundleName'] === service_bundle &&
        data['class'].toLowerCase() === service_for
      ) {
        const min_load = parseFloat(data['min']);
        const max_load = parseFloat(data['max']);

        if (applied_load >= min_load && applied_load <= max_load) {
          return data['rate'];
        }
      }
    }
    return 0;
  }
  // page 1
  onAppliedAsChanged() {
    this.clearRepForm();
    this.form_representativeInformation.controls[
      'rep_notificationChannel'
    ].setValue('Both');
    this.validateCurrentForm();
  }
  onRelationSelectionSelected(item: string) {
    this.form_representativeInformation.controls['rep_relation'].setValue(item);
  }
  onProvinceSelected(val: string) {
    this.form_serviceAddress.controls['province'].setValue(val);
    this.form_serviceAddress.controls['city'].setValue('');
    this.form_serviceAddress.controls['barangay'].setValue('');
    this.selected_province = val;
    this.cities = [];
    this.barangays = [];
    this.selected_city = '';
    this.selected_barangay = '';
    const uuid = this.getProvinceID(val);
    if (uuid === '--') {
      return;
    }
    this.fetchCitiesList(uuid);
  }
  onMunicipalitySelected(val: string) {
    this.form_serviceAddress.controls['city'].setValue(val);
    this.form_serviceAddress.controls['barangay'].setValue('');
    this.selected_city = val;
    this.barangays = [];
    this.selected_barangay = '';
    this.clearCbmSelection();
    const uuid = this.getCityID(val);
    if (uuid === '--') {
      return;
    }
    this.fetchBarangayList(uuid);
  }
  onBarangaySelected(val: string) {
    this.form_serviceAddress.controls['barangay'].setValue(val);
    this.selected_barangay = val;
  }
  // --
  validateCurrentForm() {
    switch (this.pageIndex) {
      case 0:
        this.currentFormComplete = this.validateServiceInformation();
        break;
      case 1:
        this.currentFormComplete = this.validateCustomerInformation();
        break;
      case 2:
        this.currentFormComplete = this.validateCBM();
        break;
    }
  }
  validateServiceInformation(): boolean {
    if (
      this.form_serviceInformation.controls['application_count'].value ===
      'Single'
    ) {
      return (
        this.form_serviceInformation.valid &&
        this.form_serviceInformation.controls['applied_load'].valid &&
        Number(this.form_serviceInformation.controls['applied_load'].value) >= 1
      );
    }
    if (
      this.form_serviceInformation.controls['application_count'].value ===
      'Multi-meter'
    ) {
      return this.form_serviceInformation.valid;
    }
    return false;
  }
  validateCustomerInformation(): boolean {
    if (this.appliedAs === 'customer') {
      return this.validateCustomer() && this.form_serviceAddress.valid;
    }
    if (this.appliedAs === 'customer representative') {
      return (
        this.form_serviceAddress.valid &&
        this.validateCustomer() &&
        this.validateRep()
      );
    }
    // NOT GONNA HAPPEN
    return false;
  }
  validateCBM(): boolean {
    return this.form_cbmSelection.valid;
  }
  validateCustomer() {
    if (
      this.form_serviceInformation.controls['service_for'].value ===
      'Residential'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_firstname']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_lastname']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_birthDate']
        ) &&
        this.validateCustomerNotifChannel()
      );
    }
    if (
      this.form_serviceInformation.controls['service_for'].value === 'Business'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_business_name']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_tin']
        ) &&
        this.validateCustomerNotifChannel()
      );
    }
    if (
      this.form_serviceInformation.controls['service_for'].value === 'All'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_firstname']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_lastname']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_birthDate']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_business_name']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_tin']
        ) &&
        this.validateCustomerNotifChannel()
      );
    }
    // NOT GONNA HAPPEN
    return false;
  }
  validateCustomerNotifChannel() {
    if (
      this.form_customerInformation.controls['customer_notificationChannel']
        .value === 'Both'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_mobileNumber']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_emailAddress']
        ) &&
        this.form_customerInformation.controls['customer_primaryPhone'].valid
      );
    }
    if (
      this.form_customerInformation.controls['customer_notificationChannel']
        .value === 'SMS'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_mobileNumber']
        ) &&
        this.form_customerInformation.controls['customer_emailAddress'].valid &&
        this.form_customerInformation.controls['customer_primaryPhone'].valid
      );
    }
    if (
      this.form_customerInformation.controls['customer_notificationChannel']
        .value === 'Email'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_customerInformation.controls['customer_emailAddress']
        ) &&
        this.form_customerInformation.controls['customer_mobileNumber'].valid &&
        this.form_customerInformation.controls['customer_primaryPhone'].valid
      );
    }
    // NOT GONNA HAPPEN
    return false;
  }
  validateRep() {
    return (
      this.form_representativeInformation.valid &&
      (this.form_representativeInformation.controls['rep_relation'].value !==
        'Other official representative' ||
        (this.form_representativeInformation.controls['rep_affiliation_type']
          .value !== 'Other' &&
          this.form_representativeInformation.controls['rep_affiliation_type']
            .value !== '') ||
        this.isControlNotEmptyAndValid(
          this.form_representativeInformation.controls['rep_affiliation']
        )) &&
      this.validateRepNotifChannel()
    );
  }
  validateRepNotifChannel() {
    if (
      this.form_representativeInformation.controls['rep_notificationChannel']
        .value === 'Both'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_representativeInformation.controls['rep_email']
        ) &&
        this.isControlNotEmptyAndValid(
          this.form_representativeInformation.controls['rep_mobile']
        )
      );
    }
    if (
      this.form_representativeInformation.controls['rep_notificationChannel']
        .value === 'SMS'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_representativeInformation.controls['rep_mobile']
        ) && this.form_representativeInformation.controls['rep_email'].valid
      );
    }
    if (
      this.form_representativeInformation.controls['rep_notificationChannel']
        .value === 'Email'
    ) {
      return (
        this.isControlNotEmptyAndValid(
          this.form_representativeInformation.controls['rep_email']
        ) && this.form_representativeInformation.controls['rep_mobile'].valid
      );
    }
    // NOT GONNA HAPPEN
    return false;
  }
  validateBirth(c: AbstractControl): { [key: string]: boolean } | null {
    //  VALIDATION INCLUDES PROVISION FOR MANUALLY INPUTTED DATE
    //  AS SAFARI & IE DATE INPUT FIELDS APPEARS AS ORDINARY TEXT BOX
    //  RETURN {'birthdate': true } if has errors ;  null if none
    let birthString = c.value;
    const oldFormat = new RegExp('^[0-9]{1,2}\\/[0-9]{1,2}\\/[0-9]{4}$');
    const correctFormat = new RegExp('^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$');
    if (!(oldFormat.test(birthString) || correctFormat.test(birthString))) {
      return { birthdate: true };
    }
    if (oldFormat.test(birthString)) {
      const dd = Number(birthString.substr(0, birthString.indexOf('/')));
      birthString = birthString.substr(birthString.indexOf('/') + 1);
      const mm = Number(birthString.substr(0, birthString.indexOf('/')));
      const yy = birthString.substr(birthString.indexOf('/') + 1);
      birthString = yy;
      birthString += mm < 10 ? '-0' + mm : '-' + mm;
      birthString += dd < 10 ? '-0' + dd : '-' + dd;
    }
    const birthdate = new Date(birthString);
    if (birthdate.toString() === 'Invalid Date') {
      return { birthdate: true };
    }
    const maxDate = new Date();
    // AGE VALIDATION
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    try {
      if (birthdate.getTime() > maxDate.getTime()) {
        return { birthdate: true };
      }
      return null;
    } catch (e) {
      return { birthdate: true };
    }
  }
  validateEmail() {
    const customer_email =
      this.form_customerInformation.controls['customer_emailAddress'].value;
    const rep_email =
      this.form_representativeInformation.controls['rep_email'].value;

    const promises = [];
    if (
      this.isControlNotEmptyAndValid(
        this.form_customerInformation.controls['customer_emailAddress']
      )
    ) {
      promises.push(this.isEmailBlacklisted(customer_email, 'customer'));
    }
    if (
      this.isControlNotEmptyAndValid(
        this.form_representativeInformation.controls['rep_email']
      )
    ) {
      promises.push(this.isEmailBlacklisted(rep_email, 'rep'));
    }

    this.showLoadingPage();
    Promise.all(promises)
      .then(values => {
        for (const bool of values) {
          if (bool) {
            this.showMessageModal(
              'Invalid Email Address',
              'Please enter a non-disposable email address'
            );
            this.showCreatePage();
            return;
          }
        }
        this.pageIndex++;
        //this.currentFormComplete = false;
        this.validateCurrentForm();
        this.showCreatePage();
        this.scrollToTop();
      })
      .catch(reason => {
        this.showCreatePage();
        console.log(reason);
      });
  }
  isEmailBlacklisted(email: string, field: string) {
    return new Promise((resolve, reject) => {
      this.apiService.validateEmail(email).subscribe(
        data => {
          const res = data['body'];
          this.markEmailAsInvalid(res, field);
          resolve(res);
        },
        error => {
          console.log(error);
          reject(error);
        }
      );
    });
  }
  markEmailAsInvalid(invalid: boolean, field: string) {
    if (field === 'customer') {
      this.blacklist_email_customer = invalid;
      return;
    }
    this.blacklist_email_rep = invalid;
  }
  fixDate(date: string) {
    const oldFormat = new RegExp('^[0-9]{1,2}\\/[0-9]{1,2}\\/[0-9]{4}$');
    const correctFormat = new RegExp('^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$');
    if (correctFormat.test(date)) {
      return date;
    }
    if (!oldFormat.test(date)) {
      return '1970-01-01';
    }
    let birthString = date;
    const dd = Number(birthString.substr(0, birthString.indexOf('/')));
    birthString = birthString.substr(birthString.indexOf('/') + 1);
    const mm = Number(birthString.substr(0, birthString.indexOf('/')));
    const yy = birthString.substr(birthString.indexOf('/') + 1);
    birthString = yy;
    birthString += mm < 10 ? '-0' + mm : '-' + mm;
    birthString += dd < 10 ? '-0' + dd : '-' + dd;
    return birthString;
  }
  clearForms() {
    this.form_customerInformation.reset();
    this.form_representativeInformation.reset();
    this.form_serviceAddress.reset();
    this.form_cbmSelection.reset();
    this.initForms();
  }
  // -------
  onCbmSelected(uuids: string[]) {
    this.selectedCBMS = uuids;
    this.form_cbmSelection.controls['cbm1'].setValue(uuids[0]);
    this.form_cbmSelection.controls['cbm2'].setValue(uuids[1]);
  }
  loadLocations() {
    this.apiService.getCities().subscribe(
      data => {
        if (data['statusText'] !== 'OK') {
          return;
        }
        const res = data['body'];
        this.cities_raw.push(...res);
        for (const city of this.cities_raw) {
          this.cities_name.push(city.value);
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  submitSR() {
    const body = {
      serviceInformation: {
        serviceType: {
          id: this.getServiceTypeID(),
          name: this.form_serviceInformation.controls['service_type'].value,
        },
        serviceBundle: {
          id: this.getServiceBundleID(),
          name: this.form_serviceInformation.controls['service_bundle'].value,
        },
        applicationCount:
          this.form_serviceInformation.controls['application_count'].value,
        serviceFor: this.serviceForVal, 
        appliedLoad:
          this.form_serviceInformation.controls['applied_load'].value,
        province: {
          id: this.getProvinceID(
            this.form_serviceAddress.controls['province'].value
          ),
          name: this.form_serviceAddress.controls['province'].value,
        },
        city: {
          id: this.getCityID(this.selected_city),
          name: this.selected_city,
        },
        barangay: {
          id: this.getBarangayID(
            this.form_serviceAddress.controls['barangay'].value
          ),
          name: this.form_serviceAddress.controls['barangay'].value,
        },
        address: this.form_serviceAddress.controls['address'].value.trim(),
        landmark: this.form_serviceAddress.controls['landmark'].value,
        appliedAs: this.appliedAs,
        estimatedCost: this.estimate_cost + '',
      },
      customerInformation: {
        businessName:
          this.form_customerInformation.controls[
            'customer_business_name'
          ].value.trim(),
        taxID: this.form_customerInformation.controls['customer_tin'].value,
        firstName:
          this.form_customerInformation.controls[
            'customer_firstname'
          ].value.trim(),
        middleName:
          this.form_customerInformation.controls[
            'customer_middlename'
          ].value.trim(),
        lastName:
          this.form_customerInformation.controls[
            'customer_lastname'
          ].value.trim(),
        suffix: this.form_customerInformation.controls['customer_suffix'].value,
        birthDate: this.formatDate(
          this.form_customerInformation.controls['customer_birthDate'].value
        ),
        mobileNumber:
          this.form_customerInformation.controls['customer_mobileNumber'].value,
        phoneNumber:
          this.form_customerInformation.controls['customer_primaryPhone'].value,
        emailAddress:
          this.form_customerInformation.controls['customer_emailAddress'].value,
        notificationChannel:
          this.form_customerInformation.controls['customer_notificationChannel']
            .value,
      },
      representativeInformation: {
        relationToCustomer:
          this.form_representativeInformation.controls['rep_relation'].value,
        nonCBMContractorName:
          this.form_representativeInformation.controls['rep_affiliation_type']
            .value,
        otherAffiliation:
          this.form_representativeInformation.controls[
            'rep_affiliation'
          ].value.trim(),
        firstName:
          this.form_representativeInformation.controls[
            'rep_firstname'
          ].value.trim(),
        lastName:
          this.form_representativeInformation.controls[
            'rep_lastname'
          ].value.trim(),
        birthDate:
          this.form_representativeInformation.controls['rep_birthdate'].value,
        mobileNumber:
          this.form_representativeInformation.controls['rep_mobile'].value,
        emailAddress:
          this.form_representativeInformation.controls['rep_email'].value,
        notificationChannel:
          this.form_representativeInformation.controls[
            'rep_notificationChannel'
          ].value,
      },
      cbmSelection: {
        firstChoiceId: this.form_cbmSelection.controls['cbm1'].value,
        secondChoiceId: this.form_cbmSelection.controls['cbm2'].value,
      },
    };
    this.showLoadingPage();
    this.customerRequestService.createRequest(body, this.api_key).subscribe(
      data => {
        if (data['status'] !== 200) {
          this.onCreateError(data);
          return;
        }
        const res = data['body'];
        this.onCreateSuccess(
          res['referenceNo'],
          body.customerInformation.emailAddress
        );
      },
      error => {
        this.showMessageModal(
          'Creation Error',
          'Error creating service request. \nPlease retry later.'
        );
        this.onCreateError(error);
      }
    );
  }
  fetchProvinceList() {
    this.referenceData.getProvinces().subscribe({
      next: value => {
        const res = value['body'];
        this.sortLocations(res);
        this.provincesData = res;
        this.provinces.push(...res.map(el => el.value));
      },
      error: err => {
        console.log(err);
      },
    });
  }
  fetchCitiesList(provinceId: string) {
    this.cities = [];
    this.referenceData.getCities(provinceId).subscribe({
      next: value => {
        const res = value['body'];
        this.citiesData = res;
        this.sortLocations(res);
        this.cities.push(...res.map(el => el.value));
      },
      error: err => {
        console.log(err);
      },
    });
  }
  fetchBarangayList(cityId: string) {
    this.barangays = [];
    //this.referenceData.getBarangay(cityId).subscribe(params => {
    //  const res = params['body'];
    //  this.barangaysData = res;
    //  this.sortLocations(res);
    //  res.forEach((element: any) => {
    //    this.barangays.push(element.value);
    //  });
    //});
    this.referenceData.getBarangay(cityId).subscribe({
      next: value => {
        const res = value['body'];
        this.barangaysData = res;
        this.sortLocations(res);
        this.barangays.push(...res.map(el => el.value));
      },
      error: err => {
        console.log(err);
      },
    });
  }
  getCityID(name: string): string {
    const city = this.citiesData.find(el => el.value === name);
    return city !== undefined ? city.uuid : '--';
  }
  getProvinceID(name: string): string {
    const province = this.provincesData.find(el => el.value === name);
    return province !== undefined ? province.uuid : '--';
  }
  getBarangayID(name: string): string {
    const barangay = this.barangaysData.find(el => el.value === name);
    return barangay !== undefined ? barangay.uuid : '--';
  }
  onCreateError(error: any) {
    this.showCreatePage();
  }
  onCreateSuccess(ref: string, email: string) {
    this.sr_reference = ref;
    this.sr_email = email;
    this.showResultPage();
  }
  showResultPage() {
    this.createPage = false;
    this.resultPage = true;
    this.loadingPage = false;
  }
  showLoadingPage() {
    if (this.modal_otp_sms == false && this.modal_otp_email == false) {
      console.log('ENTERED 1 loading create');
      this.createPage = false;
      this.resultPage = false;
      this.loadingPage = true;
    } else if (this.modal_otp_sms == true || this.modal_otp_email == true) {
      console.log('ENTERED 2 loading create');
      this.loadingPage = false;
    }
  }
  showCreatePage() {
    this.createPage = true;
    this.resultPage = false;
    this.loadingPage = false;
  }
  showMessageModal(title: string, message: string) {
    this.modal_message = true;
    this.modal_message_title = title;
    this.modal_message_body = message;
  }
  dismissMessageModal() {
    this.modal_message = false;
  }
  trackSR() {
    // window.open(`/customer/track?ref=${this.sr_reference}&email=${this.sr_email}&mobile`, '_self');
    window.open('/track', '_self');
  }
  isControlNotEmptyAndValid(control: AbstractControl) {
    return control.value !== null && control.value !== '' && control.valid;
  }
  toNumber(str: number) {
    return Number(str);
  }
  getMaxDate() {
    let str = '';
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    str += date.getFullYear() - 18;
    str += '-';
    str += month < 10 ? '0' + month : month;
    str += '-';
    str += day < 10 ? '0' + day : day;
    return str;
  }
  formatDate(date): string {
    if (date == null || date === '') {
      return '';
    }
    const _date = new Date(date);
    const day = _date.getDate();
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    let str = '' + year;
    str += '-';
    str += month < 10 ? '0' + month : month;
    str += '-';
    str += day < 10 ? '0' + day : day;
    return str;
  }
  getNotificationChannel(): string {
    if (this.appliedAs === 'customer') {
      return this.form_customerInformation.controls[
        'customer_notificationChannel'
      ].value;
    } else {
      return this.form_representativeInformation.controls[
        'rep_notificationChannel'
      ].value;
    }
  }
  getNotificationURI(): string {
    const channel = this.getNotificationChannel();
    if (this.appliedAs === 'customer') {
      if (channel.toUpperCase() === 'SMS' || channel.toUpperCase() === 'BOTH') {
        return this.form_customerInformation.controls['customer_mobileNumber']
          .value;
      } else {
        return this.form_customerInformation.controls['customer_emailAddress']
          .value;
      }
    } else {
      if (channel.toUpperCase() === 'SMS' || channel.toUpperCase() === 'BOTH') {
        return this.form_representativeInformation.controls['rep_mobile'].value;
      } else {
        return this.form_representativeInformation.controls['rep_email'].value;
      }
    }
  }
  getCustomerName() {
    return this.form_serviceInformation.controls['service_for'].value ===
      'Business'
      ? this.form_customerInformation.controls['customer_business_name'].value
      : this.form_customerInformation.controls['customer_firstname'].value +
      ' ' +
      this.form_customerInformation.controls['customer_lastname'].value;
  }
  scrollToTop() {
    this.top.nativeElement.scrollIntoView();
  }
  isWhitespaceValidator(control: FormControl) {
    const value = control.value || '';
    // FOR OPTIONAL CONTROLS
    if (value === '') {
      return null;
    }
    const isValid = value.trim().length !== 0;
    return isValid ? null : { whitespace: true };
  }
  // OTP
  startOTP() {
    if (this.isSubmitButtonClicked) {
      return;
    }

    console.log('ENTERED START OTP');
    this.otp_uri = this.getNotificationURI();
    this.otp_channel = this.getNotificationChannel().toUpperCase();
    this.isSubmitButtonClicked = true;
    this.sendOTP();
  }

  resendOTP() {
    this.resendCtr += 1;
    console.log('RESEND CTR = ' + this.resendCtr);

    if ( this.otpRequest ) {
      this.otpRequest.unsubscribe();
    }

    if (this.resendCtr >= this.otpConfig[0].otp_resend_limit) {
      this.detectBlock += 1;
      if (this.detectBlock == 1) {
        console.log('ENTERED RESEND LIMIT > 5');
        this.isResend = false;
        clearInterval(this.otp_interval);
        //this.otp_timeout = this.otpConfig[0].otp_timeout;
        this.intervalTimerVal = Number(
          localStorage.getItem('hourTimerInterval')
        ); //this.otpConfig[0].otp_resend_limit_duration;
        this.showIntervalBlock = true;

        this.otp_interval = setInterval(() => {
          if (this.intervalTimerVal > 0) {
            this.intervalTimerVal--;
            return;
          }

          if (this.showIntervalBlock == true && this.intervalTimerVal != 0) {
            if (
              this.getNotificationChannel().toUpperCase() === 'SMS' ||
              this.getNotificationChannel().toUpperCase() === 'BOTH'
            ) {
              this.modal_otp_sms = true;
            } else {
              this.modal_otp_email = true;
            }
          } else if (
            this.showIntervalBlock == true &&
            this.intervalTimerVal == 0
          ) {
            this.onOTPRemoveBlockInterval();
          }
        }, 1000);
      }
    } else if (this.resendCtr < this.otpConfig[0].otp_resend_limit) {
      //this.otpConfig[0].otp_resend_limit)
      console.log('RESEND # ', this.resendCtr);
      this.isResend = true;
      if (this.resendCtrDsp > 0) {
        this.resendCtrDsp--;
      }
      this.otp_timeout = this.otpConfig[0].otp_timeout;
      this.showIntervalBlock = false;

      switch (this.otp_channel) {
        case 'SMS':
          this.showLoadingPage();
          this.sendOTP_SMS(this.otp_uri);
          break;
        case 'BOTH':
          this.showLoadingPage();
          this.sendOTP_SMS(this.otp_uri);
          break;
        case 'EMAIL':
          this.showLoadingPage();
          this.sendOTP_Email(this.otp_uri);
          break;
        default: // DO NOTHING
      }
    }
  }

  /*resendOTP() {

        switch (this.otp_channel) {
          case 'SMS':
            this.sendOTP_SMS(this.otp_uri);
            break;
          case 'BOTH':
            this.sendOTP_SMS(this.otp_uri);
            break;
          case 'EMAIL':
            this.sendOTP_Email(this.otp_uri);
            break;
          default: // DO NOTHING
        }
  }*/

  enableIdleAction(
    isEnabled: string //overrides current timers with the idle timer in otpsms
  ) {
    if (this.showIntervalBlock == true) {
      this.onOTPRemoveBlockInterval();
    }
    /*else if(this.showIntervalBlock == false && this.isNewCall == false)
    {
      console.log("IDLE")
      this.expiredOTPIdle();
    }*/
  }

  sendOTP() {
    this.otp_timeout = this.otpConfig[0].otp_timeout; //120; // seconds
    //this.otp_attempts = this.otpConfig[0].otp_attempt_limit; //3; // reset attempts
    if (this.passedByExpired == false) {

      if (this.otp_channel === 'EMAIL') {
        this.isSMS = false;
        this.sendOTP_Email(this.otp_uri);
      } else {
        this.isSMS = true;
        this.sendOTP_SMS(this.otp_uri);
      }
    } else {
      console.log('SEND OTP PASS EXPIRED');
      clearInterval(this.otp_interval);
      this.otp_timeout = this.otpConfig[0].otp_timeout;
    }
  }
  sendOTP_SMS(mobile: string) {
    console.log('ENTERED SMS CHANNEL');
    const name = this.getCustomerName();
    this.otp_mobile = mobile;
    //this.isSMS = true;
    this.smsOrEmailValRetry = mobile;
    this.showLoadingPage();
    this.otpRequest = this.otp.createSMSOTP_SR(mobile, name).subscribe({
      next: value => {
        if (value.toString().length != 0) {
          this.loadingPage = false;
          this.modal_otp_sms = true;
          this.showCreatePage();
          clearInterval(this.otp_interval);
          this.otp_interval = setInterval(() => {
            if (this.otp_timeout > 0) {
              this.otp_timeout--;
              return;
            }
            this.onOTPTimeout();
          }, 1000);
        }
      },
      error: err => {
        console.log(err);
        this.isSubmitButtonClicked = false;
        this.loadingPage = false;
        this.showCreatePage();
      },
    });
  }
  sendOTP_Email(email: string) {
    console.log('ENTERED EMAIL CHANNEL');
    const name = this.getCustomerName();
    this.otp_email = email;
    //this.isSMS = false;
    this.smsOrEmailValRetry = email;
    this.showLoadingPage();
    this.otpRequest = this.otp.createEmailOTP_SR(email, name).subscribe({
      next: value => {
        if (value.toString().length != 0) {
          this.loadingPage = false;
          this.modal_otp_email = true;
          this.showCreatePage();
          clearInterval(this.otp_interval);
          this.otp_interval = setInterval(() => {
            if (this.otp_timeout > 0) {
              this.otp_timeout--;
              return;
            }
            this.onOTPTimeout();
          }, 1000);
        }
      },
      error: err => {
        console.log(err);
        this.isSubmitButtonClicked = false;
        this.loadingPage = false;
        this.showCreatePage();
      },
    });
  }
  onOTPAttemptFail() {
    // this.attemptCtr+= 1;
    /*if (this.otp_attempts > 1 ) {
      this.otp_attempts--;
      return;
    }
    this.onOTPfail();*/
    this.isNewCall = false;
    this.otp_attempts++;
    console.log('FAILED = ', this.otp_attempts);
    if (this.otp_attempts < this.max_otp_attempts + 1) {
      console.log('FAILED CREATE = ', this.otp_attempts);
      if (this.attemptCtrDsp > 0) {
        this.attemptCtrDsp--;
        if (this.attemptCtrDsp === 0) {
          this.onOTPfail();
        }
      }
      if (this.otp_attempts >= this.max_otp_attempts) {
        this.onOTPfail();
      }
    } else {
      if (this.otp_attempts === this.max_otp_attempts + 1) {
        console.log('RESET');
        this.otp_attempts = 0;
        this.attemptCtrDsp -= 1;
      }
    }
  }

  cancelOtp() {
    this.isNewCall = false;
    this.loadingPage = false;
    this.showCreatePage();
    this.isSubmitButtonClicked = false;
    this.passedByExpired = false;
    if (this.showIntervalBlock == false) {
      console.log('INTERVAL BLOCK IS NOT SHOWN');
      clearInterval(this.otp_interval);
      this.resetDefaultConfigs();
      this.closeOTPModals();

      //this.otpClearAll();
    } else if (this.showIntervalBlock == true) {
      console.log('INTERVAL BLOCK IS SHOWN');
      if (this.isSMS == true) {
        this.modal_otp_sms = false;
      } else {
        this.modal_otp_email = false;
      }
    }
  }

  otpClearAll() {
    //RESET COUNTERS:
    this.max_otp_attempts = this.otpConfig[0].otp_attempt_limit;
    this.max_resend_attempts = this.otpConfig[0].otp_resend_limit;
    this.otp_attempts = 0; //this.otpConfig[0].otp_attempt_limit;
    this.resend_attempts = 0; //this.otpConfig[0].otp_resend_limit;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;

    //CLEAR MODAL-RELATED DATA:

    this.detectBlock = 0;
    this.isResend = false;
    this.resendCtr = 0;
    //clearInterval(this.intervalTimerVal);

    this.intervalTimerVal = this.otpConfig[0].otp_resend_limit_duration;
    this.showIntervalBlock = false;

    this.modal_otp_expiredtimer = false;

    clearInterval(this.otp_interval);
    clearInterval(this.otp_resend_interval);

    //CLOSE ALL MODALS
    this.closeOTPModals();
  }

  closeOTPModals() {
    this.modal_otp_sms = false;
    this.isSMS = false;
    this.modal_otp_email = false;
    this.modal_otp_retry = false;
    this.modal_otp_expiredtimer = false;
  }

  onOTPfail() {
    clearInterval(this.otp_interval);
    this.closeOTPModals();
    this.retryOTP();
  }

  onOTPRemoveBlockInterval() {
    console.log('ENTEREDD BLOCK INTERVAL');
    this.passedByExpired = false;

    this.detectBlock = 0;
    this.resendCtr = 0;
    //clearInterval(this.intervalTimerVal);
    //clearInterval(this.otp_interval);
    //clearInterval(this.otp_resend_interval);
    this.intervalTimerVal = this.otpConfig[0].otp_resend_limit_duration;
    this.showIntervalBlock = false;

    //this.closeOTPModals();

    //this.startOTP();
    if (
      this.getNotificationChannel().toUpperCase() === 'SMS' ||
      this.getNotificationChannel().toUpperCase() === 'BOTH'
    ) {
      console.log('SMS BLOCK REMOVE');
      this.intervalTimerVal = 0;
      clearInterval(this.otp_interval);
      this.modal_otp_sms = false;

      this.isSMS = true;
      this.otp_expired_default = false;
      this.otp_expired_interval = true;
      this.modal_otp_expiredtimer = true;
    } else {
      console.log('EMAIL BLOCK REMOVE');
      this.modal_otp_email = false;

      this.isSMS = false;
      this.otp_expired_default = false;
      this.otp_expired_interval = true;
      this.modal_otp_expiredtimer = true;
    }
  }

  onOTPTimeout() {
    console.log('PASSED OTP TIMEOUT with attemptCTR = ', this.attemptCtrDsp);
    this.isNewCall = false;
    clearInterval(this.otp_interval);
    this.closeOTPModals();

    this.otp_attempts++;
    if (this.attemptCtrDsp > 0) {
      this.attemptCtrDsp--;
    }

    this.expiredOTP();

    // this.retryOTP();
  }
  onOTPSuccess(key: string) {
    this.isNewCall = false;
    this.isSubmitButtonClicked = false;
    this.passedByExpired = false;
    this.api_key = key;
    clearInterval(this.otp_interval);
    this.closeOTPModals();
    if(this.form_serviceInformation.controls['service_for'].value === 'All')
    {
      this.serviceForVal = 'Mixed';
      this.submitSR();
    }
    else
    {
      this.serviceForVal = this.form_serviceInformation.controls['service_for'].value;
      this.submitSR();
    }
  }
  retryOTP() {
    //this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resetDefaultConfigs();
    if (this.getNotificationChannel().toUpperCase() === 'SMS') {
      this.isSMS = true;
      this.modal_otp_retry = true;
    } else {
      this.isSMS = false;
      this.modal_otp_retry = true;
    }
  }

  expiredOTPIdle() {
    console.log('OTP CHANNEL IDLE EXPIRED = ', this.otp_channel);
    console.log('OTP IS = ' + this.getNotificationChannel().toUpperCase());

    this.passedByExpired = true;

    this.otp_attempts++;
    if (this.attemptCtrDsp > 0) {
      this.attemptCtrDsp--;
    }

    if (this.attemptCtrDsp === 0) {
      this.onOTPfail();
    } else {
      if (this.getNotificationChannel().toUpperCase() === 'SMS') {
        this.detectBlock = 0;
        this.resendCtr = 0;
        //clearInterval(this.intervalTimerVal);
        //clearInterval(this.otp_interval);
        this.modal_otp_sms = false;

        this.isSMS = true;
        this.otp_expired_default = true;
        this.otp_expired_interval = false;
        this.modal_otp_expiredtimer = true;
      } else {
        this.detectBlock = 0;
        this.resendCtr = 0;
        //clearInterval(this.intervalTimerVal);
        //clearInterval(this.otp_interval);
        this.modal_otp_email = false;

        this.isSMS = false;
        this.otp_expired_default = true;
        this.otp_expired_interval = false;
        this.modal_otp_expiredtimer = true;
      }
    }
  }

  expiredOTP() {
    console.log('PASSED EXPIRED OTP MAIN');

    console.log('PASSED EXPIRED with attemptCTR = ', this.attemptCtrDsp);

    if (this.attemptCtrDsp === 0) {
      this.onOTPfail();
    } else {
      if (this.showIntervalBlock == false) {
        if (this.getNotificationChannel().toUpperCase() === 'SMS') {
          this.detectBlock = 0;
          this.resendCtr = 0;
          //clearInterval(this.intervalTimerVal);
          clearInterval(this.otp_interval);
          this.closeOTPModals();

          this.isSMS = true;
          this.otp_expired_default = true;
          this.otp_expired_interval = false;
          this.modal_otp_expiredtimer = true;
        } else {
          this.detectBlock = 0;
          this.resendCtr = 0;
          //clearInterval(this.intervalTimerVal);
          clearInterval(this.otp_interval);
          this.closeOTPModals();

          this.isSMS = false;
          this.otp_expired_default = true;
          this.otp_expired_interval = false;
          this.modal_otp_expiredtimer = true;
        }
      }
    }
  }

  retryEmailOTP(emailAdd: string) {
    console.log('ENTERED retry email otp');
    //this.otp_attempts = 0;
    //this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.passedByExpired = false;
    this.isNewCall = true;
    this.closeOTPModals();

    this.resendCtr += 1;
    if (this.getNotificationChannel().toUpperCase() === 'EMAIL') {
      console.log("EMAIL IS = " + emailAdd);
      this.otp_uri = emailAdd != "" ? emailAdd : this.getNotificationURI();
      this.otp_channel = 'EMAIL';

      this.sendOTP();
    } else {
      this.otp_uri = this.getNotificationURI();
      this.otp_channel = 'SMS'

      this.sendOTP();
    }
  }

  retrySMSOTP(mobileNum: string) {
    console.log('ENTERED retry sms otp');
    //this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.passedByExpired = false;
    this.isNewCall = true;
    this.closeOTPModals();

    this.resendCtr += 1;
    if (this.getNotificationChannel().toUpperCase() === 'SMS' || this.getNotificationChannel().toUpperCase() === 'BOTH') {
      this.otp_uri = mobileNum != "" ? mobileNum : this.getNotificationURI();
      this.otp_channel = 'SMS';
      //this.sendOTP();
      this.sendOTP();
    } else {
      this.otp_uri = this.getNotificationURI();
      this.otp_channel = 'SMS';
      //this.sendOTP();
      this.sendOTP();
    }
  }

  sortLocations(list: any[]) {
    list.sort((a: any, b: any) => {
      return a['value'].localeCompare(b['value']);
    });
  }
  clearCbmSelection() {
    this.selectedCBMS = null;
    this.form_cbmSelection.controls['cbm1'].setValue('');
    this.form_cbmSelection.controls['cbm2'].setValue('');
  }

  //Added Applied Load Guide Modal
  dismissAppliedLoadGuide() {
    this.modal_appliedLoadGuide = false;

    /*if(this.isMobileView == true)
    {
      this.isMobileView = true;
    }
    else
    {
      this.isMobileView = false;
    }*/
  }
  viewAppliedLoadGuide() {
    console.log('ENTERED VIEW AL GUIDE');

    this.modal_appliedLoadGuide = true;

    const mobileWidthThresholdPort = screenData[0].max_width_mobile_portrait;
    const mobileWidthThresholdLand = screenData[0].max_width_mobile_landscape;
    const desktopWidthThreshold = screenData[0].min_width_desktop;

    //const mq = window.matchMedia( "(max-width: " + mobileWidthThreshold + ")" );

    //if ((window.innerWidth <= mobileWidthThresholdPort && window.outerWidth <= mobileWidthThresholdPort) ||
    //(window.innerWidth <= mobileWidthThresholdLand && window.outerWidth <= mobileWidthThresholdLand))
    console.log('OUTERWIDTH == ', window.outerWidth);
    console.log('INNERWIDTH == ', window.innerWidth);
    if (
      window.outerWidth <= mobileWidthThresholdPort ||
      window.outerWidth <= mobileWidthThresholdLand
    ) {
      console.log('IS MOBILE VIEW');
      this.isMobileView = true;
    } else if (window.outerWidth >= desktopWidthThreshold) {
      console.log('IS DESKTOP VIEW');

      this.isMobileView = false;
    }
  }
}
