import {
  AfterViewInit,
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener
} from "@angular/core";
//import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

const centerX = window.innerWidth / 2;
const centerY = window.innerHeight / 2;

declare var $: any;
@Component({
  selector: "ngbd-carousel-basic",
  templateUrl: "./carousel-basic.html",
  styleUrls: ["./carousel-basic.component.scss"],
})
export class NgbdCarouselBasic implements AfterViewInit {
  @ViewChild("carouselAppliedLoadGuide") alCarousel: HTMLElement;
  private isLandscape: boolean = false;

  focused = false;

  activeIdx: number = 0;
  showCarousel = true;

  scale = 1;
  initialDistance: number;
  isZooming = false;
  autoSlide = true;

  panX: number = 0;
  panY: number = 0;

  startX: number = centerX;
  startY: number = centerY;

  imageHeight: number = 0;
  imageWidth: number = 0;

  carouselEl: any;
  imageId: any = 0;
  

  // private parentEl: HTMLDivElement;
  // private imageEl: HTMLImageElement;

  @HostListener('window:resize')
  onResize() {
    this.detectOrientation();
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.carouselEl = this.el.nativeElement.querySelector(
      "#carouselAppliedLoadGuide"
    );
    $(this.carouselEl).carousel();
    this.carouselEl.addEventListener("slide.bs.carousel", (e) => {
      if (this.focused) {
        e.preventDefault();
      }
    });

    this.detectOrientation();
  }

  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;

    if (event.touches.length === 2) {
      this.initialDistance = this.calculateDistance(event.touches);
      this.isZooming = true;
    }
  }

  onTouchMove(event: TouchEvent) {
    this.pinchZoom(event);
    this.touchPan(event);
  }

  onTouchEnd(event: TouchEvent) {
    // event.preventDefault()
    if (this.isZooming) {
      this.panX = 0;
      this.panY = 0;
      this.setTransformStyle()
      this.isZooming = false;
    }
  }

  private calculateDistance(touches: TouchList): number {
    const [a, b] = Array.from(touches);
    return Math.hypot(a.clientX - b.clientX, a.clientY - b.clientY);
  }

  private setTransformStyle() {
    const transformValue = `scale(${this.scale}) translate(${this.panX}px, ${this.panY}px)`;
    const imageContainer = this.el.nativeElement.querySelector(
      `[data-image-id="${this.imageId}"]`
    );
    this.renderer.setStyle(imageContainer, "transform", transformValue);
  }

  onSlidePrev(event) {
    this.activeIdx -= 1;
    console.log("CURRENT IDX PRV = ", this.activeIdx);
  }

  onSlideNext(event) {
    this.activeIdx += 1;
    console.log("CURRENT IDX NEXT = ", this.activeIdx);
  }

  toggleFocus(event: MouseEvent) {
    this.focused = !this.focused;
    this.autoSlide = !this.autoSlide;

    if (this.focused) {
      const parentDiv = event.currentTarget as HTMLDivElement;

      const imageEl = parentDiv.querySelector("img");
      this.imageId = imageEl.getAttribute("data-image-id");
      this.imageWidth = imageEl.width;
      this.imageHeight = imageEl.height;
    }

    if (!this.focused) {
      this.panX = 0;
      this.panY = 0;
      this.scale = 1;

      this.setTransformStyle();
    }
  }

  private pinchZoom(event: TouchEvent) {
    if (
      this.isZooming &&
      event.touches.length === 2 &&
      this.focused &&
      this.scale >= 1
    ) {
      const currentDistance = this.calculateDistance(event.touches);
      let scaleFactor = currentDistance / this.initialDistance;

      if (scaleFactor < 1) {
        scaleFactor = Math.ceil(scaleFactor);
      }

      this.scale = scaleFactor;
      this.setTransformStyle();
    }
  }

  private touchPan(event: TouchEvent) {
    if (event.touches.length === 1 && this.focused) {
      const parentDiv = event.currentTarget as HTMLDivElement;
      const imageEl = parentDiv.querySelector("img");

      const imgContainerEl = parentDiv.querySelector(".image-container");
      const imgContainerBoundingClient = imgContainerEl.getBoundingClientRect();
      const boundingClient = parentDiv.getBoundingClientRect();
      const imageBoundingClient = imageEl.getBoundingClientRect();

      const currentX = event.touches[0].clientX;
      const currentY = event.touches[0].clientY;

      let deltaX = currentX - this.startX;
      let deltaY = currentY - this.startY;

      // stop panning right if image left is equal to zero
      if (currentX - this.startX > 0) {
        console.log('dimensions', {
          container: imgContainerBoundingClient,
          image: imageBoundingClient
        })
        const boundingVal = this.isLandscape ? imgContainerBoundingClient.left : boundingClient.left
        if (Math.ceil(imageBoundingClient.left) > boundingVal) {
          deltaX = 0;
          const boundingClientLeft = this.isLandscape ? boundingClient.left : imageBoundingClient.left;
          this.panX = -boundingClientLeft + this.panX;
        }
      }
      // stop panning left if image right is equal to view port right
      if (currentX - this.startX < 0) {
        const boundingVal = this.isLandscape ? imgContainerBoundingClient.right : boundingClient.right
        if (Math.ceil(imageBoundingClient.right) <= boundingVal) {
          deltaX = 0;
          this.panX =
            boundingVal - imageBoundingClient.right + this.panX;
        }
      }

      // panning to top
      if (currentY - this.startY > 0) {
        const boundingVal = this.isLandscape ? imgContainerBoundingClient.top : boundingClient.top;
        if (Math.ceil(imageBoundingClient.top) > boundingVal) {
          deltaY = 0;
          this.panY = -boundingVal + this.panY;
        }
      }

      // limit panning to bottom
      if (currentY - this.startY < 0) {
        const boundingVal = this.isLandscape ? imgContainerBoundingClient.bottom : boundingClient.bottom;
        if (Math.ceil(imageBoundingClient.bottom) < boundingVal) {
          deltaY = 0;
          this.panY =
            boundingVal - imageBoundingClient.bottom + this.panY;
        }
      }

      const newPanX = this.panX + deltaX;
      const newPanY = this.panY + deltaY;

      const maxPanX = ((this.scale - 1) * this.imageWidth) / 2;
      const minPanX = -maxPanX;
      const maxPanY = ((this.scale - 1) * this.imageHeight) / 2;
      const minPanY = -maxPanY;

      this.panX = Math.min(maxPanX, Math.max(minPanX, newPanX));
      this.panY = Math.min(maxPanY, Math.max(minPanY, newPanY));

      if(imageBoundingClient.height <= imgContainerBoundingClient.height) {
        this.panY = 0
      }

      if(imageBoundingClient.width <= imgContainerBoundingClient.width) {
        this.panX = 0;
      }


      this.setTransformStyle();

      this.startX = currentX;
      this.startY = currentY;
    }
  }

  private detectOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight

    this.panX = 0;
    this.panY = 0;

    this.setTransformStyle();
  }

  // Prevent Background interactions
  onOverlayTouchStart(event: TouchEvent) {
    event.stopPropagation();
  }

  onImageTouchStart(event: TouchEvent) {
    event.stopPropagation();
  }
}
