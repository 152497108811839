import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'select-checkbox',
  templateUrl: './select-checkbox.component.html',
  styleUrls: ['./select-checkbox.component.scss']
})
export class SelectCheckboxComponent implements OnInit {
  @Input() items: string[] = ['Item 1', 'Item 2', 'Item 3'];
  @Input() selectedIndex: number[] = [];
  @Input() placeHolder = 'Select an Item';
  @Input() width = '324px';
  @Input()  showSelectAll: boolean = false;
  @Output() onSelectionChanged: EventEmitter<String[]> = new EventEmitter();
  selectedItems: string[]  = [];
  dropdownVisible: boolean;
  focused: boolean;
  hovered: boolean;
  hasItemSelected: boolean;
  selectAll: boolean = false;

  constructor() {
    this.dropdownVisible = false;
    this.hovered = false;
    this.hasItemSelected = false;
  }
  ngOnInit() {
  }
  hideDropdown() {
    this.focused = false;
  }
  toggleFocus() {
    this.focused = ! this.focused;
  }
  onMouseOver() {
    this.hovered = true;
  }
  onMouseLeft() {
    this.hovered = false;
  }
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.onSelectAll();
  }
  onSelectAll() {
    this.selectedIndex = [];
    if (this.selectAll) {
      for (let c = 0 ; c < this.items.length; c++) {
        this.selectedIndex.push(c);
      }
    }
  }
  onItemSelected(index: number) {
    if ( !this.selectedIndex.includes(index)) {
      this.addItem(index);
    } else {
      this.removeItem(index);
    }
  }
  removeItem(index: number) {
    if ( this.selectedIndex.includes(index)) {
      this.selectedIndex.splice(this.selectedIndex.indexOf(index), 1);
      this.selectionChanged();
    }
  }
  addItem(index: number) {
    if ( !this.selectedIndex.includes(index)) {
      this.selectedIndex.push(index);
      this.selectionChanged();
    }
  }
  selectionChanged() {
      const selectedItems: string[] = [];

      for (const index of this.selectedIndex) {
        selectedItems.push(this.items[index]);
      }
      this.onSelectionChanged.emit(selectedItems);
  }
}
