import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-starshading',
  templateUrl: './starshading.component.html',
  styleUrls: ['./starshading.component.scss']
})
export class StarshadingComponent {
  @Input() rating: number;
  @Input() reviews: number;

  get stars(): string[] {
      const fullStars = Math.floor(this.rating);
      const halfStar = this.rating - fullStars >= 0.5 ? 'half-star' : '';
      const emptyStars = 5 - Math.ceil(this.rating);
      return Array(fullStars).fill('full-star').concat(halfStar).concat(Array(emptyStars).fill('empty-star'));  
    }
}