import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';

import * as events from 'events';

@Component({
  selector: 'cbm-item',
  templateUrl: './cbm-item.component.html',
  styleUrls: ['./cbm-item.component.scss'],
})
export class CbmItemComponent implements OnInit {
  @Input() selectionOrder: number = 0;
  @Input() selected: boolean = false;
  @Input() rating: number;
  @Input() recommended: boolean = false;
  @Input() cmbId: string;
  @Input() pcab: string = 'A';
  @Input() name: string = 'CBM NAME';
  @Input() tags: string[] = [
    'Most Awaited',
    'Excellent Customer Service',
    'Quick Response Time',
    'Good Quality of Work',
  ];

  @Output() itemClick: EventEmitter<any> = new EventEmitter();
  @Output() onViewDetail: EventEmitter<any> = new EventEmitter();

  public ratingValue: number;
  public ratingValueFin: number;
  private cmbIdNumber: string;
  notfound: boolean = false;

  cbmData: any = null;
  cbmID: string = '';
  feebbackcount: number;

  feedbacks: any[] = [];

  constructor(private cbmService: ApiService) {}

  ngOnInit() {
    // this.ratingValue = Math.floor(this.rating * 10) /10 ;
    this.cmbIdNumber = this.cmbId;

    //console.log("cbm number", this.cmbIdNumber);
    this.fetchCbm();
  }
  fetchCbm() {
    this.cbmService.getCBM(this.cmbIdNumber).subscribe(
      data => {
        const res = data['body'];
        if (res['contractor'].length === 0) {
          this.onNotFound();
          return;
        }
        this.cbmData = res;
        this.feedbacks = res['feedback'];
        this.ratingValue = res['contractor']['rating'];
        this.ratingValueFin = Number(this.ratingValue.toFixed(1));
        console.log('feedback', this.feedbacks);
        //this.pageCount = Math.ceil(this.feedbacks.length / 5);
        this.sortByDate();
        this.sortCities();
      },
      error => {
        this.onNotFound();
      }
    );
  }
  onNotFound() {
    this.cbmData = null;
    this.notfound = true;
  }
  clicked() {
    this.itemClick.emit();
  }
  viewDetail($event) {
    $event.stopPropagation();
    this.onViewDetail.emit();
  }

  sortByDate() {
    for (let c = 0; c < this.feedbacks.length; c++) {
      let maxIndex = c;
      let maxDate = this.feedbacks[c]['date_created'];
      for (let d = c; d < this.feedbacks.length; d++) {
        const date = this.feedbacks[d]['date_created'];
        if (date > maxDate) {
          maxDate = date;
          maxIndex = d;
        }
      }
      const temp = this.feedbacks[c];
      this.feedbacks[c] = this.feedbacks[maxIndex];
      this.feedbacks[maxIndex] = temp;
    }
  }
  sortCities() {
    const cities = this.cbmData['city'];
    for (let c = 0; c < cities.length; c++) {
      let min_val = cities[c]['value'];
      let min_index = c;
      for (let d = c; d < cities.length; d++) {
        if (cities[d]['value'].localeCompare(min_val) < 0) {
          min_index = d;
          min_val = cities[d]['value'];
        }
      }
      const temp = cities[c]['value'];
      cities[c]['value'] = cities[min_index]['value'];
      cities[min_index]['value'] = temp;
    }
  }

  getRatingValue(rating: string | number): number {
    if(!rating) return 0
    if (typeof rating === 'string') {
      const parsedRating = parseFloat(rating);
      if (isNaN(parsedRating)) {
        return 0; // Return a default value (0) when the input rating is not a valid number
      }
      rating = parsedRating;
    }

    // Return the rating rounded to one decimal place
    if (typeof rating !== 'undefined') {
      return parseFloat(rating.toFixed(1));
    } else {
      return 0; // Return a default value (0) if rating is undefined
    }
  }
}
