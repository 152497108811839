import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  UntypedFormGroup,
  FormControl,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../shared/services/api.service";
import { ServiceRequestService } from "../../../shared/services/service-request.service";
import { OtpProviderService } from "../../../shared/services/otp-provider.service";

import data from "../../../../assets/json/contractors-info.json";
import dataOTP from "../../../../assets/json/otp_config.json";

//FOR LOCAL JSON
interface CBMStatusInfo {
  title: string;
  linkRes?: string;
  linkBiz?: string;
  desc1: string;
  desc2?: string;
}

interface CBMInfo {
  bundle: string;
  status: Array<CBMStatusInfo>;
}

interface OTPConfig {
  otp_timeout: number;
  otp_attempt_limit: number;
  otp_resend_limit: number;
  otp_resend_limit_duration: number;
}

@Component({
  selector: "track-request",
  templateUrl: "./track.component.html",
  styleUrls: ["./track.component.scss"],
})
export class TrackComponent implements OnInit {
  //---OTP TIMER BEHAVIOR UPDATED JULY 11 2023----

  isTrackButtonClicked: boolean = false;

  isSMS: boolean = false; //for expired otp sms or email
  mobileFromRetry: string = "none"; // for alternative mobile num in retry
  emailFromRetry: string = "none"; // for alternative email add in retry
  idleTimerIsEnabled: boolean = false;
  isNewCall: boolean = false;
  passedByExpired: boolean = false;

  //----------

  //For Rework Detection in Status Desc:
  isFromRework: boolean = false;

  //----------

  displayEstimatedCost: boolean = false;

  cbmInfoArray: CBMInfo[] = data as CBMInfo[];

  otpConfig: OTPConfig[] = dataOTP[0].data.config;

  detectBlock: number = 0;
  resendCtr: number = 0;
  showIntervalBlock: boolean = false;
  isResend: boolean = false;
  intervalTimerVal: number;
  otp_resend_interval: any;

  attemptCtrDsp: number = 0;
  resendCtrDsp: number = 0;

  //timer updating
  updateInterval: any;
  updateDuration: any;
  smsOrEmailValRetry: string = "";

  otp_timeout: number;
  otp_interval: any;
  otp_mobile: string;
  otp_email: string;
  modal_otp_sms: boolean = false;
  modal_otp_email: boolean = false;
  modal_otp_retry: boolean = false;
  modal_otp_expiredtimer: boolean = false;

  otp_expired_default: boolean = false; //3 mins timer expired
  otp_expired_interval: boolean = false; //1 hr timer expired

  max_otp_attempts: number = 0;
  otp_attempts: number = 0; //5;//3;
  max_resend_attempts: number = 0;
  resend_attempts: number = 0;

  //-------------------------
  isLoading: boolean = false;
  showTrack: boolean;
  showData: boolean;
  showRating: boolean;
  form: UntypedFormGroup;
  form_cbmRating: UntypedFormGroup;
  serviceRequest: any;
  invalid_request: boolean;
  showRateCompleteDialog: boolean = false;
  showConfirmSubmit: boolean = false;
  showConfirmCancel: boolean = false;
  hasRated: boolean = false;
  // OTP
  forFeedback: boolean = false;
  modal_verifyCancel: boolean = false;
  api_key: string = "";

  // INFO MODAL
  modal_info: boolean = false;
  info_title: string = "title";
  info_message: string = "message";

  //FOR CHEVRON PROGRESS BAR
  expandStatDesc: boolean = false;
  sr_status: string = "";
  sr_bundle: string = "";
  sr_account_type: string = "";
  sr_ref_no: string = "";
  sr_old_status: string = "";
  sr_old_refNo: string = "";
  sr_cbm_desc: string = "";
  sr_cbm_desc_link: string = "";
  sr_cbm_desc_display: boolean = false;
  otpRequest: any = null;

  constructor(
    private serviceRequests: ServiceRequestService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private cbmService: ApiService,
    private route: ActivatedRoute,
    private otp: OtpProviderService,
    private cdr2: ChangeDetectorRef
  ) {
    this.initForm();
    this.serviceRequest = {};
    this.invalid_request = false;

    this.showTrackPage();
    // pre load data without requesting. for dev only
    //  this.loadDemo();
    //  this.showDataPage();
    // this.showRatingPage();
  }

  ngOnInit() {
    // console.log("BAM - start of track sr");

    localStorage.setItem(
      "hourTimerInterval",
      this.otpConfig[0].otp_resend_limit_duration.toString()
    );
    localStorage.setItem(
      "defaultTimerInterval",
      this.otpConfig[0].otp_timeout.toString()
    );

    this.max_otp_attempts = this.otpConfig[0].otp_attempt_limit;
    this.max_resend_attempts = this.otpConfig[0].otp_resend_limit;
    this.otp_attempts = 0; //this.otpConfig[0].otp_attempt_limit;
    this.resend_attempts = 0; //this.otpConfig[0].otp_resend_limit;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;

    this.smsOrEmailValRetry = this.getOTPURI();
    if (this.getOTPchannel() === "SMS") {
      this.isSMS = true;
    } else {
      this.isSMS = false;
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(15),
          Validators.pattern("^\\+639\\d{9}$"),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(255),
          Validators.pattern(
            "^^[A-Za-z0-9\\.\\-]+@[\\w\\-\\.]+\\.[A-Za-z]{2,3}$"
          ),
        ],
      ],
      reference_number: [
        "",
        [
          Validators.required,
          Validators.pattern("^[A-Za-z0-9]+$"),
          Validators.minLength(14),
        ],
      ],
    });

    this.form_cbmRating = this.formBuilder.group({
      service_id: ["", []],
      contractor_name: ["", []],
      contractor_id: ["", []],
      customer_id: ["", []],
      customer_service: ["", [Validators.required]],
      response_time: ["", [Validators.required]],
      work_quality: ["", [Validators.required]],
      comment: ["", []],
      user: ["", []],
    });
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr2.detectChanges();
  }

  resetDefaultConfigs() {
    this.max_otp_attempts = this.otpConfig[0].otp_attempt_limit;
    this.max_resend_attempts = this.otpConfig[0].otp_resend_limit;
    this.otp_attempts = 0; //this.otpConfig[0].otp_attempt_limit;
    this.resend_attempts = 0; //this.otpConfig[0].otp_resend_limit;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;
  }

  showTrackPage() {
    this.showTrack = true;
    this.showData = false;
    this.showRating = false;
    this.isLoading = false;
  }
  showDataPage() {
    this.showTrack = false;
    this.showData = true;
    this.showRating = false;
    this.isLoading = false;
  }
  showRatingPage() {
    this.showTrack = false;
    this.showData = false;
    this.showRating = true;
    this.isLoading = false;
  }
  showLoadingPage() {
    if (this.modal_otp_sms == false && this.modal_otp_email == false) {
    //   console.log("ENTERED 1 loading track");
      this.showTrack = false;
      this.showData = false;
      this.showRating = false;
      this.isLoading = true;
    } else if (this.modal_otp_sms == true || this.modal_otp_email == true) {
    //   console.log("ENTERED 2 loading track");
      this.isLoading = false;
    }
  }
  rateCBM() {
    // this.startOTP();
    this.startRatingOTP();
  }
  rateCustomerService(rating: number) {
    this.form_cbmRating.controls["customer_service"].setValue(rating);
  }
  rateResponseTime(rating: number) {
    this.form_cbmRating.controls["response_time"].setValue(rating);
  }
  rateQuality(rating: number) {
    this.form_cbmRating.controls["work_quality"].setValue(rating);
  }
  startRatingOTP() {
    this.forFeedback = true;
    this.resetDefaultConfigs();
    this.startOTP();
  }
  submitRating() {
    this.showConfirmSubmit = false;
    let body = {
      service_id: this.form_cbmRating.controls["service_id"].value,
      contractor_id: this.form_cbmRating.controls["contractor_id"].value,
      customer_id: this.form_cbmRating.controls["customer_id"].value,
      user: this.form_cbmRating.controls["user"].value,
      ratings: {
        customer_service:
          this.form_cbmRating.controls["customer_service"].value,
        response_time: this.form_cbmRating.controls["response_time"].value,
        work_quality: this.form_cbmRating.controls["work_quality"].value,
      },
      comment: this.form_cbmRating.controls["comment"].value,
    };
    // console.log("BODY CHECK IS = ", body);
    this.showLoadingPage();
    let funcParams = {
      funcParams: {
        funcName: 'part_1'
      }
    };
    body = { ...body, ...funcParams };
    this.cbmService.postFeedback(body, this.api_key).subscribe(
      (data) => {
        if ( data?.['body']?.['requestBody'] ) {
          this.cbmService.postFeedback(data?.['body']['requestBody'], this.api_key).subscribe(
            (data) => {
              // console.log("DATA CHECK IS = ", data);
              this.hasRated = true;
              this.onRatingSuccess();
              this.showRatingPage();
            },
            (error) => {
              this.hasRated = true;
              console.log(error);
              this.showRatingPage();
            }
          );
        } else {
          this.hasRated = true;
          this.onRatingSuccess();
          this.showRatingPage();
        }
      },
      (error) => {
        this.hasRated = true;
        console.log(error);
        this.showRatingPage();
      }
    );
  }
  onRatingSuccess() {
    this.showRateCompleteDialog = true;
    setTimeout(() => {
      this.showRateCompleteDialog = false;
      this.showDataPage();
    }, 3000);
  }
  trackSR(api_key: string) {
    this.viewRequest(
      this.form.controls["reference_number"].value,
      this.form.controls["email"].value,
      this.form.controls["mobile"].value,
      api_key
    );
  }

  tracePrevSRStatus(refNumber: string) {
    this.serviceRequests.getIfReworkStatusExist(refNumber).subscribe((data) => {
    //   console.log("REWORK TRY API = " + data["body"]);
      var statusListStr = JSON.stringify(data["body"]);
      if (statusListStr.includes("For Rework")) {
        // console.log("FOR REWORK TRUE");
        this.isFromRework = true;
      } else {
        // console.log("FOR REWORK FALSE");
        this.isFromRework = false;
      }
    });
  }

  viewRequest(
    reference: string,
    email: string,
    mobile: string,
    api_key: string
  ) {
    // if (mobile !== '') {
    //     mobile = mobile.replace('+', '%2B');
    // }
   // this.showLoadingPage();
 
    this.serviceRequests.getRequest(
        reference,
        email,
        mobile,
        api_key)
        .subscribe(data => {
            this.serviceRequest = data['body'];
            console.log("DATA = ", this.serviceRequest);
            if ( this.serviceRequest['message'] !== undefined ) {
                console.log("ERROR HERE 1")
                this.showTrackPage();
                this.invalid_request = true;
                return;
            }
            this.showDataPage();
            console.log("DATA DETAILS BAM = " + this.serviceRequest['service_request']);

            this.sr_ref_no = this.serviceRequest['service_request']['referenceNo'].toString();
            this.sr_status = this.serviceRequest['service_request']['status'].toString();
            this.sr_bundle = this.serviceRequest['service_request']['service_bundle'].toString();
            this.sr_account_type = this.serviceRequest['service_request']['accountType'].toString();
            
            console.log("STATUS = " + this.sr_status);

          if (this.sr_status === "Closed") {
            this.displayEstimatedCost = false;
          } else {
            this.displayEstimatedCost = true;
          }

          if (
            this.sr_status != "" &&
            this.sr_bundle != "" &&
            this.sr_account_type != ""
          ) {
            if (this.sr_bundle == "Full Bundle") {
              //USE THIS FOR TEXT EXPANSION LAYOUT TESTING FOR DIFF STATUS, COMMENT OUT IF DONE TESTING
              /*if(this.sr_status == 'For CBM Service')
                 {
                    this.sr_cbm_desc = this.cbmInfoArray[0]['status'][6]['desc1'];
                 }*/

              //THIS IS THE ACTUAL CODE, DO NOT DELETE
              for (
                var ctr = 0;
                ctr < this.cbmInfoArray[0]["status"].length;
                ctr++
              ) {
                if (
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() ===
                    "For Meralco Verification" &&
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                //   console.log("ENTERED 1");

                  if (this.isFromRework == false) {
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[0]["status"][ctr]["desc1"];
                  } else if (this.isFromRework == true) {
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[0]["status"][ctr]["desc2"];
                  }
                } else if (
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() ===
                    "For Energization" &&
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                //   console.log("ENTERED 2");
                //   console.log(
                //     "REALTIME STATUS = " +
                //       this.cbmInfoArray[0]["status"][ctr]["title"].toString()
                //   );

                  this.sr_old_status = "For Energization";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[0]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() ===
                    "For Rework" &&
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                //   console.log("ENTERED 3");
                  this.sr_old_status = "For Rework";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[0]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() !==
                    "For Meralco Verification" &&
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() !==
                    "For Energization" &&
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() !==
                    "For Rework" &&
                  this.cbmInfoArray[0]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                //   console.log("ENTERED 4");
                //   console.log(
                //     "REALTIME STATUS = " +
                //       this.cbmInfoArray[0]["status"][ctr]["title"].toString()
                //   );
                //   console.log("SR STATUS = " + this.sr_status);
                //   console.log(
                //     "DESC = " +
                //       this.cbmInfoArray[0]["status"][ctr]["desc1"].toString()
                //   );
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[0]["status"][ctr]["desc1"];
                }
              }
            } else if (this.sr_bundle == "Technical Bundle") {
              for (
                var ctr = 0;
                ctr < this.cbmInfoArray[1]["status"].length;
                ctr++
              ) {
                if (
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() ===
                    "For Meralco Verification" &&
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  if (this.isFromRework == false) {
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[1]["status"][ctr]["desc1"];
                  } else if (this.isFromRework == true) {
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[1]["status"][ctr]["desc2"];
                  }
                } else if (
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() ===
                    "For Energization" &&
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_old_status = "For Energization";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[1]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() ===
                    "For Rework" &&
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_old_status = "For Rework";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[1]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() !==
                    "For Meralco Verification" &&
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() !==
                    "For Energization" &&
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() !==
                    "For Rework" &&
                  this.cbmInfoArray[1]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[1]["status"][ctr]["desc1"];
                }
              }
            } else if (this.sr_bundle == "Documentary Bundle") {
              for (
                var ctr = 0;
                ctr < this.cbmInfoArray[2]["status"].length;
                ctr++
              ) {
                if (
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() ===
                    "For Meralco Verification" &&
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  if (this.isFromRework == false) {
                    // console.log("ENTERED HERE BAM DOCUMENTARY!!!");
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[2]["status"][ctr]["desc1"];
                    // console.log(
                    //   "ENTERED HERE BAM!!! INFO = " + this.sr_cbm_desc
                    // );
                  } else if (this.isFromRework == true) {
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[2]["status"][ctr]["desc2"];
                  }
                } else if (
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() ===
                    "For Energization" &&
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_old_status = "For Energization";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[2]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() ===
                    "For Rework" &&
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_old_status = "For Rework";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[2]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() !==
                    "For Meralco Verification" &&
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() !==
                    "For Energization" &&
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() !==
                    "For Rework" &&
                  this.cbmInfoArray[2]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[2]["status"][ctr]["desc1"];
                }
              }
            } else if (this.sr_bundle == "Processing Bundle") {
              for (
                var ctr = 0;
                ctr < this.cbmInfoArray[3]["status"].length;
                ctr++
              ) {
                if (
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() ===
                    "For Meralco Verification" &&
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  if (this.isFromRework == false) {
                    // console.log("ENTERED HERE BAM PROCESSING!!!");
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[3]["status"][ctr]["desc1"];
                    // console.log(
                    //   "ENTERED HERE BAM!!! INFO = " + this.sr_cbm_desc
                    // );
                  } else if (this.isFromRework == true) {
                    this.sr_cbm_desc_link = "none";
                    this.sr_cbm_desc =
                      this.cbmInfoArray[3]["status"][ctr]["desc2"];
                  }
                } else if (
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() ===
                    "For Energization" &&
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_old_status = "For Energization";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[3]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() ===
                    "For Rework" &&
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_old_status = "For Rework";
                  this.sr_old_refNo = this.sr_ref_no;
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[3]["status"][ctr]["desc1"];
                } else if (
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() !==
                    "For Meralco Verification" &&
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() !==
                    "For Energization" &&
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() !==
                    "For Rework" &&
                  this.cbmInfoArray[3]["status"][ctr]["title"].toString() ===
                    this.sr_status
                ) {
                  this.sr_cbm_desc_link = "none";
                  this.sr_cbm_desc =
                    this.cbmInfoArray[3]["status"][ctr]["desc1"];
                }
              }
            }
          }

          this.form_cbmRating.controls["service_id"].setValue(
            this.serviceRequest["service_request"]["uuid"]
          );
          this.form_cbmRating.controls["contractor_id"].setValue(
            this.serviceRequest["contractor"]["uuid"]
          );
          this.form_cbmRating.controls["customer_id"].setValue(
            this.serviceRequest["customer"]["uuid"]
          );
          this.form_cbmRating.controls["contractor_name"].setValue(
            this.serviceRequest["contractor"]["cbmName"]
          );
          this.form_cbmRating.controls["user"].setValue(this.getCustomerName());
          this.invalid_request = false;
          this.hasRated =
            this.serviceRequest["service_request"]["hasFeedback"] === true;

          //console.log("THIS SR IS = ", this.form_cbmRating.controls['service_id'].value);
        },
        (error) => {
          console.log(error);
          this.invalid_request = true;
          this.showDataPage();
        }
      );
  }
  loadDemo() {
    this.serviceRequest = {
      service_request: {
        appliedAs: "customer representative",
        referenceNo: "CBM202201001147",
        appliedLoad: "1.5",
        date_created: "2022-01-24 01:13:43",
        accountType: "Residential",
        multiMeter: false,
        origin: "Customer",
        serviceAddress: "test, NATUNUAN - SAN JOSE, SAN JOSE, BATANGAS",
        estimatedCost: "8685",
        uuid: "682d8054-f8e7-4ecb-b26e-6f802d247760",
        cxeReferenceNumber: "22012232017",
        date_modified: "2022-01-24 01:13:43",
        billingAddress: "test, NATUNUAN - SAN JOSE, SAN JOSE, BATANGAS",
        landmark: "test",
        status: "For CBM Acceptance",
        city: "SAN JOSE",
        service_type: "Service Application",
        service_bundle: "Full Bundle",
        service_bundle_rate: "8685.00",
      },
      customer: {
        customer_type: "Customer",
        lastName: "test",
        firstName: "test",
        address: "test",
        date_modified: "2022-01-24 01:13:38",
        date_created: "2022-01-24 01:13:38",
        middleName: "t",
        mobileNo: "+639167501675",
        notificationChannel: "SMS",
        birthDate: "2004-01-22",
        uuid: "c5daa5e8-7933-4526-8644-dc237335172d",
      },
      contractor: {
        address: "test",
        endDate: "2021-06-30 00:00:00",
        rating: 3,
        active: "Delisted",
        pcabNo: "123456789012",
        mobileNo: "09123456789",
        monthly_service_request_quota: 60,
        uuid: "e782b356-adc0-4e69-b38b-d2d08da1c702",
        cbmName: "Contractor Test",
        class: "residential",
        startDate: "2020-05-04 00:00:00",
        email: "contractortest@yopmail.com",
        status: "Active",
      },
      frontliner: null,
      customer_representative: {
        customer_type: "Customer Representative",
        lastName: "rep",
        firstName: "rep",
        date_modified: "2022-01-24 01:13:38",
        relationToCustomer: "Friend",
        date_created: "2022-01-24 01:13:38",
        mobileNo: "+639167501675",
        notificationChannel: "SMS",
        uuid: "cfc934a8-11ef-41eb-8c0e-67135c03c71c",
      },
      responsible_team: null,
    };
    this.form_cbmRating.controls["service_id"].setValue(
      this.serviceRequest["service_request"]["uuid"]
    );
    this.form_cbmRating.controls["contractor_id"].setValue(
      this.serviceRequest["contractor"]["uuid"]
    );
    this.form_cbmRating.controls["customer_id"].setValue(
      this.serviceRequest["customer"]["uuid"]
    );
    this.form_cbmRating.controls["contractor_name"].setValue(
      this.serviceRequest["contractor"]["cbmName"]
    );
    this.form_cbmRating.controls["user"].setValue("Customer");
    // console.log(this.serviceRequest);
    this.showDataPage();
  }
  format(data: any) {
    if (data === undefined || data === "") {
      return "--";
    }
    return data;
  }
  getCustomerName() {
    if (
      this.serviceRequest["service_request"]["accountType"].toString() ===
      "Business"
    ) {
      return this.serviceRequest["customer"]["businessName"].toString();
    }
    return (
      this.serviceRequest["customer"]["firstName"].toString() +
      " " +
      this.serviceRequest["customer"]["lastName"].toString()
    );
  }

  getBusinessCenter() {
    const value = this.serviceRequest["responsible_team"];
    if (value === "HMB Office Makati") {
      return "Makati Business Center";
    }
    return value;
  }
  // OTP
  getOTPchannel(): string {
    if (
      this.form.controls["mobile"].valid &&
      this.form.controls["mobile"].value !== ""
    ) {
      return "SMS";
    } else {
      return "EMAIL";
    }
  }
  getOTPURI(): string {
    const channel = this.getOTPchannel();
    return channel === "SMS"
      ? this.form.controls["mobile"].value
      : this.form.controls["email"].value;
  }

  sendOTPFromRetryModal(channel: string, uri: string) {
    this.otp_timeout = this.otpConfig[0].otp_timeout; //120; // seconds
    this.otp_attempts = this.otpConfig[0].otp_attempt_limit; //3; // reset attempts
    const refNum = this.form.controls["reference_number"].value;
    if (this.passedByExpired === false) {
    //   console.log("passed by expired is FALSE");

      if (!this.forFeedback) {
        if (channel === "SMS") {
          this.isSMS = true;
          this.sendOTP_SMS_track_retryModal(uri, refNum);
        } else {
          this.isSMS = false;
          this.sendOTP_email_track_retryModal(uri, refNum);
        }
      } else {
        var name = this.form_cbmRating.controls["contractor_name"].value;
        if (channel === "SMS") {
          this.isSMS = true;
          this.sendOTP_SMS(uri, name);
        } else {
          this.isSMS = false;
          this.sendOTP_Email(uri, name);
        }
      }
    } else {
    //   console.log("passed by expired is TRUE");
      clearInterval(this.otp_interval);
      this.otp_timeout = this.otpConfig[0].otp_timeout;
    }
  }

  sendOTP(channel: string, uri: string) {
    this.otp_timeout = this.otpConfig[0].otp_timeout; //120; // seconds
    this.otp_attempts = this.otpConfig[0].otp_attempt_limit; //3; // reset attempts
    const refNum = this.form.controls["reference_number"].value;
    if (this.passedByExpired === false) {
    //   console.log("passed by expired is FALSE");
      // this.otp_interval  = setInterval(() => {
      //     if ( this.otp_timeout > 0) {
      //         this.otp_timeout--;
      //         return;
      //     }
      //     this.onOTPTimeout();
      // } , 1000 );

      if (!this.forFeedback) {
        if (channel === "SMS") {
          this.isSMS = true;
          this.sendOTP_SMS_track(uri, refNum);
        } else {
          this.isSMS = false;
          this.sendOTP_email_track(uri, refNum);
        }
      } else {
        var name = this.form_cbmRating.controls["contractor_name"].value;
        if (channel === "SMS") {
          this.isSMS = true;
          this.sendOTP_SMS(uri, name);
        } else {
          this.isSMS = false;
          this.sendOTP_Email(uri, name);
        }
      }
    } else {
    //   console.log("passed by expired is TRUE");
      clearInterval(this.otp_interval);
      this.otp_timeout = this.otpConfig[0].otp_timeout;
    }
  }
  startOTP() {
    if (this.isTrackButtonClicked) {
      return;
    }

    // console.log("CLICKED START OTP");
    this.invalid_request = false;
    this.isTrackButtonClicked = true;
    this.showConfirmSubmit = false;
    var uri = this.getOTPURI();
    this.sendOTP(this.getOTPchannel(), uri);
  }

  resendOTP() {
    this.resendCtr += 1;
    // console.log("RESEND CTR = " + this.resendCtr);
    if ( this.otpRequest ) {
      this.otpRequest.unsubscribe();
    }
    var channel = this.getOTPchannel();

    if (this.resendCtr >= this.otpConfig[0].otp_resend_limit) {
    //   console.log("ENTERED RESEND LIMIT > 5");
      this.detectBlock += 1;
      if (this.detectBlock == 1) {
        this.isResend = false;
        clearInterval(this.otp_interval);
        //this.otp_timeout = this.otpConfig[0].otp_timeout;
        this.intervalTimerVal = this.otpConfig[0].otp_resend_limit_duration;
        this.showIntervalBlock = true;

        this.otp_resend_interval = setInterval(() => {
          if (this.intervalTimerVal > 0) {
            this.intervalTimerVal--;
            return;
          }

          // this.onOTPRemoveBlockInterval();

          if (this.showIntervalBlock == true && this.intervalTimerVal != 0) {
            if (this.getOTPchannel() == "SMS") {
              this.isSMS = true;
              this.modal_otp_sms = true;
            } else {
              this.isSMS = false;
              this.modal_otp_email = true;
            }
          } else if (
            this.showIntervalBlock == true &&
            this.intervalTimerVal == 0
          ) {
            this.onOTPRemoveBlockInterval();
          }
        }, 1000);
      }
      /*const uri = this.getOTPURI();
      const channel = this.getOTPchannel();
      const refNum = this.form.controls['reference_number'].value;
      if ( channel !== 'EMAIL') {
          this.sendOTP_SMS_track(uri, refNum);
      } else {
          this.sendOTP_email_track(uri, refNum);
      }*/
    } else if (this.resendCtr < this.otpConfig[0].otp_resend_limit) {
    //   console.log("RESEND # ", this.resendCtr);
      this.isResend = true;
      if (this.resendCtrDsp > 0) {
        this.resendCtrDsp--;
      }
      this.otp_timeout = this.otpConfig[0].otp_timeout;
      this.showIntervalBlock = false;

      /*this.otp_interval = setInterval(() => {
        if ( this.otp_timeout > 0) {
            this.otp_timeout--;
            return;
        }
        this.onOTPRemoveBlockInterval();
    } , 1000 );*/

      if (this.forFeedback == true) {

        console.log('forFeedback-Mobile', {
            formValueMobile: this.form.controls["mobile"].value,
            formValueEmail: this.form.controls["email"].value,
            channel
        })

        var uri = this.getOTPURI();
        var name = this.form_cbmRating.controls["contractor_name"].value;
        if (channel === "SMS") {
          this.isSMS = true;
          this.sendOTP_SMS(uri, name);
        } else {
          this.isSMS = false;
          this.sendOTP_Email(uri, name);
        }
      } else if (this.forFeedback == false) {
        // console.log("ENTERED RESEND NONFEEDBACK!");
        if (this.mobileFromRetry === "none" && this.emailFromRetry === "none") {
          //DEFAULT
        //   console.log("RESEND ENTERED DEFAULT");
          const uri = this.getOTPURI();
          
          const refNum = this.form.controls["reference_number"].value;
          if (channel === "SMS") {
            this.isSMS = true;
            this.sendOTP_SMS_track(uri, refNum);
          } else {
            this.isSMS = false;
            this.sendOTP_email_track(uri, refNum);
          }
        } else if (
          this.mobileFromRetry != "none" &&
          this.emailFromRetry === "none"
        ) {
        //   console.log("RESEND ENTERED SMS FROM RETRY");
          const uri = this.mobileFromRetry;
          const refNum = this.form.controls["reference_number"].value;
          this.isSMS = true;
          this.sendOTP_SMS_track_retryModal(uri, refNum);
        } else if (
          this.mobileFromRetry === "none" &&
          this.emailFromRetry != "none"
        ) {
        //   console.log("RESEND ENTERED EMAIL FROM RETRY");
          const uri = this.emailFromRetry;
          const refNum = this.form.controls["reference_number"].value;
          this.isSMS = false;
          this.sendOTP_email_track_retryModal(uri, refNum);
        }
      }
    }
  }
  /*resendOTP() {
   
    const uri = this.getOTPURI();
    const channel = this.getOTPchannel();
    const refNum = this.form.controls['reference_number'].value;
    if ( channel !== 'EMAIL') {
        this.sendOTP_SMS_track(uri, refNum);
    } else {
        this.sendOTP_email_track(uri, refNum);
    }
}*/
  sendOTP_SMS(mobile: string, contractor_name) {
    // console.log("ENTERED SMS CONTRACTOR");
    this.otp_mobile = mobile;
    this.showLoadingPage();
    this.otpRequest = this.otp.createSMSOTP_Feedback(mobile, contractor_name).subscribe(
      (data) => {
        // console.log(
        //   "THIS IS THE OTP FEEDBACK RESPONSE = " + JSON.stringify(data)
        // );
        if (JSON.stringify(data["status"]) === "200") {
        //   console.log("ENTERED SMS 200!! ");
          this.isSMS = true;
          this.isLoading = false;
          this.modal_otp_sms = true;
          if (this.showTrack == true) {
            this.showTrackPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          } else {
            this.showDataPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          }
        }
      },
      (err) => {
        console.log(err);
        this.isTrackButtonClicked = false;
        this.isLoading = false;
        this.isTrackButtonClicked = false;
        this.showDataPage();
      }
    );
  }
  sendOTP_Email(email: string, contractor_name) {
    this.otp_email = email;
    this.showLoadingPage();
    this.otpRequest = this.otp.createEmailOTP_Feedback(email, contractor_name).subscribe(
      (data) => {
        // console.log(data);
        if (JSON.stringify(data["status"]) === "200") {
          this.isSMS = false;
          this.isLoading = false;
          this.modal_otp_email = true;
          if (this.showTrack == true) {
            this.showTrackPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          } else {
            this.showDataPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          }
        }
      },
      (err) => {
        console.log(err);
        this.isTrackButtonClicked = false;
        this.isLoading = false;
        this.isTrackButtonClicked = false;
        this.showDataPage();
      }
    );
  }
  sendOTP_SMS_track(mobile: string, refnum: string) {
    // console.log("PASSED INSIDE NORMAL SEND OTP TRACK");
    this.otp_mobile = mobile;
    this.showLoadingPage();
    this.otpRequest = this.otp.createSMSOTP_View(mobile, refnum).subscribe(
      (data) => {
        // console.log("THIS IS THE OTP TRACK RESPONSE = " + JSON.stringify(data));
        if (JSON.stringify(data["status"]) === "200") {
        //   console.log("HERE 200");
          this.invalid_request = false;
          this.isSMS = true;
          if (this.mobileFromRetry != "none") {
            this.mobileFromRetry = this.mobileFromRetry;
            // console.log("NEW MOBILE = " + this.mobileFromRetry);
            this.isLoading = false;
            this.modal_otp_sms = true;
            // console.log("OTP OK SMS");
            this.showTrackPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          } else {
            this.mobileFromRetry = "none";
            this.isLoading = false;
            this.modal_otp_sms = true;
            // console.log("OTP OK SMS");
            this.showTrackPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          }
        }
      },
      (err) => {
        console.log(err);
        this.invalid_request = true;
        this.isTrackButtonClicked = false;
        this.isLoading = false;
        this.showTrackPage();
        clearInterval(this.otp_interval);
      }
    );
  }

  sendOTP_SMS_track_retryModal(mobile: string, refnum: string) {
    this.otp_mobile = mobile;
    this.showLoadingPage();
    this.otpRequest = this.otp.createSMSOTP_View_Resend(mobile, refnum).subscribe(
      (data) => {
        // console.log(
        //   "THIS IS THE OTP TRACK RETRY RESPONSE = " + JSON.stringify(data)
        // );
        if (JSON.stringify(data["status"]) === "200") {
          this.invalid_request = false;
          this.isSMS = true;
          this.mobileFromRetry = mobile;
          this.isLoading = false;
          this.modal_otp_sms = true;
        //   console.log("OTP OK SMS");
          this.showTrackPage();
          clearInterval(this.otp_interval);
          this.otp_interval = setInterval(() => {
            if (this.otp_timeout > 0) {
              this.otp_timeout--;
              return;
            }
            this.onOTPTimeout();
          }, 1000);
        }
      },
      (err) => {
        console.log(err);
        this.invalid_request = true;
        this.isTrackButtonClicked = false;
        this.isLoading = false;
        this.showTrackPage();
        clearInterval(this.otp_interval);
      }
    );
  }

  sendOTP_email_track(email: string, refnum: string) {
    this.otp_email = email;
    this.showLoadingPage();
    this.otpRequest = this.otp.createEmailOTP_View_Resend(email, refnum).subscribe(
      (data) => {
        if (JSON.stringify(data["status"]) === "200") {
          this.invalid_request = false;
          this.isSMS = false;
          if (this.emailFromRetry != "none") {
            // console.log("HAS ok email retry = " + this.emailFromRetry);
            this.emailFromRetry = this.emailFromRetry;
            // console.log("NEW EMAIL = " + this.emailFromRetry);
            this.isLoading = false;
            this.modal_otp_email = true;
            // console.log("OTP OK EMAIL");
            this.showTrackPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          } else {
            this.emailFromRetry = "none";
            this.isLoading = false;
            this.modal_otp_email = true;
            // console.log("OTP OK EMAIL");
            this.showTrackPage();
            clearInterval(this.otp_interval);
            this.otp_interval = setInterval(() => {
              if (this.otp_timeout > 0) {
                this.otp_timeout--;
                return;
              }
              this.onOTPTimeout();
            }, 1000);
          }
        }
      },
      (err) => {
        console.log(err);
        this.invalid_request = true;
        this.isTrackButtonClicked = false;
        this.isLoading = false;
        this.showTrackPage();
        clearInterval(this.otp_interval);
      }
    );
  }

  sendOTP_email_track_retryModal(email: string, refnum: string) {
    this.otp_email = email;
    this.showLoadingPage();
    this.otpRequest = this.otp.createEmailOTP_View_Resend(email, refnum).subscribe(
      (data) => {
        if (JSON.stringify(data["status"]) === "200") {
          this.invalid_request = false;
          this.isSMS = false;
          this.emailFromRetry = email;
          this.isLoading = false;
          this.modal_otp_email = true;
        //   console.log("OTP OK EMAIL");
          this.showTrackPage();
          clearInterval(this.otp_interval);
          this.otp_interval = setInterval(() => {
            if (this.otp_timeout > 0) {
              this.otp_timeout--;
              return;
            }
            this.onOTPTimeout();
          }, 1000);
        }
      },
      (err) => {
        console.log(err);
        this.invalid_request = true;
        this.isTrackButtonClicked = false;
        this.isLoading = false;
        this.showTrackPage();
        clearInterval(this.otp_interval);
      }
    );
  }
  onOTPAttemptFail() {
    this.isNewCall = false;
    this.otp_attempts++;

    if (this.otp_attempts < this.max_otp_attempts + 1) {
    //   console.log("FAILED TRACK = ", this.otp_attempts);
      if (this.attemptCtrDsp > 0) {
        this.attemptCtrDsp--;
        if (this.attemptCtrDsp === 0) {
          this.onOTPfail();
        }
      }
      if (this.otp_attempts >= this.max_otp_attempts) {
        this.onOTPfail();
      }
    } else if (this.otp_attempts === this.max_otp_attempts + 1) {
    //   console.log("RESET");
      this.otp_attempts = 0;
      this.attemptCtrDsp -= 1;
    }
  }
  closeOTPModals() {
    this.invalid_request = false;
    this.modal_otp_sms = false;
    this.isSMS = false;
    this.modal_otp_email = false;
    this.modal_otp_retry = false;
    this.modal_otp_expiredtimer = false;
  }

  cancelOtp() {
    this.isTrackButtonClicked = false;

    this.mobileFromRetry = "none";
    this.emailFromRetry = "none";

    this.isLoading = false;

    this.passedByExpired = false;
    this.detectBlock = 0;
    this.resendCtr = 0;
    this.isSMS = false;

    this.isNewCall = false;

    this.max_otp_attempts = this.otpConfig[0].otp_attempt_limit;
    this.max_resend_attempts = this.otpConfig[0].otp_resend_limit;
    this.otp_attempts = 0;
    this.resend_attempts = 0;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;

    this.smsOrEmailValRetry = this.getOTPURI();
    if (this.getOTPchannel() === "SMS") {
      this.isSMS = true;
    } else {
      this.isSMS = false;
    }

    clearInterval(this.otp_interval);
    clearInterval(this.otp_resend_interval);
    this.showIntervalBlock = false;
    this.closeOTPModals();
  }

  onOTPfail() {
    this.passedByExpired = false;
    this.detectBlock = 0;
    this.resendCtr = 0;
    this.isSMS = false;

    this.max_otp_attempts = this.otpConfig[0].otp_attempt_limit;
    this.max_resend_attempts = this.otpConfig[0].otp_resend_limit;
    this.otp_attempts = 0;
    this.resend_attempts = 0;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;

    this.smsOrEmailValRetry = this.getOTPURI();
    if (this.getOTPchannel() === "SMS") {
      this.isSMS = true;
    } else {
      this.isSMS = false;
    }

    clearInterval(this.otp_interval);
    clearInterval(this.otp_resend_interval);
    this.showIntervalBlock = false;
    this.closeOTPModals();

    this.retryOTP();
  }

  onOTPRemoveBlockInterval() {
    this.passedByExpired = false;
    // console.log("ENTEREDD BLOCK INTERVAL TRACK");
    this.detectBlock = 0;
    this.resendCtr = 0;
    //clearInterval(this.intervalTimerVal);
    clearInterval(this.otp_interval);
    clearInterval(this.otp_resend_interval);
    this.intervalTimerVal = this.otpConfig[0].otp_resend_limit_duration;
    this.showIntervalBlock = false;

    this.closeOTPModals();

    //this.startOTP();
    this.otp_expired_default = false;
    this.otp_expired_interval = true;

    if (this.getOTPchannel() === "SMS") {
    //   console.log("IS SMS BLOCK TRUE");
      this.isSMS = true;
      this.modal_otp_expiredtimer = true;
    } else {
    //   console.log("IS SMS BLOCK FALSE");
      this.isSMS = false;
      this.modal_otp_expiredtimer = true;
    }
  }

  enableIdleAction(
    isEnabled: string //overrides current timers with the idle timer in otpsms
  ) {
    if (this.showIntervalBlock == true) {
      this.onOTPRemoveBlockInterval();
    }
  }

  expiredOTPIdle() {
    // console.log("OTP CHANNEL IDLE EXPIRED = ", this.getOTPchannel());

    this.passedByExpired = true;

    this.otp_attempts++;
    if (this.attemptCtrDsp > 0) {
      this.attemptCtrDsp--;
    }

    if (this.attemptCtrDsp === 0) {
      this.onOTPfail();
    } else {
      if (this.getOTPchannel() === "SMS") {
        this.detectBlock = 0;
        this.resendCtr = 0;
        this.modal_otp_sms = false;

        this.isSMS = true;
        this.otp_expired_default = true;
        this.otp_expired_interval = false;
        this.modal_otp_expiredtimer = true;
      } else {
        this.detectBlock = 0;
        this.resendCtr = 0;
        this.modal_otp_email = false;

        this.isSMS = false;
        this.otp_expired_default = true;
        this.otp_expired_interval = false;
        this.modal_otp_expiredtimer = true;
      }
    }
  }

  expiredOTP() {
    // console.log("PASSED EXPIRED OTP MAIN");

    // console.log("PASSED EXPIRED with attemptCTR = ", this.attemptCtrDsp);

    this.mobileFromRetry = "none";
    this.emailFromRetry = "none";

    if (this.attemptCtrDsp === 0) {
      this.onOTPfail();
    } else {
      if (this.getOTPchannel() === "SMS") {
        this.isSMS = true;

        this.detectBlock = 0;
        this.resendCtr = 0;
        clearInterval(this.otp_interval);

        this.otp_expired_default = true; //3 mins timer expired
        this.otp_expired_interval = false; //1 hr timer expired

        this.modal_otp_expiredtimer = true;
      } else {
        this.isSMS = false;

        this.detectBlock = 0;
        this.resendCtr = 0;
        clearInterval(this.otp_interval);

        this.otp_expired_default = true; //3 mins timer expired
        this.otp_expired_interval = false; //1 hr timer expired

        this.modal_otp_expiredtimer = true;
      }
    }
  }

  otpClearAll() {
    this.detectBlock = 0;
    this.showIntervalBlock = false;

    clearInterval(this.otp_interval);

    this.otp_attempts = 0; //this.otpConfig[0].otp_attempt_limit;
    this.resend_attempts = 0; //this.otpConfig[0].otp_resend_limit;

    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.resendCtrDsp = this.otpConfig[0].otp_resend_limit;

    this.closeOTPModals();
  }

  onOTPTimeout() {
    /*clearInterval(this.otp_interval);
    this.closeOTPModals();
    this.expiredOTP();*/
    // console.log("PASSED OTP TIMEOUT with attemptCTR = ", this.attemptCtrDsp);
    this.isNewCall = false;
    clearInterval(this.otp_interval);
    this.closeOTPModals();

    this.otp_attempts++;
    if (this.attemptCtrDsp > 0) {
      this.attemptCtrDsp--;
    }

    this.expiredOTP();
  }
  onOTPSuccess(key: string) {
    // console.log("PASSED OTP SUCCESS");
    this.isTrackButtonClicked = false;

    this.mobileFromRetry = "none";
    this.emailFromRetry = "none";

    this.passedByExpired = false;
    this.isNewCall = false;
    this.api_key = key;
    clearInterval(this.otp_interval);
    this.closeOTPModals();
    if (this.forFeedback) {
      this.showRatingPage();
    } else {
      this.showLoadingPage();
      this.trackSR(this.api_key);
      this.tracePrevSRStatus(this.form.controls["reference_number"].value);
    }
  }
  retryOTP() {
    // console.log("GENERAL RETRY");
    this.resetDefaultConfigs();
    if (this.getOTPchannel() === "SMS") {
      this.modal_otp_retry = true;
      this.isSMS = true;
    //   console.log("IS SMS SMS = " + this.isSMS);
    } else {
      this.modal_otp_retry = true;
      this.isSMS = false;
    //   console.log("IS SMS EMAIL = " + this.isSMS);
    }
  }

  retryEmailOTP(val: string) {
    /*console.log("EMAIL RETRY")
    
    this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
        this.isNewCall = true;
        this.closeOTPModals();
        this.sendOTP('EMAIL', val);*/

    // console.log("ENTERED retry email otp" + val);
    //this.otp_attempts = 0;
    //this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.passedByExpired = false;
    this.isNewCall = true;
    this.closeOTPModals();

    this.resendCtr += 1;
    if (val != "") {
    //   console.log("EMAIL ENTER WITH VAL PASSED");
      this.smsOrEmailValRetry = val;
      this.sendOTPFromRetryModal("EMAIL", this.smsOrEmailValRetry);
    } else {
    //   console.log("EMAIL ENTER NO VAL PASSED");
      this.smsOrEmailValRetry = this.getOTPURI();
      this.sendOTP("EMAIL", this.smsOrEmailValRetry);
    }
    //this.sendOTP('EMAIL', this.smsOrEmailValRetry);
  }

  retrySMSOTP(val: string) {
    /*console.log("SMS RETRY")
   this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.isNewCall = true;
    this.closeOTPModals();
    this.sendOTP('SMS', val);*/

    // console.log("ENTERED retry sms otp" + val);
    //this.attemptCtrDsp = this.otpConfig[0].otp_attempt_limit;
    this.passedByExpired = false;
    this.isNewCall = true;
    this.closeOTPModals();

    this.resendCtr += 1;
    if (val != "") {
      this.smsOrEmailValRetry = val; //this.getOTPURI();
      this.sendOTPFromRetryModal("SMS", this.smsOrEmailValRetry);
    } else {
      this.smsOrEmailValRetry = this.getOTPURI();
      this.sendOTP("SMS", this.smsOrEmailValRetry);
    }
  }

  showMessageDialog(title: string, message: string) {
    this.modal_info = true;
    this.info_title = title;
    this.info_message = message;
  }
}
