import {Component, EventEmitter, HostListener, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OtpProviderService} from '../../shared/services/otp-provider.service';
import {MAT_DATE_LOCALE} from '@angular/material/core';

import dataOTP from '../../../../src/assets/json/otp_config.json';

export interface OTPMain {

   data: [{ config: OTPConfig[], title: OTPTitleMsg[], feedback: OTPFeedbackMsgs[],  nonfeedback: OTPNonFeedbackMsgs[],  failed: OTPFailedMsgs[], interval: OTPIntervalMsgs[], noCode: OTPNoCodeMsg[], cancel: OTPCancelledMsg[] }]

}

export interface OTPConfig {
  otp_timeout: number,
  otp_attempt_limit: number,
  otp_resend_limit: number,
  otp_resend_limit_duration: number
}

export interface OTPTitleMsg {
  otp_title_sms: string,
  otp_title_email: string
}

export interface OTPFeedbackMsgs {
  otp_feedback_msg_1: string,
  otp_feedback_msg_2: string,
  otp_feedback_msg_3_sms: string,
  otp_feedback_msg_3_email: string,
  otp_feedback_msg_4: string, 
  otp_feedback_msg_5: string
}

export interface OTPNonFeedbackMsgs {
  otp_nonfeedback_msg_0: string,
  otp_nonFeedback_msg_1: string,
  otp_nonFeedback_msg_2_sms: string,
  otp_nonFeedback_msg_2_email: string,
  otp_nonFeedback_msg_3: string,
  otp_nonFeedback_msg_4: string
}

export interface OTPFailedMsgs {
  otp_failed_msg_1: string,
  otp_failed_msg_2: string,
  otp_failed_msg_3: string
}

export interface OTPIntervalMsgs {
  otp_interval_block_msg_1: string,
  otp_interval_block_msg_05: string,
  otp_interval_block_msg_2: string
}

export interface OTPNoCodeMsg {
  otp_no_code_msg: string,
  otp_no_code_msg2: string
}

export interface OTPCancelledMsg {
  otp_cancel_msg: string
}

@Component({
  selector: 'otp-sms',
  templateUrl: './otp-sms.component.html',
  styleUrls: ['./otp-sms.component.scss']
})

export class OtpSmsComponent implements OnInit {

  //OTP SETTINGS
  //otpMain: OTPMain[] = dataOTP[0].data;
  otpConfig: OTPConfig[] = dataOTP[0].data.config;
  otpTitleMsgs: OTPTitleMsg[] = dataOTP[0].data.title;
  otpFeedbackMsgs: OTPFeedbackMsgs[] = dataOTP[0].data.feedback;
  otpNonFeedbackMsgs: OTPNonFeedbackMsgs[] = dataOTP[0].data.nonfeedback;
  otpFailedMsgs: OTPFailedMsgs[] = dataOTP[0].data.failed;
  otpIntervalMsgs: OTPIntervalMsgs[] = dataOTP[0].data.interval;
  otpNoCodeMsgs: OTPNoCodeMsg[] = dataOTP[0].data.noCode;
  otpCancelMsgs: OTPCancelledMsg[] = dataOTP[0].data.cancel;

  tempTime: string = '';
  oldSeconds: number = 0;
  oldSecondsDefault: number = 0;
  
  updatedSeconds: number = 0;
  fromIdle = 0;

otpTitleMsgSMS: string = "";

otpFeedbackMsg1: string = "";
otpFeedbackMsg2: string = "";
otpFeedbackMsg3: string = "";
otpFeedbackMsg4: string = "";
otpFeedbackMsg5: string = "";

otpNonFeedbackMsg0: string = "";
otpNonFeedbackMsg1: string = "";
otpNonFeedbackMsg2: string = "";
otpNonFeedbackMsg3: string = "";
otpNonFeedbackMsg4: string = "";

otpFailedMsg1: string = "";
otpFailedMsg2: string = "";
otpFailedMsg3: string = "";

otpIntervalMsg1: string = "";
otpIntervalMsg05: string = "";
otpIntervalMsg2: string = "";

otpNoCodeMsg: string = "";
otpNoCodeMsg2: string = "";

otpCancelMsg: string = "";

timeNew: string = "";
 

@HostListener('window:focus', ['$event']) onFocus(event: any): void {
  // Do something
  // console.log("ENTERED ONFOCUS");

  //this.isActiveWindow = false;
  //this.fromIdle += 1;

  //this.idleDate = new Date().toTimeString();
  //console.log("IDLE ONINIT = ", this.idleDate);

  /*this.idleInterval = setInterval(() => {
    if ( this.idleIntervalVal > 0) {
        this.idleIntervalVal++;
        return;
    }
  })*/
 
  //this.startTimer();
}

/*@HostListener('window:blur', ['$event']) onBlur(event: any): void {
  // Do something
  console.log("ENTERED ONBLUR SMS");

  //this.isActiveWindow = false;
  //this.fromIdle += 1;

  //this.idleDate = new Date().toTimeString();
  //console.log("IDLE ONINIT = ", this.idleDate);

  this.idleInterval = setInterval(() => {
    if ( this.idleIntervalVal > 0) {
        this.idleIntervalVal++;
        
    }
  })


}*/
 
  @Input() isRenewedCall: boolean = false;
  @Input() alternative_contact: string = '';
  @Input() message: string = '';
  @Input() visible: boolean;
  @Input() mobile: string = '';
  @Input() timeout: number = 0;
  @Input() attempts: number = 0;
  @Input() max_attempts: number = 0;
  @Input() resend_attempts: number = 0;
  @Input() max_resend_attempts: number = 0;
  @Input() display_resend_ctr: number = 0;
  @Input() display_attempt_ctr: number = 0;
  @Input() showIntervalBlock: boolean = false;
  @Input() resetMainTimer: boolean = false;
  @Input() intervalTimer: number = 0;
  @Input() forFeedback: boolean = false;
  @Input() ongoingIntervalBlock: number = 0;
  @Output() idleTimerEnabled: EventEmitter<string> = new EventEmitter();
  @Output() onRestartTimer: EventEmitter <boolean> = new EventEmitter();
  @Output() onSubmit: EventEmitter <string>  = new EventEmitter();
  @Output() onDismiss: EventEmitter <string>  = new EventEmitter();
  @Output() onSuccess: EventEmitter <string>  = new EventEmitter();
  @Output() onFail: EventEmitter <string>  = new EventEmitter();
  @Output() onCancel: EventEmitter <string>  = new EventEmitter();
  @Output() onResend: EventEmitter <string>  = new EventEmitter();
  form: UntypedFormGroup;
  isLoading: boolean = false;
  failedAttempt: boolean = false;
  modal_otp: boolean = true;
  modal_confirmCancel: boolean = false;
  http: any;
  
  isActiveWindow: boolean = false;
  resendInterval: any;
  resendIntervalVal: number;
  
  initialDate : Date; //= '';
  idleDate: Date; //= '';
  currentDate: Date; //= '';

  initDateDef: Date; //= '';
  currDateDef: Date; //= '';

  initDateRes: Date;
  currDateRes: Date;

  firstView: number = 0;
  ctrResend: number = 0;

  constructor(private builder: UntypedFormBuilder, private otp: OtpProviderService, private cdr: ChangeDetectorRef) {
    this.initForm();
  
  }
  
  ngOnInit() {
    // console.log("BAM - start of OTP SMS TIMER MODAL");

    this.oldSeconds = Number(localStorage.getItem('hourTimerInterval'));
    this.oldSecondsDefault = Number(localStorage.getItem('defaultTimerInterval'));



  //this.startTimer();
  //this.initialDate = new Date().toTimeString();
   // console.log("INITIAL DATE ONINIT = ", this.initialDate);

this.failedAttempt = false;

//OTP MSGS
this.otpTitleMsgSMS = this.otpTitleMsgs[0].otp_title_sms;
this.otpFeedbackMsg1 = this.otpFeedbackMsgs[0].otp_feedback_msg_1;
this.otpFeedbackMsg2 = this.otpFeedbackMsgs[0].otp_feedback_msg_2; 
this.otpFeedbackMsg3 = this.otpFeedbackMsgs[0].otp_feedback_msg_3_sms;
this.otpFeedbackMsg4 = this.otpFeedbackMsgs[0].otp_feedback_msg_4;
this.otpFeedbackMsg5 = this.otpFeedbackMsgs[0].otp_feedback_msg_5;

this.otpNonFeedbackMsg0 = this.otpNonFeedbackMsgs[0].otp_nonfeedback_msg_0;
this.otpNonFeedbackMsg1 = this.otpNonFeedbackMsgs[0].otp_nonFeedback_msg_1;
this.otpNonFeedbackMsg2 = this.otpNonFeedbackMsgs[0].otp_nonFeedback_msg_2_sms;
this.otpNonFeedbackMsg3 = this.otpNonFeedbackMsgs[0].otp_nonFeedback_msg_3;
this.otpNonFeedbackMsg4 = this.otpNonFeedbackMsgs[0].otp_nonFeedback_msg_4;

this.otpFailedMsg1 = this.otpFailedMsgs[0].otp_failed_msg_1;
this.otpFailedMsg2 = this.otpFailedMsgs[0].otp_failed_msg_2;
this. otpFailedMsg3 = this.otpFailedMsgs[0].otp_failed_msg_3;

this.otpIntervalMsg1 = this.otpIntervalMsgs[0].otp_interval_block_msg_1;
this.otpIntervalMsg05 = this.otpIntervalMsgs[0].otp_interval_block_msg_05;
this.otpIntervalMsg2 = this.otpIntervalMsgs[0].otp_interval_block_msg_2

this.otpNoCodeMsg = this.otpNoCodeMsgs[0].otp_no_code_msg;
this.otpNoCodeMsg2 = this.otpNoCodeMsgs[0].otp_no_code_msg2;

this.otpCancelMsg = this.otpCancelMsgs[0].otp_cancel_msg;


  }

  initForm() {
    this.form = this.builder.group({ otp: ['', [ Validators.required ,
                                                               Validators.pattern('^[0-9]{6}$')]
                                                        ]});
  }

  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }

/*startTimerInterval()
{
  if(this.showIntervalBlock == true)
  {

  if(this.firstView > 0)
   {
    console.log("ENTERED FIRST VIEW")
    this.failedAttempt = false;
    var initDate1 = new Date(); //.toTimeString();
    this.initialDate = initDate1;
    this.firstView = 0;
   }

   if(this.visible == true)
   {

     this.currentDate = new Date();
   
     console.log("CURR TIME = ", this.currentDate);
     console.log("INIT DATE = ", this.initialDate);
     
     
     var diff = (this.currentDate.getTime() / 1000) - (this.initialDate.getTime() /1000 )
     //diff /= (60 * 60);
     this.updatedSeconds = Math.abs(Math.round(diff));
    
    //this.updatedSeconds = diff;
      console.log("UPDATED SECONDS REAL SMS = ", this.updatedSeconds);
     if(this.updatedSeconds >= this.oldSeconds)
     {
       console.log("CALL IDLE TIMER TS!!!");
       this.visible = false;
       this.enabledIdleTimer();
     }
    }
  }
}*/

  /*startTimer()
  {

    console.log("start timer!!!")
    if(this.visible == true)
  {
    console.log("ENTRERED VISIBLE")
    this.isActiveWindow = true;

     
   if(this.firstView == 0)
   {
    console.log("ENTERED FIRST VIEW")
    this.failedAttempt = false;
    var initDate1 = new Date(); //.toTimeString();
    this.initialDate = initDate1;
    this.firstView += 1;
   }

    this.currentDate = new Date();//.toTimeString();
  
    console.log("CURR TIME = ", this.currentDate);
    console.log("INIT DATE = ", this.initialDate);
    
    if(this.showIntervalBlock == true || this.ongoingIntervalBlock >= 1)
    {
      console.log("ENTERED IDLE TIMER INTERVAL");
  
     // this.initialDate = localStorage.getItem('firstView');
      //var i = this.initialDate.split(':');
      //var c = this.currentDate.split(':');
      //var id = this.idleDate.split(':');
      

      //var newInitDate = (parseInt(i[0])) * 60 * 60 + (parseInt(i[1])) * 60 + (parseInt(i[2].slice(0,2))); 
      //var newCurrDate = (parseInt(c[0])) * 60 * 60 + (parseInt(c[1])) * 60 + (parseInt(c[2].slice(0,2))); 

      

     //var newInitDate = (parseInt(i[1])) * 60 + (parseInt(i[2].slice(0,2)));
     //var newCurrDate = (parseInt(c[1])) * 60 + (parseInt(c[2].slice(0,2)));
    //var newIdleDate = (parseInt(id[1])) * 60 + (parseInt(id[2].slice(0,1)));
      console.log("INIT SEC = " + this.initialDate);
      //console.log("IDLE SEC = " + newIdleDate);
      console.log("CURR SEC = " + this.currentDate);

      //if(newInitDate == newCurrDate)
      //{
      //  console.log("NOTHING HAPPENS YET")
      
     // }
     
      //this.updatedSeconds = newCurrDate - newInitDate;

      var diff =(this.currentDate.getTime() - this.initialDate.getTime()) / 1000;
      diff /= (60 * 60);
      this.updatedSeconds = Math.abs(Math.round(diff));

      if(this.updatedSeconds >= this.oldSeconds)
      {
        this.enabledIdleTimer();
      }
      
      this.formatTimeInterval(this.updatedSeconds, 1);
      
    }
    else if(this.resetMainTimer == false && this.showIntervalBlock == false)
    {
      console.log("ENTERED IDLE TIMER DEFAULT");
  
      //var i = this.initialDate.split(':');
      //var c = this.currentDate.split(':');
      //var id = this.idleDate.split(':');

      //console.log("INIT DATE FIRST = " + i);
      //var newInitDate = (parseInt(i[1])) * 60 + (parseInt(i[2].slice(0,2)));
      //var newCurrDate = (parseInt(c[1])) * 60 + (parseInt(c[2].slice(0,2)));
      //var newIdleDate = (parseInt(id[1])) * 60 + (parseInt(id[2].slice(0,1)));


      console.log("INIT SEC = " + this.initialDate);
      //console.log("IDLE SEC = " + newIdleDate);
      console.log("CURR SEC = " + this.currentDate);
      //console.log("NEW - INIT = ", newCurrDate - newInitDate);

      //if(newInitDate == newCurrDate)
     // {
      //  console.log("NOTHING HAPPENS YET")
      //}
      
      //this.updatedSeconds = newCurrDate - newInitDate;

      var diff =(this.currentDate.getTime() - this.initialDate.getTime()) / 1000;
      diff /= (60 * 60);
      this.updatedSeconds = Math.abs(Math.round(diff));

      if(this.updatedSeconds >= this.oldSecondsDefault)
      {
        console.log("CALL IDLE TIMER TS!!!")
        this.enabledIdleTimer();
      }
      else
      {
        this.formatTime(this.updatedSeconds, 1);
      }
      
      
    }
    else if(this.resetMainTimer == true && this.showIntervalBlock == false)
    {
      console.log("ENTERED IDLE TIMER RESEND");
  
     // var i = this.initialDate.split(':');
     // var c = this.currentDate.split(':');
      //var id = this.idleDate.split(':');

      //var newInitDate = (parseInt(i[1])) * 60 + (parseInt(i[2].slice(0,2)));
      //var newCurrDate = (parseInt(c[1])) * 60 + (parseInt(c[2].slice(0,2)));
      //var newIdleDate = (parseInt(id[1])) * 60 + (parseInt(id[2].slice(0,1)));

      console.log("INIT SEC = " + this.initialDate);
      //console.log("IDLE SEC = " + newIdleDate);
      console.log("CURR SEC = " + this.currentDate);
  
      //if(newInitDate == newCurrDate)
      //{
      //  console.log("NOTHING HAPPENS YET")
      //}

      var diff =(this.currentDate.getTime() - this.initialDate.getTime()) / 1000;
      diff /= (60 * 60);
      this.updatedSeconds = Math.abs(Math.round(diff));
      //this.updatedSeconds = newCurrDate - newInitDate;

      if(this.updatedSeconds >= this.oldSecondsDefault)
      {
        this.enabledIdleTimer();
      }
      
      this.formatTimeResend(this.updatedSeconds, 1);
  
    }

  }

  }*/

  viewText(msg: string)
  {
    // console.log("STRING IS = ", msg);
    return `${msg}`;
  }

  viewMsgs(msg: string)
  {
    console.log("MSG IS = ", msg);
    return `${msg}`;
  }

  // API
  validateOTP(otp: string) {
    this.setEnable(false);
    this.otp
        .verifySMSOTP(this.mobile, otp)
        .subscribe(
            data  => {
              this.setEnable(false);
              if ( data['status'] !== 200) {
                this.onValidateFail();
                return;
              }
              const res = data['body'];
              if (!res.hasOwnProperty('api_key')) {
                this.onValidateFail();
                return;
              }
              const api_key = res['api_key'];
              this.onValidateSuccess(api_key);
            },
            error => {
              this.onValidateFail();
            }
        );
  }
  resendOTP() {

   /*if(this.showIntervalBlock === false)
    {
      this.onResend.emit();
    }
    return;*/

    this.onResend.emit();
  
  }
  // ---------
  formatNumber(mobile: string) {
    if(this.alternative_contact === 'none')
    {
      if (this.mobile.length !== 13) {
        return '--';
      }
     return this.mobile.substr(9, 4);
    }
    else
    {
      if (this.alternative_contact.length !== 13) {
        return '--';
      }
     return this.alternative_contact.substr(9, 4);
    }
   
  }


  formatTime(nmber: number, notHTML : number) {  
   /*if(notHTML === 0)
   {
      const min = Math.trunc( nmber / 60);
      const sec = nmber % 60;
      this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
      //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

      return `${min}:${sec < 10 ? '0' + sec : sec}`    
   }
   else
   {
    //nmber = this.oldSecondsDefault - this.updatedSeconds;
    const min = Math.trunc( nmber / 60);
    const sec = nmber % 60;
    this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
    //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

    return `${min}:${sec < 10 ? '0' + sec : sec}`    
   }*/

  //  console.log("ENTERED IDLE TIMER DEFAULT");
  
  if(this.visible == true)
  {
    // console.log("ENTRERED VISIBLE")
    this.isActiveWindow = true;
    
    
   if(this.firstView == 0)
   {
    // console.log("ENTERED FIRST VIEW")
    this.failedAttempt = false;
    //var initDate1: Date = new Date();//.toTimeString();
    //this.initDateDef = initDate1;
    this.firstView += 1;
   }

    /*this.currDateDef = new Date();//.toTimeString();
    console.log("INIT SEC = " + this.initDateDef);
    console.log("CURR SEC = " + this.currDateDef);*/
///-----------------
//THIS IS OLDER CODE
    /*var i = this.initDateDef.split(':');
    var c = this.currDateDef.split(':');

    var newInitDate = (parseInt(i[1])) * 60 + (parseInt(i[2].slice(0,2)));
    var newCurrDate = (parseInt(c[1])) * 60 + (parseInt(c[2].slice(0,2)));

    if(newInitDate == newCurrDate)
   {
    console.log("NOTHING HAPPENS YET")
   }


   console.log("CURR SEC = " + this.currDateDef);
   this.updatedSeconds = newCurrDate - newInitDate;*/

//---------------------
   /*var diff = (this.currDateDef.getTime() / 1000) - (this.initDateDef.getTime() /1000 )
   //diff /= (60 * 60);
   this.updatedSeconds = Math.abs(Math.round(diff));

   console.log("UPDATED SECS = " + this.updatedSeconds);
   if(this.updatedSeconds >= this.oldSecondsDefault)
   {
     console.log("CALL IDLE TIMER TS!!!")
     this.enabledIdleTimer();
   }
   else
   {
 
    var min = Math.trunc( (this.oldSecondsDefault - this.updatedSeconds) / 60);
    var sec = Math.trunc( (this.oldSecondsDefault - this.updatedSeconds) % 60);
    this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
    //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

    return `${min}:${sec < 10 ? '0' + sec : sec}`    
   }
  }*/

  /*const min = Math.trunc((this.oldSecondsDefault -(this.oldSecondsDefault - nmber))  / 60);
  const sec = Math.trunc((this.oldSecondsDefault -(this.oldSecondsDefault - nmber)) % 60);
  this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
  //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

  if(min == 0)
  {
    this.enabledIdleTimer();
  }

  return `${min}:${sec < 10 ? '0' + sec : sec}`    */


  this.updatedSeconds = nmber;
  // console.log("UPDATED SECS RESEND = ", this.updatedSeconds);
  this.updatedSeconds-=1;

  if(this.updatedSeconds == 0)
  {
    console.log("CALL IDLE TIMER TS!!!")
    this.enabledIdleTimer();
  }
  else
  {

   var min = Math.trunc( (this.updatedSeconds) / 60);
   var sec = Math.trunc( (this.updatedSeconds) % 60);
   this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
   //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

   return `${min}:${sec < 10 ? '0' + sec : sec}`    
  }
  }

  }

  formatTimeResend(nmber: number, notHTML: number)
  {
    /*const min = Math.trunc( nmber / 60);
       const sec = nmber % 60;
       this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
       //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;
 
       return `${min}:${sec < 10 ? '0' + sec : sec}`*/
   
   
    if(this.visible == true && this.modal_otp == true)
    {
       
        this.isActiveWindow = true;
        if(this.firstView == 1)
        {
         console.log("ENTERED FIRST VIEW RESEND")
         this.failedAttempt = false;
         this.firstView += 1;
        }


       /* const min = Math.trunc((this.oldSecondsDefault -(this.oldSecondsDefault - nmber))  / 60);
        const sec = Math.trunc((this.oldSecondsDefault -(this.oldSecondsDefault - nmber)) % 60);
        this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
    
        if(min == 0)
        {
          this.enabledIdleTimer();
        }

        return `${min}:${sec < 10 ? '0' + sec : sec}`    */

   this.updatedSeconds = nmber;
  //  console.log("UPDATED SECS RESEND = ", this.updatedSeconds);
   this.updatedSeconds-=1;

   if(this.updatedSeconds == 0)
   {
     console.log("CALL IDLE TIMER TS!!!")
     this.enabledIdleTimer();
   }
   else
   {

    var min = Math.trunc( (this.updatedSeconds) / 60);
    var sec = Math.trunc( (this.updatedSeconds) % 60);
    this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
    //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

    return `${min}:${sec < 10 ? '0' + sec : sec}`    
   }
      
    }
}

  formatTimeInterval(nmber: number, notHTML) {
   /*if(notHTML === 0)
   {
      console.log("ONE HOUR HTML")
      const min = Math.trunc( nmber / 60);
      const sec = nmber % 60;
      this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
      //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

      return `${min}:${sec < 10 ? '0' + sec : sec}`    
   }
   else
   {
    console.log("ONE HOUR STARTTIMER")
    nmber = this.oldSeconds - this.updatedSeconds;
    const min = Math.trunc( nmber / 60);
    const sec = nmber % 60;
    this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
    //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;

    return `${min}:${sec < 10 ? '0' + sec : sec}`    
   }*/

   if(this.visible == true && this.modal_otp == true)
   {

    console.log("FIRST VIEW = " + this.firstView);

    if(this.firstView > 0)
    {
     console.log("ENTERED FIRST VIEW")
     this.failedAttempt = false;
     var initDate1 = new Date(); //.toTimeString();
     this.initialDate = initDate1;
     this.firstView = 0;
    }

     this.currentDate = new Date();
   
     console.log("CURR TIME = ", this.currentDate);
     console.log("INIT DATE = ", this.initialDate);
     
     
     var diff = (this.currentDate.getTime() / 1000) - (this.initialDate.getTime() /1000 )
     //diff /= (60 * 60);

     this.updatedSeconds = Math.abs(Math.round(diff));
    
    //this.updatedSeconds = diff;
      console.log("UPDATED SECONDS REAL SMS = ", this.updatedSeconds);
     if(this.updatedSeconds >= this.oldSeconds)
     {
       console.log("CALL IDLE TIMER TS!!!");
       this.visible = false;
       this.enabledIdleTimer();
     }
     else
     {
      console.log("CALL NORMAL 1hr TIMER");
      //var newNnmber = nmber;
      //this.formatTime(this.updatedSeconds, 1);
      var newMins = Math.trunc((this.oldSeconds - this.updatedSeconds) / 60);
      var newSec = Math.trunc( (this.oldSeconds - this.updatedSeconds) % 60);
      //var newSec = nmber % 60;

      //var min =  Math.trunc( nmber * 60);
      //var sec = (nmber - this.updatedSeconds) % 60;
      this.tempTime = '00:00';//`${iNew}:${sNew < 10 ? '0' + sNew : sNew}`
      //this.timeSaved = `${min}:${sec < 10 ? '0' + sec : sec}`;
      
      return `${newMins}:${newSec < 10 ? '0' + newSec : newSec}`  
     }
   }

  }


  submit() {
    const val = this.form.controls['otp'].value;
    this.onSubmit.emit(val);
    this.validateOTP(val);
  }
  confirmCancel() {
      this.modal_confirmCancel = true;
      this.modal_otp = false;
  }
  rejectCancel() {
      this.modal_confirmCancel = false;
      this.modal_otp = true;
  }
  cancel() {
    clearInterval(this.resendInterval);
    this.resendIntervalVal = 0;
    this.fromIdle = 0;
    this.firstView = 0;
    this.updatedSeconds = 0;

      this.failedAttempt = false;
      this.display_attempt_ctr = 0;
      this.modal_confirmCancel = false;
      this.modal_otp = true;
      this.onCancel.emit();
  }

  setEnable(enable: boolean) {
    this.isLoading = !enable;
    if (enable) {
      this.form.controls['otp'].enable();
    } else {
      this.form.controls['otp'].disable();
    }
  }

  enabledIdleTimer()
  {
    this.visible = false;
    this.initialDate = null;
    this.currentDate = null;

    this.fromIdle = 0;
    this.firstView = 0;
    this.updatedSeconds = 0;
    this.resendIntervalVal = 0;
    this.currDateDef = null;
    this.initDateDef = null;
    this.currDateRes = null;
    this.initDateRes = null;
    this.idleTimerEnabled.emit('true');
  }

  onValidateFail() {
    clearInterval(this.resendInterval);
    this.resendIntervalVal = 0;
    
    /*if(this.firstView >= 2)
    {
      this.firstView = 0;
    }*/

    this.fromIdle = 0;
    this.updatedSeconds = 0;

    this.failedAttempt = true;
    this.form.controls['otp'].setValue('');
    this.setEnable(true);
    this.onFail.emit();
  }
  onValidateSuccess(key: string) {
    clearInterval(this.resendInterval);
    this.resendIntervalVal = 0;
    
     this.fromIdle = 0;
    this.firstView = 0;
    this.updatedSeconds = 0;

    this.form.controls['otp'].setValue('');
    this.onSuccess.emit(key);
    this.setEnable(true);
  }

  


}
