import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class HttpProviderService {

  constructor(private http: HttpClient, private providerPath: string) {}

  sendGetRequest(path: string, headers: HttpHeaders, params: HttpParams) {
    headers = headers.append('x-api-key',
                             'Whi9QqIyLnasPChPwRqgFKdRdpUslxJ54MK72VZ0');
    const requestOptions = {
      headers: headers,  // headers.append('Authorization', JSON.stringify(localStorage.getItem('token'))),
      params: params,
      observe: 'response' as 'body',
    };
    return this.http.get(`${this.providerPath}${path}`, requestOptions);
  }
  //rodel
  sentGetRequestServiceList(refNum: string, headers: HttpHeaders, params: HttpParams) {
    headers = headers.append('x-api-key',
    'ISBNUmi5ksDjl2fuznjTpaar8L8hAoawLCGzws70');

        const requestOptions = {
        headers: headers,
        observe: "response" as 'body',
        params: params
        };
        return this.http.get(`${this.providerPath}${refNum}`, requestOptions);
  }
  sendPostRequestOTP(path: string, body: any, headers: HttpHeaders, params: HttpParams)
  {
    //headers = headers.append('x-api-key', 'ISBNUmi5ksDjl2fuznjTpaar8L8hAoawLCGzws70');
   
    const requestOptions = {
      headers: headers.append('x-api-key', 'ISBNUmi5ksDjl2fuznjTpaar8L8hAoawLCGzws70'),
      observe: "response" as 'body',
      params: params
    };

    return this.http.post(`${this.providerPath}${path}`, body, requestOptions);
  }

  sendPostRequest(path: string, body: any, headers: HttpHeaders, params: HttpParams) {

    headers = headers.append('x-api-key', 'ISBNUmi5ksDjl2fuznjTpaar8L8hAoawLCGzws70');
    
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };

    return this.http.post(`${this.providerPath}${path}`, body, requestOptions);
  }
  sendPutRequest(path: string, body: any, headers: HttpHeaders, params: HttpParams) {
    headers = headers.append('x-api-key',
                             'ISBNUmi5ksDjl2fuznjTpaar8L8hAoawLCGzws70');
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.put(`${this.providerPath}${path}`, body, requestOptions);
  }
  sendPatchRequest(path: string, body: any, headers: HttpHeaders, params: HttpParams) {
    headers = headers.append('x-api-key',
                             'ISBNUmi5ksDjl2fuznjTpaar8L8hAoawLCGzws70');
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.patch(`${this.providerPath}${path}`, body, requestOptions);
  }
  sendDeleteRequest(path: string, headers: HttpHeaders, params: HttpParams) {
    headers = headers.append('x-api-key',
                             'ISBNUmi5ksDjl2fuznjTpaar8L8hAoawLCGzws70');
    const requestOptions = {
      headers: headers,
      observe: "response" as 'body',
      params: params
    };
    return this.http.delete(`${this.providerPath}${path}`, requestOptions);
  }
}


@Injectable({
  providedIn: 'root'
})

export class MemberProviderService extends HttpProviderService {
  constructor(private providerHttp: HttpClient) {
    super(providerHttp, environment.hostURL + '/admin');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataProviderService extends HttpProviderService {
  constructor(private providerHttp: HttpClient) {
    super(providerHttp, environment.hostURL+'/reference');
  }
}

@Injectable({
  providedIn: 'root'
})
export class RequestProviderService extends HttpProviderService {
  constructor(private providerHttp: HttpClient) {
    super(providerHttp, environment.hostURL + '/service-request');
  }
}

@Injectable({
  providedIn: 'root'
})
export class RequestHTTP extends HttpProviderService {
  constructor(private providerHttp: HttpClient) {
    super(providerHttp, environment.hostURL + '/request/customer/service-request');
  }
}


@Injectable({
  providedIn: 'root'
})
export class ApiHTTP extends HttpProviderService {
  constructor(private providerHttp: HttpClient) {
    super(providerHttp, environment.hostURL + '/request');
  }
}


